// Login Screen v0.0.2
// Created By Trevor Colby
// IMPORTANT: This file should be identical for every mountain
// any configuration should occur in './Configuration.js'.
// When updating do all development and testing on isno-www (Quebec Factory)
// and then update the version (v0.x.x) in that before updating this screen.
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { css, useTheme } from '@emotion/react';
import { useLocation, useHistory } from 'react-router-dom';
import background from 'isno/lib/static/images/HKD_Login_Background_Comp.jpg';
import logo from 'isno/lib/static/images/iSnoLogo.png';
import { Languages } from 'isno/lib/languages/LanguageContext';
import { signinUser, getCurrentAuth } from '../actions';
import LoginButton from '../components/Loginbutton';
import configuration from './Configuration';
// Configuration values pulled in from the './Configuration.js' file
const { initialScreen } = configuration;

function LoginScreen(props) {
  const [language, setLanguage] = useState(Languages.english);
  const theme = useTheme();
  const styles = stylesFromTheme(theme, language);
  const location = useLocation();
  const history = useHistory();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const [twoFactorSent, setTwoFactorSent] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [loading, setLoading] = useState(false);
  const [wait, setWait] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [firstTime, setFirstTime] = useState(() => {
    return 0;
  });
  const [hasSpaceInUsername, setHasSpaceInUsername] = useState(false);

  const { from } = location.state || { from: { pathname: initialScreen } };

  useEffect(() => {
    props.getCurrentAuth();
    if (props.auth.tokenExpired) {
      setErrorMessage(language.tokenExpiredMessage);
    }
  }, []);

  useEffect(() => {
    // clean up to stop memory leaks
    return () => {
      setIsMounted(false);
    };
  }, []);

  // If we fail to authenticate but don't receive an error: communication issue
  useEffect(() => {
    if (firstTime > 0 && props.auth.authenticated === false && props.auth.error === undefined) {
      setErrorMessage(language.communicationErrorMessage);
    }
  }, [props.auth, language]);

  // SCOTT this useEffect runs even when you log yourself out - incorrect message displayed for regular log out
  useEffect(() => {
    if (props.auth.tokenExpired) {
      // setErrorMessage('You were automatically logged out for security purposes because your authentication token had expired. Please log back in.');
      setErrorMessage(language.tokenExpiredMessage);
    }
  }, [props.auth.tokenExpired, language]);
  // If we fail to authenticate and receive an error: display error
  useEffect(() => {
    if (!props.auth.tokenExpired || firstTime > 0) {
      if ([400, 401].indexOf(props.auth.error) > -1) {
        if (twoFactorSent) {
          setErrorMessage(`${language.invalidCredentials}\n${language.verify2FA}\n(HTTPS ${language.errorCaps} ${props.auth.error})`);
        } else {
          setErrorMessage(`${language.invalidCredentials}\n${language.verifyNamePassword}\n(HTTPS ${language.errorCaps} ${props.auth.error})`);
        }
      } else if ([404, 405, 408].indexOf(props.auth.error) > -1) {
        setErrorMessage(`${language.communicationFailure}\n(HTTPS ${language.errorCaps} ${props.auth.error})`);
      }
    }
  }, [props.auth.error, firstTime, language]);

  useEffect(() => {
    if (!props.auth.need2fa) {
      setTwoFactorCode('');
    }

    if (props.auth.authenticated) {
      history.push(from.pathname);
    }
  }, [props]);
// Check if username contains a space and if so, show an error message
  useEffect(() => {
    if (username.includes(' ')) {
      setHasSpaceInUsername(true);
      setErrorMessage(language.usernameContainsSpace);
    } else {
      setHasSpaceInUsername(false);
      setErrorMessage('');
    }
  }, [username, language.usernameContainsSpace]);

  function sleep(ms) {
    return new Promise((resolve) => { setTimeout(resolve, ms); });
  }

  const loginAttempt = async (e) => {
    setLoading(true);
    setWait(true);
    setErrorMessage('');
    setFirstTime(firstTime + 1);
    e.preventDefault();
    
    try {
      await props.signinUser({ username, password, twoFactorCode });
      await sleep(1000);
      setLoading(false);
      setWait(false);
    } catch (error) {
      console.log(error);
    }
  };

  const twoFactorAttempt = async (e) => {
    setLoading(true);
    setWait(false);
    setErrorMessage('');
    setFirstTime(firstTime + 1);
    e.preventDefault();
    try {
      await sleep(500);
      setTwoFactorSent(true);
      await props.signinUser({ username, password, twoFactorCode });
      await sleep(1000);
      if (isMounted) {
        setLoading(false);
        setWait(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderForm = () => {
    if (props.auth.need2fa && !wait) {
      const { qrCodeUrl } = props.auth.user;
      return (
        <form css={styles.form}>
          <img css={styles.logo} src={logo} alt="iSno_Logo" />
          {qrCodeUrl
          && (
          <div css={styles.qrCodeContainer}>
            <img src={qrCodeUrl} alt="QR Code" css={styles.qrCode} />
            {/* This is the seed code not the user enter code */}
            <button
              type="button"
              css={styles.code}
              onClick={() => {
                navigator.clipboard.writeText(props.auth?.user?.twoFactorCode);
              }}
            >
              {(() => {
                const code = props.auth?.user?.twoFactorCode;
                let codeWithSpaces = '';
                for (let i = 0; i < code.length; i++) {
                  if (i % 4 === 0) {
                    codeWithSpaces += '  ';
                  }
                  codeWithSpaces += code[i];
                }
                return codeWithSpaces;
              })()}
            </button>
            <div css={styles.alert}>{language.dualFAIndicator}</div>
          </div>
          )}
          <input css={styles.login_input_text}
            autoComplete="one-time-code"
            placeholder={language.enter2FA}
            maxLength={6}
            value={twoFactorCode}
            onChange={(event) => {
              setTwoFactorCode(event.target.value);
            }}
          />
          <button css={styles.noFocus} type="submit" onClick={(e) => { twoFactorAttempt(e); }}>
            <LoginButton animate={loading} authenticationText={language.authenticate} language={language} />
          </button>
          <div css={css`margin-top: 10px; display: flex; flex-direction: row; width: 140px; justify-content: space-between;`}>
            <div css={[styles.flag]}>
              <div css={css`height: 100%; margin-top: -2.5px;`}>
                <link
                  style={{
                    width: '100%', height: '100%', cursor: 'pointer', borderRadius: '3px',
                  }}
                  onClick={() => setLanguage(Languages.english)}
                  className="fi fi-us"
                  rel="stylesheet"
                  href="https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.0.0/css/flag-icons.min.css"
                />
              </div>
            </div>
            <div css={[styles.flag]}>
              <div css={css`height: 100%; margin-top: -2.5px;`}>
                <link
                  style={{
                    width: '100%', height: '100%', cursor: 'pointer', borderRadius: '3px',
                  }}
                  onClick={() => setLanguage(Languages.french)}
                  className="fi fi-fr"
                  rel="stylesheet"
                  href="https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.0.0/css/flag-icons.min.css"
                />
              </div>
            </div>
          </div>
          {(twoFactorSent && !loading)
          && (<div css={styles.error}>{errorMessage}</div>)}
        </form>
      );
    }

    return (
      <form css={styles.form}>
        <img css={styles.logo} src={logo} alt="iSno_Logo" />
        <input css={[styles.login_input_text, hasSpaceInUsername && css`border-bottom: 2px solid red !Important;`]}
          autoComplete="username"
          placeholder={language.enterUsername}
          value={username}
          onChange={(event) => {
            setUsername(event.target.value);
          }}
        />
        <input css={styles.login_input_password}
          autoComplete="current-password"
          placeholder={language.enterPassword}
          type="password"
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
        />
        <button 
          css={styles.noFocus} 
          type="submit" 
          onClick={(e) => { loginAttempt(e); }}
          disabled={hasSpaceInUsername}
        >
          <LoginButton animate={loading} authenticationText={language.authenticate} language={language} />
        </button>
        <div css={css`margin-top: 10px; display: flex; flex-direction: row; width: 140px; justify-content: space-between;`}>
          <div css={[styles.flag]}>
            <div css={css`height: 100%; margin-top: -2.5px;`}>
              <link
                style={{
                  width: '100%', height: '100%', cursor: 'pointer', borderRadius: '3px',
                }}
                onClick={() => setLanguage(Languages.english)}
                className="fi fi-us"
                rel="stylesheet"
                href="https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.0.0/css/flag-icons.min.css"
              />
            </div>
          </div>
          <div css={[styles.flag]}>
            <div css={css`height: 100%; margin-top: -2.5px;`}>
              <link
                style={{
                  width: '100%', height: '100%', cursor: 'pointer', borderRadius: '3px',
                }}
                onClick={() => setLanguage(Languages.french)}
                className="fi fi-fr"
                rel="stylesheet"
                href="https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.0.0/css/flag-icons.min.css"
              />
            </div>
          </div>
        </div>
        {
          (hasSpaceInUsername || (firstTime > 0 && !loading) || (!!props.auth.tokenExpired))
          && (<div css={styles.error}>{errorMessage}</div>)
        }
      </form>
    );
  };

  return (
    <div css={styles.wrapper}>
      <img css={styles.backgroundImage} src={background} alt="Background_Img" />
      {renderForm()}
    </div>
  );
}

const stylesFromTheme = (theme, language) => {
  return {
    wrapper: css`
      width: 100vw;
      height: 100vh;
      height: 100dvh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `,
    backgroundImage: css`
      z-index: -1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: fixed;
      top: 0px;
      left: 0px;
      overflow: hidden;
      filter: brightness(40%);
    `,
    form: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      width: ${language.fileName === 'français' ? '450px' : '320px'};
      margin-bottom: 2%;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        font-size: 16px;
      }
    `,
    logo: css`
      width: 200px;
    `,
    flag: css`
    height: 38px;
    width: 50px;
    :hover {
      border: 1px solid ${theme.linkColorActive};
      border-radius: 4px;
    }
    `,
    error: css`
      width: 200px;
      text-align: center;
      white-space: pre-line;
      color: white;
    `,
    noFocus: css`
      stroke: white;
      :focus {
        outline: none;
        stroke: ${theme.linkColorActive};
      }
    `,
    qrCode: css`
      border-radius: 6px;
      width: 150px;
      margin-top: 20px;
      margin-bottom: 10px;
    `,
    qrCodeContainer: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `,
    code: css`
      color: black;
      background-color: white;
      text-align: center;
      padding: 10px;
      margin-top: 10px;
      border-radius: 4px;
    `,
    alert: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      //position: fixed;
      //top: 0;
      //text-align: center;
      color: white;
      background: red;
      // height: 40px;
      padding: 10px;
      margin-top: 20px;
      border-radius: 4px;
      color: black;
    `,
    loginButton: css`
        display: block;
        width: 100%;
        text-align: center;
        /* background-color: #505052; */
        background-color: rgba(80, 80, 82, 0);
        color: white;
        padding: 14px 20px;
        margin: 8px 0;
        cursor: pointer;
        border: 1px solid rgba(231, 231, 231, 1);
        border-radius: 20px;
        :focus {
          outline: 0;
          border: thin solid ${theme.linkColorActive} !Important;
        };
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: white;
          opacity: 1; /* Firefox */
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: white;
        }
        ::-ms-input-placeholder { /* Microsoft Edge */
          color: white;
        }
        :hover {
          /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 3px 10px 0 rgba(0,0,0,0.19); */
          border-color: ${theme.linkColorActive};
          background-color: rgba(80, 80, 82, 0.4);
        }
    `,
    login_input_text: css`
        width: 320px;
        padding: 12px 20px; /*pading inside entry*/
        border-bottom: 1px solid ;
        margin: 20px 0 10px;
        display: inline-block;
        border: none;
        border-bottom: thin solid rgba(231, 231, 231, 1);
        box-sizing: border-box;
        background:rgba(255,255,255, 0.0);
        color: white;
        :focus {
          outline: 0;
          border-bottom: thin solid ${theme.linkColorActive};
        };
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: white;
        opacity: 1; /* Firefox */
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: white;
        }
        ::-ms-input-placeholder { /* Microsoft Edge */
          color: white;
        }
    `,
    login_input_password: css`
        width: 320px;
        padding: 12px 20px; /*pading inside entry*/
        margin: 10px 0 30px;
        display: inline-block;
        border: none;
        border-bottom: thin solid rgba(231, 231, 231, 1) !important;
        box-sizing: border-box;
        background:rgba(255,255,255, 0.0);
        color: white;
        :focus {
          outline: 0;
          border-bottom: thin solid ${theme.linkColorActive} !Important;
        };
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: white;
        opacity: 1; /* Firefox */
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: white;
        }
        ::-ms-input-placeholder { /* Microsoft Edge */
          color: white;
        }
    `,
  };
};

LoginScreen.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      qrCodeUrl: PropTypes.string,
      twoFactorCode: PropTypes.string,
    }),
    authenticated: PropTypes.bool,
    need2fa: PropTypes.bool,
    error: PropTypes.number,
    tokenExpired: PropTypes.bool,
    // error: PropTypes.oneOfType([
    //   PropTypes.number,
    // ]),
  }).isRequired,

  signinUser: PropTypes.func.isRequired,
  getCurrentAuth: PropTypes.func.isRequired,

};

const mapStateToProps = (state) => ({
  auth: state.auth,
  settings: state.settings,
});

export default connect(mapStateToProps, { signinUser, getCurrentAuth })(LoginScreen);
