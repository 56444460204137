// Tracker Screen v0.0.11
// Created By Trevor Colby
// IMPORTANT: This file should be identical for every mountain
// any configuration should occur in './Configuration.js'
import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useMemo,
} from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CancelToken } from 'axios';
import { css, useTheme } from '@emotion/react';
import {
  Controls,
  HoverOverlay,
  EditOverlay,
  TrailEditOverlay,
  SensorEditOverlay,
  WeatherStation,
  Banner,
  Defender,
  DrainValve,
  TrailEditor,
  TrailEditInstructions,
} from 'isno/lib/components/tracker';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
// Tracker Menu Icons
import configIconBlack from 'isno/lib/static/images/TrackerIcons/black/configurationIcon.png';
import configIconDarkTheme from 'isno/lib/static/images/TrackerIcons/darkTheme/configurationIconWhite.png';
import configIconOriginalTheme from 'isno/lib/static/images/TrackerIcons/originalTheme/configurationIconWhite.png';
import keyIconBlack from 'isno/lib/static/images/TrackerIcons/black/keyIcon.svg';
import keyIconDarkTheme from 'isno/lib/static/images/TrackerIcons/darkTheme/keyIconDarkTheme.svg';
import keyIconOriginalTheme from 'isno/lib/static/images/TrackerIcons/originalTheme/keyIconOriginalTheme.svg';
import trailDownloadBlack from 'isno/lib/static/images/TrackerIcons/black/downloadTrail.svg';
import trailDownloadDarkTheme from 'isno/lib/static/images/TrackerIcons/darkTheme/downloadTrailDarkTheme.svg';
import trailDownloadOriginalTheme from 'isno/lib/static/images/TrackerIcons/originalTheme/downloadTrailOriginalTheme.svg';
import hydrantDownloadBlack from 'isno/lib/static/images/TrackerIcons/black/downloadHydrant.svg';
import hydrantDownloadDarkTheme from 'isno/lib/static/images/TrackerIcons/darkTheme/downloadHydrantDarkTheme.svg';
import hydrantDownloadOriginalTheme from 'isno/lib/static/images/TrackerIcons/originalTheme/downloadHydrantOriginalTheme.svg';
import thermoBlack from 'isno/lib/static/images/TrackerIcons/black/thermo.svg';
import thermoDarkTheme from 'isno/lib/static/images/TrackerIcons/darkTheme/thermoDarkTheme.svg';
import thermoOriginalTheme from 'isno/lib/static/images/TrackerIcons/originalTheme/thermoOriginalTheme.svg';
import snowGunBlack from 'isno/lib/static/images/TrackerIcons/black/snowGun.svg';
import snowGunDarkTheme from 'isno/lib/static/images/TrackerIcons/darkTheme/snowGunDarkTheme.svg';
import snowGunOriginalTheme from 'isno/lib/static/images/TrackerIcons/originalTheme/snowGunOriginalTheme.svg';
import trailIconBlack from 'isno/lib/static/images/TrackerIcons/black/trailIcon.svg';
import trailIconDarkTheme from 'isno/lib/static/images/TrackerIcons/darkTheme/trailIconDarkTheme.svg';
import trailIconOriginalTheme from 'isno/lib/static/images/TrackerIcons/originalTheme/trailIconOriginalTheme.svg';
import sensorImageBlack from 'isno/lib/static/images/TrackerIcons/black/sensor.png';
import sensorImageDarkTheme from 'isno/lib/static/images/TrackerIcons/darkTheme/sensorWhite.png';
import sensorImageOriginalTheme from 'isno/lib/static/images/TrackerIcons/originalTheme/sensorWhite.png';
import snowDepthImageBlack from 'isno/lib/static/images/TrackerIcons/black/snowDepth.png';
import snowDepthImageDarkTheme from 'isno/lib/static/images/TrackerIcons/darkTheme/snowDepthWhite.png';
import snowDepthImageOriginalTheme from 'isno/lib/static/images/TrackerIcons/originalTheme/snowDepthWhite.png';

import Sensor from 'isno/lib/static/images/sensorIcon.svg';
import FlowZoneSensor from 'isno/lib/static/images/flowZoneSensorIcon.svg';
import FlowZoneAlarmSensor from 'isno/lib/static/images/flowZoneAlarmSensorIcon.svg';
import SensorSelected from 'isno/lib/static/images/sensorIconSelected.svg';
import Thermometer from 'isno/lib/static/images/thermometerSquare.svg';
import DefenderImage from 'isno/lib/static/images/defenderIcon.svg';
import ValveImage from 'isno/lib/static/images/drainIcon.svg';
import ValveImageOpen from 'isno/lib/static/images/drainIconOpen.svg';
import ValveImageClosed from 'isno/lib/static/images/drainIconClosed.svg';
import {
  FaAngleDoubleUp,
  FaAngleDoubleDown,
  FaCrosshairs,
  FaTimes,
} from 'react-icons/fa';
import {
  Map, View, Overlay, Feature,
} from 'ol';
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  WebGLPoints as WebGLPointsLayer,
} from 'ol/layer';
import TileState from 'ol/TileState';
import { BingMaps, XYZ, Vector as VectorSource } from 'ol/source';
import {
  Point, Polygon, MultiPolygon, MultiPoint, LineString,
} from 'ol/geom';
import {
  Icon,
  Style,
  Fill,
  Stroke,
  Text,
  RegularShape,
} from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { unByKey } from 'ol/Observable';
import FillPattern from 'ol-ext/style/FillPattern';
import * as Control from 'ol/control';
import * as Proj from 'ol/proj';
import Collection from 'ol/Collection';
import 'ol/ol.css';
import {
  Draw,
  Modify,
  Snap,
  Select,
  Translate,
  defaults as defaultInteractions,
} from 'ol/interaction';
import { isEmpty } from 'ol/extent';

import { singleClick } from 'ol/events/condition';

import { isEqual } from 'lodash';

import { LoadingIndicator } from 'isno/lib/components/displays';
import {
  addHydrant,
  deleteHydrant,
  addTrail,
  deleteTrail,
  updateTrail,
  fetchAllHydrantModels,
  fetchAllTrails,
  fetchAllTrailsWithGeometry,
  fetchAllAutoTrails,
  fetchGlobalNotes,
  fetchAllGateways,
  fetchFeatures,
  addGateway,
  fetchAllPresets,
  fetchAllWeatherStations,
  selectTrail,
  selectHydrant,
  selectMobile,
  selectAutoTrail,
  updateHydrant,
  bulkUpdateHydrants,
  bulkSetHydrantIOValues,
  setHydrantIOValue,
  setTrailState,
  setPriorityView,
  setAutoTrailIOValue,
  setAutoTrailValue,
  addNote,
  updateNote,
  fetchAllGuns,
  fetchAllMobileGuns,
  fetchMobileGun,
  deleteMobileGun,
  addMobileGun,
  editMobileGun,
  fetchAllMobileEquipment,
  fetchTemperatureZones,
  fetchFlowZones,
  fetchPressureZones,
  fetchElevation,
  fetchAllDefenders,
  // fetchSnowDepths,
  fetchSnowDepthsByDate,
  fetchAllValves,
  addAutoHydrant,
  addSnofiHydrant,
  deleteAutoHydrant,
  fetchPLCs,
  editPLC,
  fetchAllTrackerEquipment,
  addHydrantSensor,
  deleteHydrantSensor,
  fetchAllAutoTrailsIOValues,
  fetchAllTrackerIOValues,
  fetchAllIOValues,
  fetchAllFlowManagementSettings,
  editFMConfig,
  fetchAllZoneIOValues,
  addZoneIOValue,
  deleteZoneIOValue,
  editZoneIOValue,
  setIOValue,
  fetchHydrant,
  fetchHydrantTrendDataset,
  downloadKML,
  updateSettings,
} from '../actions';
import configuration from './Configuration';

// Configuration values pulled in from the './Configuration.js' file
const {
  radius,
  radius2,
  strokeWidth,
  noteStrokeWidth,
  padding,
  extentFactors,
  rotationFactor,
  labelZoom,
  trackerEquipmentZoom,
  trackerEquipmentIconScale,
  maxZoomLimit,
  minZoomLimit,
  initialZoom,
  center,
  mapEditRole,
  developerRole,
  defaultCenterPoint,
  googleLayerType,
} = configuration;

// IOValueKeys are passed to subcomponents
// Tracker Screen -> Controls -> Hydrant
// Tracker Screen -> Controls -> HydrantSettings -> AutoHydrantSettings
// Tracker Screen -> Controls -> HydrantSettings -> AutoFanSettings
const IOValueKeys = {
  HydrantIOValueKeys: {
    // autoModeStart: 'control_mode_auto_start',
    // autoModeStop: 'control_mode_auto_stop',
    // klikTotalGallons: 'total_kilogallons',
    // masterDrybulb: 'master_drybulb',
    airPressure: 'air_pressure',
    alarmReset: 'alarm_reset',
    alarmReset1: 'alarm_reset_1',
    alarmReset2: 'alarm_reset_2',
    alarmReset3: 'alarm_reset_3',
    alarmStatus1: 'alarm_status_1',
    alarmStatus2: 'alarm_status_2',
    alarmStatus3: 'alarm_status_3',
    autoDisable: 'auto_disable',
    autoEnable: 'auto_enable',
    autoManual: 'auto_manual',
    autoMode: 'auto_mode',
    autoModeStart: 'control_mode_auto_start',
    autoModeStop: 'control_mode_auto_stop',
    comms: 'hydrant_status',
    compressorOff: 'compressor_off',
    compressorOn: 'compressor_on',
    compressorStatus: 'compressor_status',
    controlStatus: 'control_mode_status',
    drybulb: 'drybulb',
    drybulbTemperature: 'drybulb_temperature',
    drybulbTemperatureAlternate: 'drybulb_temperature_alternate',
    errorStatus: 'error_status',
    fanControlStatus: 'control_mode_status',
    fanDrybulb: 'actual_drybulb',
    fanDrybulbAlternate: 'actual_drybulb_alternate',
    fanRelativeHumidity: 'relative_humidity',
    fanRequestedFlow: 'requested_water_flow',
    fanType: 'fan_type',
    fanWaterPressure: 'actual_water_pressure',
    fanWetbulb: 'actual_wetbulb',
    fanWetbulbAlternate: 'actual_wetbulb_alternate',
    flow: 'water_flow_setpoint',
    gunStatus: 'gun_status',
    haloGen: 'halo_generation',
    haloSetpoint: 'halo_current_setpoint',
    haloSetpointCommand: 'halo_setpoint_command',
    haloStage: 'halo_current_stage',
    haloStageSetpoint: 'halo_desired_stage',
    hydrantHeaterSwitch: 'hydrant_heater_switch',
    hydrantSaveHead: 'hydrant_save_head',
    index: 'index',
    kilowatts: 'kilowatts',
    klikAutoStart: 'auto_start_switch',
    klikAutoStop: 'auto_stop_switch',
    klikAutoSwitch: 'auto_switch',
    klikManualSwitch: 'remote_switch',
    klikTotalGallons: 'total_gallons',
    localAirPressureMode: 'local_air_pressure_mode',
    localAuto: 'local_auto',
    localRemote: 'local_remote',
    localWaterPressureMode: 'local_water_pressure_mode',
    manualAuto: 'manual_auto',
    manualMode: 'control_mode_manual',
    masterAlarm: 'master_alarm',
    masterAlarmReset: 'master_alarm_reset',
    masterAlarmReset2: 'master_alarm_2_reset',
    masterAlarmReset3: 'master_alarm_3_reset',
    masterWetbulb: 'master_wetbulb',
    masterWetbulbAlternate: 'master_wetbulb_alternate',
    position: 'current_position',
    positionSetpoint: 'remote_manual_position_setpoint',
    refreshNode: 'refresh_node',
    relativeHumidity: 'relative_humidity',
    remoteStatus: 'distant_mode',
    remoteSwitch: 'remote_mode',
    retry: 'retry',
    revClosed: 'rev_closed',
    revClosing: 'rev_closing',
    revEnabled: 'rev_enabled',
    revOpen: 'rev_open',
    revOpening: 'rev_opening',
    rssi: 'rssi',
    running: 'running',
    setPosition: 'set_position',
    snowQuality: 'snow_quality',
    stage0Command: 'stage_0_command',
    stage0Reached: 'stage_0_reached',
    stage1Command: 'stage_1_command',
    stage1Reached: 'stage_1_reached',
    stage2Command: 'stage_2_command',
    stage2Reached: 'stage_2_reached',
    stage3Command: 'stage_3_command',
    stage3Reached: 'stage_3_reached',
    stage4Command: 'stage_4_command',
    stage4Reached: 'stage_4_reached',
    start: 'start',
    starting: 'starting',
    status: 'hydrant_status',
    stop: 'stop',
    stopped: 'stopped',
    stopping: 'stopping',
    voltEnabled: 'volt_enabled',
    waiting: 'waiting',
    warmup: 'warmup',
    waterPressure: 'water_pressure',
    waterPressureOverride: 'water_pressure_override',
    wetbulb: 'wetbulb',
    wetbulbAlternate: 'wetbulb_alternate',
    wetbulbOverride: 'wetbulb_override',
    wetbulbTemperature: 'wetbulb_temperature',
    wetbulbTemperatureAlternate: 'wetbulb_temperature_alternate',
  },
  HydrantSettingsIOValueKeys: {
    AutoFanIOValueKeys: {
      airFlow: 'air_flow',
      airPressure: 'air_pressure',
      barrelAngle: 'barrel_angle',
      barrelAngleSetpoint: 'barrel_angle_setpoint',
      barrelDirection: 'barrel_direction',
      barrelDirectionSetpoint: 'barrel_direction_setpoint',
      barrelJackType: 'barrel_jack_type',
      ccwLimit: 'orientation_ccw_limit',
      compressor: 'compressor_status',
      compressorEnabled: 'compressor_enabled',
      compressorOff: 'compressor_manual_stop',
      compressorOn: 'compressor_manual_start',
      cwLimit: 'orientation_cw_limit',
      disableKeyboardLockout: 'disable_keyboard_lockout',
      disableSilentMode: 'disable_silent_mode',
      disableWindTracking: 'disable_wind_tracking',
      enableKeyboardLockout: 'enable_keyboard_lockout',
      enableSilentMode: 'enable_silent_mode',
      enableWindTracking: 'enable_wind_tracking',
      fan: 'fan_status',
      fanOff: 'fan_manual_stop',
      fanOn: 'fan_manual_start',
      flow: 'water_flow_setpoint',
      heater: 'heater_status',
      heaterOff: 'heater_off',
      heaterOn: 'heater_on',
      heatingEnabled: 'heating_enabled',
      horizontalPositionSetpoint: 'horizontal_position_setpoint',
      hydrant: 'hydrant_status',
      hydrantClose: 'hydrant_manual_close',
      hydrantOpen: 'hydrant_manual_open',
      keyboardLockoutEnabled: 'keyboard_lockout_enabled',
      kilowatts: 'kilowatts',
      light: 'light_status',
      lightDisable: 'light_disable',
      lightEnable: 'light_enable',
      lightEnabled: 'light_enabled',
      lightOff: 'light_off',
      lightOn: 'light_on',
      maxHorizontalRotation: 'max_horizontal_rotation',
      minHorizontalRotation: 'min_horizontal_rotation',
      minionPressureSetpoint: 'minion_pressure_setpoint',
      oscillationCalibration: 'oscillator_calibration',
      oscillationCalibrationStart: 'oscillator_calibration_start',
      oscillationCalibrationStop: 'oscillator_calibration_stop',
      oscillationEnabled: 'oscillation_enabled',
      oscillator: 'oscillator',
      oscillatorOff: 'oscillator_stop',
      oscillatorOn: 'oscillator_start',
      oscillatorSpan: 'oscillator_span_setpoint',
      oscillatorSpanSetpoint: 'oscillator_span_setpoint',
      power: 'power',
      pressureSetpoint: 'pressure_setpoint',
      remoteStatus: 'distant_mode',
      remoteSwitch: 'remote_mode',
      silentModeEnabled: 'silent_mode_enabled',
      startStopWetbulbSetpoint: 'start_stop_wetbulb_setpoint',
      startStopWetbulbSetpointAlternate: 'start_stop_wetbulb_setpoint_alternate',
      startTempSetpoint: 'auto_start_temperature_setpoint',
      startTempSetpointAlternate: 'auto_start_temperature_setpoint_alternate',
      stopTempSetpoint: 'auto_stop_temperature_setpoint',
      stopTempSetpointAlternate: 'auto_stop_temperature_setpoint_alternate',
      turbineEnabled: 'turbine_enabled',
      valvesOpen: 'valves_open',
      verticalPositionSetpoint: 'vertical_position_setpoint',
      waterFlowSetpoint: 'water_flow_setpoint',
      waterTemperature: 'water_temperature',
      waterTemperatureAlternate: 'water_temperature_alternate',
      windTrackingEnabled: 'wind_tracking_enabled',
    },
    AutoHydrantIOValueKeys: {
      compressor: 'compressor_status',
      workLight: 'hydrant_work_light',
      workLightOn: 'hydrant_work_light_on',
      workLightOff: 'hydrant_work_light_off',
      compressorSetpoint: 'compressor_preheat_delay_setpoint',
      voltEnabled: 'volt_enabled',
      revEnabled: 'rev_enabled',
      revPosition: 'rev_actual_position',
      revTarget: 'rev_turn_target',
      revMaxTurnsSetpoint: 'rev_max_turns_setpoint',
      revClosingCurrentSetpoint: 'rev_closing_current_setpoint',
      revMaxCurrentSetpoint: 'rev_max_current_setpoint',
      powerDelay: 'start_delay_timer_setpoint',
      // wetbulbOverride: 'Wetbulb_Override',
      wetbulbOverride: 'wetbulb_override',
      // wetbulbOverrideValue: 'Wetbulb_Override_Value',
      wetbulbOverrideValue: 'wetbulb_override_value',
      wetbulbOverrideValueAlternate: 'wetbulb_override_value_alternate',
      pressureOverride: 'water_pressure_override',
      pressureOverrideValue: 'water_pressure_override_setpoint',
      hydrantHeating: 'heater_enable',
      hydrantHeatingMode: 'heater_auto_manual',
      hydrantHeaterSwitch: 'heater_heater_switch',
      // nozzelHeat: 'nozzel_heat_temperature_setpoint',
      localWaterPressure: 'local_water_pressure',
      localWaterPressureMode: 'local_water_pressure_mode',
      localAirPressure: 'local_air_pressure',
      localAirPressureMode: 'local_air_pressure_mode',
      analogInput1Value: 'analog_input_1_scaled',
      analogInput2Value: 'analog_input_2_scaled',
      analogInput1LowEGU: 'analog_input_1_min_scale',
      analogInput1HighEGU: 'analog_input_1_max_scale',
      analogInput2LowEGU: 'analog_input_2_min_scale',
      analogInput2HighEGU: 'analog_input_2_max_scale',

      // controlModeStatus: 'control_mode_status',

      quality1Stage1Wetbulb: 'quality_1_stage_1_wetbulb_setpoint',
      quality1Stage2Wetbulb: 'quality_1_stage_2_wetbulb_setpoint',
      quality1Stage3Wetbulb: 'quality_1_stage_3_wetbulb_setpoint',
      quality1Stage4Wetbulb: 'quality_1_stage_4_wetbulb_setpoint',
      quality1Stage5Wetbulb: 'quality_1_stage_5_wetbulb_setpoint',
      quality1Stage6Wetbulb: 'quality_1_stage_6_wetbulb_setpoint',
      quality1Stage7Wetbulb: 'quality_1_stage_7_wetbulb_setpoint',
      quality1Stage8Wetbulb: 'quality_1_stage_8_wetbulb_setpoint',

      quality2Stage1Wetbulb: 'quality_2_stage_1_wetbulb_setpoint',
      quality2Stage2Wetbulb: 'quality_2_stage_2_wetbulb_setpoint',
      quality2Stage3Wetbulb: 'quality_2_stage_3_wetbulb_setpoint',
      quality2Stage4Wetbulb: 'quality_2_stage_4_wetbulb_setpoint',
      quality2Stage5Wetbulb: 'quality_2_stage_5_wetbulb_setpoint',
      quality2Stage6Wetbulb: 'quality_2_stage_6_wetbulb_setpoint',
      quality2Stage7Wetbulb: 'quality_2_stage_7_wetbulb_setpoint',
      quality2Stage8Wetbulb: 'quality_2_stage_8_wetbulb_setpoint',

      quality3Stage1Wetbulb: 'quality_3_stage_1_wetbulb_setpoint',
      quality3Stage2Wetbulb: 'quality_3_stage_2_wetbulb_setpoint',
      quality3Stage3Wetbulb: 'quality_3_stage_3_wetbulb_setpoint',
      quality3Stage4Wetbulb: 'quality_3_stage_4_wetbulb_setpoint',
      quality3Stage5Wetbulb: 'quality_3_stage_5_wetbulb_setpoint',
      quality3Stage6Wetbulb: 'quality_3_stage_6_wetbulb_setpoint',
      quality3Stage7Wetbulb: 'quality_3_stage_7_wetbulb_setpoint',
      quality3Stage8Wetbulb: 'quality_3_stage_8_wetbulb_setpoint',

      quality1Stage1WetbulbAlternate: 'quality_1_stage_1_wetbulb_setpoint_alternate',
      quality1Stage2WetbulbAlternate: 'quality_1_stage_2_wetbulb_setpoint_alternate',
      quality1Stage3WetbulbAlternate: 'quality_1_stage_3_wetbulb_setpoint_alternate',
      quality1Stage4WetbulbAlternate: 'quality_1_stage_4_wetbulb_setpoint_alternate',
      quality1Stage5WetbulbAlternate: 'quality_1_stage_5_wetbulb_setpoint_alternate',
      quality1Stage6WetbulbAlternate: 'quality_1_stage_6_wetbulb_setpoint_alternate',
      quality1Stage7WetbulbAlternate: 'quality_1_stage_7_wetbulb_setpoint_alternate',
      quality1Stage8WetbulbAlternate: 'quality_1_stage_8_wetbulb_setpoint_alternate',

      quality2Stage1WetbulbAlternate: 'quality_2_stage_1_wetbulb_setpoint_alternate',
      quality2Stage2WetbulbAlternate: 'quality_2_stage_2_wetbulb_setpoint_alternate',
      quality2Stage3WetbulbAlternate: 'quality_2_stage_3_wetbulb_setpoint_alternate',
      quality2Stage4WetbulbAlternate: 'quality_2_stage_4_wetbulb_setpoint_alternate',
      quality2Stage5WetbulbAlternate: 'quality_2_stage_5_wetbulb_setpoint_alternate',
      quality2Stage6WetbulbAlternate: 'quality_2_stage_6_wetbulb_setpoint_alternate',
      quality2Stage7WetbulbAlternate: 'quality_2_stage_7_wetbulb_setpoint_alternate',
      quality2Stage8WetbulbAlternate: 'quality_2_stage_8_wetbulb_setpoint_alternate',

      quality3Stage1WetbulbAlternate: 'quality_3_stage_1_wetbulb_setpoint_alternate',
      quality3Stage2WetbulbAlternate: 'quality_3_stage_2_wetbulb_setpoint_alternate',
      quality3Stage3WetbulbAlternate: 'quality_3_stage_3_wetbulb_setpoint_alternate',
      quality3Stage4WetbulbAlternate: 'quality_3_stage_4_wetbulb_setpoint_alternate',
      quality3Stage5WetbulbAlternate: 'quality_3_stage_5_wetbulb_setpoint_alternate',
      quality3Stage6WetbulbAlternate: 'quality_3_stage_6_wetbulb_setpoint_alternate',
      quality3Stage7WetbulbAlternate: 'quality_3_stage_7_wetbulb_setpoint_alternate',
      quality3Stage8WetbulbAlternate: 'quality_3_stage_8_wetbulb_setpoint_alternate',
    },
  },
};

let hydrantFeatures,
  trailFeatures,
  trailDeleteFeatures,
  weatherStations,
  defendersLayer,
  valvesLayer,
  defaultCenterLayer,
  sensorsLayer,
  snowDepthLayer,
  weatherOverlayDOM,
  defenderOverlayDOM,
  valveOverlayDOM,
  weatherOverlays,
  defenderOverlays,
  valveOverlays,
  weatherSource,
  snowDepthSource,
  defenderSource,
  valveSource,
  sensorSource,
  weatherPoints,
  defenderPoints,
  valvePoints,
  sensorPoints,
  snowDepthPoints,
  hoverOverlayDOM,
  hoverOverlay,
  changeResKey, // ol.map 'change:resolution' event key used to remove event listener inside useEffect update
  singleClickKey, // ol.map 'singleclick' event key used to remove event listener inside useEffect update
  pointermoveKey, // ol.map 'pointermove' event key used to remove event listener inside useEffect update
  pointermoveKeyCursor, // same as above but for different useEffect listener func
  changeSelectKey, // ol.map select 'change:length' event key
  select,
  translate,
  draw,
  // draw2,
  modify,
  snap,
  snapTrail,
  // cursorOverlay,
  editOverlayDOM,
  editOverlayDOMHandle,
  editOverlay,
  sensorEditOverlayDOM,
  sensorEditOverlayDOMHandle,
  sensorEditOverlay,
  hydrants,
  trails,
  // pipes,
  trailsAdd,
  trailsDelete,
  map,
  snowDepthStyle;

const hydrantSource = new VectorSource();
const trailSource = new VectorSource();
const trailAddSource = new VectorSource();
// const pipeSource = new VectorSource();
const trailDeleteSource = new VectorSource();
const selectedFeatures = new Collection();
const defaultCenterSource = new VectorSource();

// Function used to convert hsl color values to rgb because openlayers cannot use hsl
// but color projections for snow depths are much easier in hsl
// https://www.30secondsofcode.org/js/s/hsl-to-rgb
const HSLToRGB = (h, s, l) => {
  const tempS = s / 100;
  const tempL = l / 100;
  // s /= 100;
  // l /= 100;
  const k = (n) => (n + h / 30) % 12;
  const a = tempS * Math.min(tempL, 1 - tempL);
  const f = (n) => tempL - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
  return [255 * f(0), 255 * f(8), 255 * f(4)];
};

// Function used to load our google maps tiles, we use this instead of the default
// loader to prevents cross site cookie errors, may need to attach additional headers in the future
const customLoader = (tile, src) => {
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.addEventListener('loadend', function load(evt) {
    const data = this.response;
    if (data !== undefined) {
      tile.getImage().src = URL.createObjectURL(data); // eslint-disable-line no-param-reassign
    } else {
      tile.setState(TileState.ERROR);
    }
  });
  xhr.addEventListener('error', () => {
    tile.setState(TileState.ERROR);
  });
  xhr.open('GET', src);
  // xhr.setRequestHeader('foo', 'bar'); // Example of how to set header
  xhr.send();
};

const setDefaultWeatherClosed = (stations) => {
  const tempWClosed = {};
  if (stations) {
    Object.values(stations).forEach((station) => {
      if (
        station.weatherlinkStation?.longitude
        || station.openWeatherMapLocation?.longitude
        || station.weatherStationTrackerEquipment?.longitude
        || station.weatherStationEquipment?.location?.longitude
      ) {
        tempWClosed[station.id] = true;
      }
    });
  }
  return tempWClosed;
};

const setDefaultDefendersClosed = (defenders) => {
  const tempDClosed = {};
  if (defenders) {
    Object.values(defenders).forEach((defender) => {
      tempDClosed[defender.id] = true;
    });
  }
  return tempDClosed;
};

const setDefaultValvesClosed = (valves) => {
  const tempVClosed = {};
  if (valves) {
    Object.values(valves).forEach((valve) => {
      tempVClosed[valve.id] = true;
    });
  }
  return tempVClosed;
};

const renderDefenders = (defenders, defendersClosed, setDefendersClosed) => {
  if (!defenders) return null;
  return (
    <div>
      {Object.values(defenders).map((defender) => {
        return (
          <Defender
            key={`defender_${defender.id}`}
            closed={defendersClosed}
            setClosed={setDefendersClosed}
            data={defender}
          />
        );
      })}
    </div>
  );
};

const renderDrainValves = (valves, valvesClosed, setValvesClosed, setIOVal) => {
  if (!valves) return null;
  return (
    <div>
      {Object.values(valves).map((valve) => {
        if (valve.latitude != null && valve.longitude != null) {
          return (
            <DrainValve
              key={`valve_${valve.id}`}
              closed={valvesClosed}
              setClosed={setValvesClosed}
              data={valve}
              setIOValue={setIOVal}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

const renderWeatherStations = (stations, weatherClosed, setWeatherClosed, settings) => {
  if (!stations) return null;
  return (
    <div>
      {Object.values(stations).map((station) => {
        if (
          station.weatherlinkStation?.longitude
          || station.openWeatherMapLocation?.longitude
          || station.weatherStationTrackerEquipment?.longitude
          || station.weatherStationEquipment?.location?.longitude
        ) {
          return (
            <WeatherStation
              celsius={settings?.useCelsius}
              key={`stationOut_${station.id}`}
              closed={weatherClosed}
              setClosed={setWeatherClosed}
              weatherData={station}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

const styleCache = {};

const hexagonSelected = new RegularShape({
  // fill: new Fill({ color: 'rgba(153, 201, 255,0.6)' }),
  // fill: new Fill({ color: 'rgba(0,94,204,1)' }),
  fill: new Fill({ color: 'rgba(255,128,0,0.7)' }),
  stroke: new Stroke({
    // color: 'rgba(0,94,204,1)',
    // color: 'black',
    color: 'rgba(255,128,0,1)',
    width: strokeWidth,
  }),
  radius,
  radius2,
  points: 3,
});
const mobileSelected = new RegularShape({
  // fill: new Fill({ color: 'rgba(153, 201, 255,0.6)' }),
  // fill: new Fill({ color: 'rgba(0,94,204,1)' }),
  fill: new Fill({ color: 'rgba(255,128,0,0.7)' }),
  stroke: new Stroke({
    // color: 'rgba(0,94,204,1)',
    // color: 'black',
    color: 'rgba(255,128,0,1)',
    width: strokeWidth,
  }),
  radius,
  radius2,
  points: 2,
});

const trailStyles = new Style({
  fill: new Fill({ color: 'rgba(255,255,255,0.4)' }),
  stroke: new Stroke({ color: 'transparent', width: 2 }),
});

const trailStylesPriorityZero = new Style({
  fill: new Fill({ color: 'rgba(184,200,255,0.6)' }),
  stroke: new Stroke({ color: 'transparent', width: 2 }),
});

const trailStylesPriorityOne = new Style({
  fill: new Fill({ color: 'rgba(75,115,255,0.6)' }),
  stroke: new Stroke({ color: 'transparent', width: 2 }),
});

const trailStylesPriorityTwo = new Style({
  fill: new Fill({ color: 'rgba(0,52,234,0.6)' }),
  stroke: new Stroke({ color: 'transparent', width: 2 }),
});

const trailStylesSequencing = new Style({
  fill: new Fill({ color: 'rgba(35,194,32,0.5)' }),
  stroke: new Stroke({ color: 'transparent', width: 2 }),
});

const trailStyleDelete = new Style({
  fill: new Fill({ color: 'rgba(209,0,0,0.4)' }),
  stroke: new Stroke({ color: 'rgba(209,0,0,1)', width: 5 }),
});

// Only used during initialization after that 'styleFuncTrails' is used instead
const styleFunctionTrails = (feature, resolution) => {
  if (feature.get('trailData').state === 1) {
    return trailStylesViewed;
  }
  return trailStyles;
};

const thermometerIcon = new Icon({
  opacity: 1,
  src: Thermometer,
});

const defenderIcon = new Icon({
  opacity: 1,
  src: DefenderImage,
});

const sensorIcon = new Icon({
  opacity: 1,
  src: Sensor,
});

const flowZoneSensorIcon = new Icon({
  opacity: 1,
  src: FlowZoneSensor,
});

const flowZoneAlarmSensorIcon = new Icon({
  opacity: 1,
  src: FlowZoneAlarmSensor,
});

const sensorIconSelected = new Icon({
  opacity: 1,
  src: SensorSelected,
});

const valveIcon = new Icon({
  opacity: 1,
  src: ValveImage,
});

const valveIconOpen = new Icon({
  opacity: 1,
  src: ValveImageOpen,
});

const valveIconClosed = new Icon({
  opacity: 1,
  src: ValveImageClosed,
});

const shapeStylesThermometer = new Style({
  image: thermometerIcon,
});

const shapeStylesDefender = new Style({
  image: defenderIcon,
});

const shapeStylesSensor = new Style({
  image: sensorIcon,
});

const shapeStylesFlowZoneAlarmSensor = new Style({
  image: flowZoneAlarmSensorIcon,
});

const shapeStylesFlowZoneSensor = new Style({
  image: flowZoneSensorIcon,
});

const shapeStylesSensorSelected = new Style({
  image: sensorIconSelected,
});

const shapeStylesValve = new Style({
  image: valveIcon,
});

const shapeStylesValveOpen = new Style({
  image: valveIconOpen,
});

const shapeStylesValveClosed = new Style({
  image: valveIconClosed,
});

const styleFunctionThermometer = (feature, resolution) => {
  thermometerIcon.setScale((1 * trackerEquipmentIconScale) / resolution);
  return shapeStylesThermometer;
};

const styleFunctionDefender = (feature, resolution) => {
  defenderIcon.setScale((1 * trackerEquipmentIconScale) / resolution);
  return shapeStylesDefender;
};

const styleFunctionSensor = (feature, resolution) => {
  sensorIcon.setScale((1 * trackerEquipmentIconScale) / resolution);
  return shapeStylesSensor;
};

const styleFunctionFlowZoneAlarmSensor = (feature, resolution) => {
  flowZoneAlarmSensorIcon.setScale((1 * trackerEquipmentIconScale) / resolution);
  return shapeStylesFlowZoneAlarmSensor;
};

const styleFunctionFlowZoneSensor = (feature, resolution) => {
  flowZoneSensorIcon.setScale((1 * trackerEquipmentIconScale) / resolution);
  return shapeStylesFlowZoneSensor;
};

const styleFunctionValve = (feature, resolution) => {
  const drainValveData = feature.get('drainValveData');

  if (drainValveData?.ioValues?.opened?.value) {
    valveIconOpen.setScale((1 * trackerEquipmentIconScale) / resolution);
    return shapeStylesValveOpen;
  } else if (drainValveData?.ioValues?.closed?.value) {
    valveIconClosed.setScale((1 * trackerEquipmentIconScale) / resolution);
    return shapeStylesValveClosed;
  } else {
    valveIcon.setScale((1 * trackerEquipmentIconScale) / resolution);
    return shapeStylesValve;
  }
};

// Style objects with Hexagon's for each state
const shapeStylesSelected = new Style({
  image: hexagonSelected,
});
const mobileStylesSelected = new Style({
  image: mobileSelected,
});

const pattern = new FillPattern({
  pattern: 'hatch',
  color: 'rgba(255,128,0,1)',
  size: '5',
  angle: 75,
  sale: '1',
  fill: new Fill({ color: 'rgba(255,128,0,0.6)' }),
  spacing: 18,
});

const patternView = new FillPattern({
  pattern: 'hatch',
  color: 'rgba(75,115,255,1)',
  size: '5',
  angle: 75,
  sale: '1',
  fill: new Fill({ color: 'rgba(75,115,255,0.6)' }),
  spacing: 18,
});

const trailStylesSelected = new Style({
  fill: pattern,
  stroke: new Stroke({ color: 'transparent', width: 1 }),
});

const defaultEditStyle = [
  /* We are using two different styles for the polygons:
   *  - The first style is for the polygons themselves.
   *  - The second style is to draw the vertices of the polygons.
   *    In a custom `geometry` function the vertices of a polygon are
   *    returned as `MultiPoint` geometry, which will be used to render
   *    the style.
   */
  new Style({
    stroke: new Stroke({
      color: 'blue',
      width: 3,
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 255, 0.1)',
    }),
  }),
  new Style({
    image: new CircleStyle({
      radius: 5,
      fill: new Fill({
        color: 'orange',
      }),
    }),
    geometry(feature) {
      // console.log(feature.get('hydrantData'));
      // return the coordinates of the first ring of the polygon
      const coordinates = feature.getGeometry().getCoordinates();
      // console.log(coordinates);
      // Need to account for differences of multi-polygon and polygon
      const firstGeom = coordinates[0].length < 2 ? coordinates[0][0] : coordinates[0];
      const vertices = [...firstGeom];
      for (let i = 1; i < coordinates.length; i++) {
        vertices.push(...coordinates[i][0]);
      }
      // console.log(vertices);
      return new MultiPoint(vertices);
    },
  }),
];
const defaultStylePipes = [
  /* We are using two different styles for the polygons:
   *  - The first style is for the polygons themselves.
   *  - The second style is to draw the vertices of the polygons.
   *    In a custom `geometry` function the vertices of a polygon are
   *    returned as `MultiPoint` geometry, which will be used to render
   *    the style.
   */
  new Style({
    stroke: new Stroke({
      color: 'blue',
      width: 3,
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 255, 0.1)',
    }),
  }),
  new Style({
    image: new CircleStyle({
      radius: 5,
      fill: new Fill({
        color: 'orange',
      }),
    }),
    // geometry(feature) {
    //   console.log('=======');
    //   console.log(feature.get('hydrantData'));
    //   // return the coordinates of the first ring of the polygon
    //   const coordinates = feature.getGeometry().getCoordinates();
    //   console.log(coordinates);
    //   // Need to account for differences of multi-polygon and polygon
    //   const firstGeom = coordinates[0].length < 2 ? coordinates[0][0] : coordinates[0];
    //   const vertices = [...firstGeom];
    //   for (let i = 1; i < coordinates.length; i++) {
    //     vertices.push(...coordinates[i][0]);
    //   }
    //   return new LineString(vertices);
    // },
  }),
];

const nonSelectedEditStyle = [
  /* We are using two different styles for the polygons:
   *  - The first style is for the polygons themselves.
   *  - The second style is to draw the vertices of the polygons.
   *    In a custom `geometry` function the vertices of a polygon are
   *    returned as `MultiPoint` geometry, which will be used to render
   *    the style.
   */
  new Style({
    fill: new Fill({
      color: 'rgba(255,255,255,0.4)',
    }),
    stroke: new Stroke({
      color: 'black',
      width: 1,
    }),
  }),
  new Style({
    image: new CircleStyle({
      radius: 3,
      fill: new Fill({
        color: 'black',
      }),
    }),
    geometry(feature) {
      // return the coordinates of the first ring of the polygon
      const coordinates = feature.getGeometry().getCoordinates();
      // Need to account for differences of multi-polygon and polygon
      const firstGeom = coordinates[0].length < 2 ? coordinates[0][0] : coordinates[0];
      const vertices = [...firstGeom];
      for (let i = 1; i < coordinates.length; i++) {
        vertices.push(...coordinates[i][0]);
      }
      return new MultiPoint(vertices);
    },
  }),
];
// const defaultEditStyle = [new Style({
//   fill: new Fill({
//     color: [255, 255, 255, 0.5],
//   }),
//   stroke: new Stroke({
//     color: [0, 153, 255, 1],
//     width: 3,
//   }),
// }),
// new Style({
//   image: new CircleStyle({
//     radius: 5,
//     fill: new Fill({
//       color: 'orange',
//     }),
//   }),
//   geometry(feature) {
//     // return the coordinates of the first ring of the polygon
//     const coordinates = feature.getGeometry().getCoordinates()[0];
//     return new MultiPoint(coordinates);
//   },
// }),
// ];

const trailStylesViewed = new Style({
  fill: patternView,
  stroke: new Stroke({ color: 'transparent', width: 1 }),
});

const styleFunctionSelected = (feature, resolution) => {
  hexagonSelected.setScale(0.2 / resolution);
  shapeStylesSelected.setText(new Text({
    overflow: true,
    font: 'normal 14px Verdana',
    text: map.getView().getZoom() > labelZoom ? feature.get('hydrantData')?.name : '',
    fill: new Fill({ color: 'white' }),
    stroke: new Stroke({
      color: '#000', width: 2,
    }),
  }));
  const hydrantData = feature.get('hydrantData');
  // const trailData = feature.get('trailData');
  if (hydrantData) {
    return shapeStylesSelected;
  }
  return trailStylesSelected;
};
const styleFunctionMobileSelected = (feature, resolution) => {
  mobileSelected.setScale(0.2 / resolution);
  mobileStylesSelected.setText(new Text({
    overflow: true,
    font: 'normal 14px Verdana',
    text: map.getView().getZoom() > labelZoom ? `${feature.get('hydrantData')?.name}:\n${feature.get('hydrantData')?.mobileGun?.name}` : '',
    fill: new Fill({ color: 'white' }),
    stroke: new Stroke({
      color: '#000', width: 2,
    }),
  }));
  const hydrantData = feature.get('hydrantData');
  // const trailData = feature.get('trailData');
  if (hydrantData) {
    return mobileStylesSelected;
  }
  return trailStylesSelected;
};

const defaultHydrantFilters = {
  all: true,
  off: true,
  on: true,
  alarm: true,
  locked: true,
  standby: true,
  notes: true,
  mobiles: true,
};

const defaultHydrantFiltersFalse = {
  all: false,
  off: false,
  on: false,
  alarm: false,
  locked: false,
  standby: false,
  notes: false,
  mobiles: false,
};

let guns = [];
let mobileGuns = [];
let gunLookup = {};
let hydrantModels = [];
let hydrantModelLookup = {};

function TrackerScreen(props) {
  // console.log(props.trails);
  // console.log(props.hydrants);
  // console.log(props.hydrants?.hydrant);
  const theme = useTheme();
  const styles = stylesFromTheme(theme);
  const { language } = useContext(LanguageContext);

  // const [hoveredHydrantID, setHoveredHydrantID] = useState(null);
  const [editHydrantID, setEditHydrantID] = useState(null);
  // SCOTT: editHydrantCoord passed to editoverlay, increase hydrant position accuracy
  const [editHydrantCoord, setEditHydrantCoord] = useState(null);
  const [editSensorData, setEditSensorData] = useState(null);
  const [editSensorCoord, setEditSensorCoord] = useState(null);
  const [editHydrantTrail, setEditHydrantTrail] = useState(null);
  const [hoverOverlayData, setHoverOverlayData] = useState({});
  const [showMenu, setShowMenu] = useState(false);
  const [showLegend, setShowLegend] = useState(false);
  const [editMode, setEditMode] = useState(0);
  const [snowDepthMode, setSnowDepthMode] = useState(false);
  const [translationActive, setTranslationActive] = useState(false);
  const [selectedLength, setSelectedLength] = useState(0);
  const [modifyEnd, setModifyEnd] = useState(false);
  const [featureAdd, setFeatureAdd] = useState(null);
  const [featureRemove, setFeatureRemove] = useState(false);
  const [gen, setGen] = useState(0);
  const [hydrantsGen, setHydrantsGen] = useState(0);
  const [closeHydrantMenu, setCloseHydrantMenu] = useState(false);
  const [trailMenuVisible, setTrailMenuVisible] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [mobileViewVisible, setMobileViewVisible] = useState(false);
  const [trailListVisible, setTrailListVisible] = useState(false);
  const [globalNotesViewVisible, setGlobalNotesViewVisible] = useState(false);
  const [autoTrailSettingsVisible, setAutoTrailSettingsVisible] = useState(false);
  const [hydrantSettingsVisible, setHydrantSettingsVisible] = useState(false);
  const [weatherClosed, setWeatherClosed] = useState(() => ({}));
  const [defendersClosed, setDefendersClosed] = useState(() => ({}));
  const [valvesClosed, setValvesClosed] = useState(() => ({}));
  const [globalWeatherToggle, setGlobalWeatherToggle] = useState(true);
  const [hydrantFilters, setHydrantFilters] = useState(() => (defaultHydrantFilters));
  const [gunModelLookup, setGunModelLookup] = useState({});
  const [ipAddressLookup, setIpAddressLookup] = useState({});
  const [editTrailID, _setEditTrailID] = useState(null);
  const [initRequestsComplete, setInitRequestsComplete] = useState(!!map);
  const [valvesLoading, setValvesLoading] = useState(!map);
  const [defendersLoading, setDefendersLoading] = useState(!map);
  const [weatherLoading, setWeatherLoading] = useState(!map);
  const [sensorsLoading, setSensorsLoading] = useState(!map);
  const [snowDepthLoading, setSnowDepthLoading] = useState(!map);
  const [renderLoader, setRenderLoader] = useState(true);
  const [hydrantsArray, setHydrantsArray] = useState(Object.values(props.trails?.hydrants || {}));
  const [trailsArray, setTrailsArray] = useState(Object.values(props.trails?.trails || {}));
  const [gatewaysArray, setGatewaysArray] = useState(Object.values(props.gateways?.gateways || {}));
  const [hydrant, setHydrant] = useState({});
  const [hydrantPriorityView, setHydrantPriorityView] = useState(false);
  const [trendFlow, setTrendFlow] = useState({});
  const [trendWetbulb, setTrendWetbulb] = useState({});
  const [hydrantTrendFilter, setHydrantTrendFilter] = useState('8h');
  const [hydrantTrend, setHydrantTrend] = useState(false);
  const [mobile, setMobile] = useState({});
  const [miniIntervalID, setMiniIntervalID] = useState();
  const [globalNotes, setGlobalNotes] = useState(props.hydrants?.globalNotes);
  const [weatherStationOptions, setWeatherStationOptions] = useState(props.weatherStations?.weatherStations);
  const [flowManagementConfigs, setFlowManagementConfigs] = useState(props.flowManagementSettings?.flowManagementSettings);
  const [autoTrails, setAutoTrails] = useState(props.trails?.autotrails);
  const [trailListSelection, setTrailListSelection] = useState(false);
  const [klikWeatherStations, setKlikWeatherStations] = useState({});

  const cancelTokenSourceRef = useRef(null);
  const editTrailIDRef = useRef(editTrailID);
  // const hydrantTrendFilterRef = useRef(hydrantTrendFilter);
  // const hydrantTrendRef = useRef(hydrantTrend);
  // const selectedHydrantIDRef = useRef(null);
  const selectedMobileIDRef = useRef(null);
  const mobileIntervalIDRef = useRef(null);

  const setEditTrailID = (data) => {
    editTrailIDRef.current = data;
    _setEditTrailID(data);
  };

  const legendIcons = {
    configIconBlack,
    configIconDarkTheme,
    configIconOriginalTheme,
    keyIconBlack,
    keyIconDarkTheme,
    keyIconOriginalTheme,
    trailDownloadBlack,
    trailDownloadDarkTheme,
    trailDownloadOriginalTheme,
    hydrantDownloadBlack,
    hydrantDownloadDarkTheme,
    hydrantDownloadOriginalTheme,
    thermoBlack,
    thermoDarkTheme,
    thermoOriginalTheme,
    snowGunBlack,
    snowGunDarkTheme,
    snowGunOriginalTheme,
    trailIconBlack,
    trailIconDarkTheme,
    trailIconOriginalTheme,
    sensorImageBlack,
    sensorImageDarkTheme,
    sensorImageOriginalTheme,
    snowDepthImageDarkTheme,
    snowDepthImageOriginalTheme,
    snowDepthImageBlack,
  };

  // This is an experiment with an "adaptive" style function that would replace the need for individual style functions
  // const styleFunctionAdaptive = (feature, resolution) => {
  //   let backgroundColor;
  //   const selected = feature.get('selected');
  //   const hydrantData = feature.get('hydrantData');

  //   if (selected) {
  //     backgroundColor = 'rgba(255,128,0,0.7)';
  //   } else if (hydrantData.isEnabled) {
  //     backgroundColor = theme.onGreen;
  //   } else {
  //     backgroundColor = theme.offRed;
  //   }

  //   const hex = new RegularShape({
  //     // fill: new Fill({ color: 'rgba(141, 199, 56, 0.8)' }),
  //     fill: new Fill({ color: `${backgroundColor}80` }),
  //     stroke: new Stroke({
  //       color: selected ? 'rgba(255,128,0,1)' : hydrantData.hasUnacknowledged ? 'rgb(204, 0, 255)' : 'black',
  //       width: hydrantData.hasUnacknowledged && !selected ? noteStrokeWidth : strokeWidth,
  //     }),
  //     radius,
  //     radius2,
  //     points: 3,
  //   });
  //   hex.setScale(0.2 / resolution);

  //   const hexStyle = new Style({
  //     image: hex,
  //   });

  //   hexStyle.setText(new Text({
  //     overflow: true,
  //     font: 'normal 14px Verdana',
  //     text: map.getView().getZoom() > labelZoom ? feature.get('hydrantData')?.name : '',
  //     fill: new Fill({ color: 'white' }),
  //   }));

  //   return hexStyle;
  // };

  // Fill color for hydrants in the 'On' state
  const onFill = useMemo(() => {
    return new Fill({ color: `${theme.onGreen}CC` });
  }, []);

  // Fill color for hydrants in the 'Off' state
  const offFill = useMemo(() => {
    return new Fill({ color: `${theme.offRed}CC` });
  }, []);

  // Fill color for hydrants with low priority
  const priorityZeroFill = useMemo(() => {
    return new Fill({ color: 'rgb(184,200,255)' });
  }, []);

  // Fill color for hydrants with medium priority
  const priorityOneFill = useMemo(() => {
    return new Fill({ color: 'rgb(75,115,255)' });
  }, []);

  // Fill color for hydrants with high priority
  const priorityTwoFill = useMemo(() => {
    return new Fill({ color: 'rgb(0,52,234)' });
  }, []);

  // Fill color for hydrants in the 'Alarm' state
  const alarmFill = useMemo(() => {
    return new Fill({ color: `${theme.alarm}CC` });
  }, []);

  // Fill color for hydrants in the 'Locked' state
  const lockedFill = useMemo(() => {
    return new Fill({ color: `${theme.connectivity}CC` });
  }, []);

  // Fill color for hydrants in the 'Standby' state
  const standbyFill = useMemo(() => {
    return new Fill({ color: 'rgba(153, 201, 255, 0.6)' });
  }, []);

  // Stroke style for hydrants in the 'On' state
  const onStroke = useMemo(() => {
    return new Stroke({
      color: 'rgba(1,120,0,1)',
      width: strokeWidth,
    });
  }, []);

  // Stroke style for hydrants in the 'Off' state
  const offStroke = useMemo(() => {
    return new Stroke({
      color: `${theme.hydrantOffStroke}`,
      width: strokeWidth,
    });
  }, []);

  // Stroke style for hydrants in the 'Alarm' state
  const alarmStroke = useMemo(() => {
    return new Stroke({
      color: 'rgba(120,0,0,1)',
      width: strokeWidth,
    });
  }, []);

  // Stroke style for hydrants in the 'Locked' state
  const lockedStroke = useMemo(() => {
    return new Stroke({
      color: 'black',
      width: strokeWidth,
    });
  }, []);

  // Stroke style for hydrants in the standby state
  const standbyStroke = useMemo(() => {
    return new Stroke({
      color: 'rgba(0,94,204,1)',
      width: strokeWidth,
    });
  }, []);

  // Stroke style for hydrants with open notes
  const noteStroke = useMemo(() => {
    return new Stroke({
      color: 'rgb(204, 0, 255)',
      width: noteStrokeWidth,
    });
  }, []);

  // Stroke style on the hydrant text labels
  const textStroke = useMemo(() => {
    return new Stroke({
      color: '#000',
      width: 2,
    });
  }, []);

  // hexagon
  const hexagonOn = useMemo(() => {
    return new RegularShape({
      fill: onFill,
      stroke: onStroke,
      radius,
      radius2,
      points: 3,
    });
  });

  const hexagonOnNotes = useMemo(() => {
    return new RegularShape({
      fill: onFill,
      stroke: noteStroke,
      radius,
      radius2,
      points: 3,
    });
  });

  const hexagonOff = useMemo(() => {
    return new RegularShape({
      fill: offFill,
      stroke: offStroke,
      radius,
      radius2,
      points: 3,
    });
  });

  const hexagonOffNotes = useMemo(() => {
    return new RegularShape({
      fill: offFill,
      stroke: noteStroke,
      radius,
      radius2,
      points: 3,
    });
  });

  const hexagonPriorityZero = useMemo(() => {
    return new RegularShape({
      fill: priorityZeroFill,
      stroke: offStroke,
      radius,
      radius2,
      points: 3,
    });
  });

  const hexagonPriorityOne = useMemo(() => {
    return new RegularShape({
      fill: priorityOneFill,
      stroke: offStroke,
      radius,
      radius2,
      points: 3,
    });
  });

  const hexagonPriorityTwo = useMemo(() => {
    return new RegularShape({
      fill: priorityTwoFill,
      stroke: offStroke,
      radius,
      radius2,
      points: 3,
    });
  });

  const hexagonAlarm = useMemo(() => {
    return new RegularShape({
      fill: alarmFill,
      stroke: alarmStroke,
      radius,
      radius2,
      points: 3,
    });
  });

  const hexagonAlarmNotes = useMemo(() => {
    return new RegularShape({
      fill: alarmFill,
      stroke: noteStroke,
      radius,
      radius2,
      points: 3,
    });
  });

  const hexagonLocked = useMemo(() => {
    return new RegularShape({
      fill: lockedFill,
      stroke: lockedStroke,
      radius,
      radius2,
      points: 3,
    });
  });

  const hexagonLockedNotes = useMemo(() => {
    return new RegularShape({
      fill: lockedFill,
      stroke: noteStroke,
      radius,
      radius2,
      points: 3,
    });
  });

  const hexagonStandby = useMemo(() => {
    return new RegularShape({
      fill: standbyFill,
      stroke: standbyStroke,
      radius,
      radius2,
      points: 3,
    });
  });

  const hexagonStandbyNotes = useMemo(() => {
    return new RegularShape({
      fill: standbyFill,
      stroke: noteStroke,
      radius,
      radius2,
      points: 3,
    });
  });

  const shapeStylesOn = useMemo(() => {
    return new Style({
      image: hexagonOn,
    });
  }, []);

  const shapeStylesOnNotes = useMemo(() => {
    return new Style({
      image: hexagonOnNotes,
    });
  }, []);

  const shapeStylesOff = useMemo(() => {
    return new Style({
      image: hexagonOff,
    });
  }, []);

  const shapeStylesOffNotes = useMemo(() => {
    return new Style({
      image: hexagonOffNotes,
    });
  }, []);

  const shapeStylesPriorityZero = useMemo(() => {
    return new Style({
      image: hexagonPriorityZero,
    });
  }, []);

  const shapeStylesPriorityOne = useMemo(() => {
    return new Style({
      image: hexagonPriorityOne,
    });
  }, []);

  const shapeStylesPriorityTwo = useMemo(() => {
    return new Style({
      image: hexagonPriorityTwo,
    });
  }, []);

  const shapeStylesAlarm = useMemo(() => {
    return new Style({
      image: hexagonAlarm,
    });
  }, []);

  const shapeStylesAlarmNotes = useMemo(() => {
    return new Style({
      image: hexagonAlarmNotes,
    });
  }, []);

  const shapeStylesLocked = useMemo(() => {
    return new Style({
      image: hexagonLocked,
    });
  }, []);

  const shapeStylesLockedNotes = useMemo(() => {
    return new Style({
      image: hexagonLockedNotes,
    });
  }, []);

  const shapeStylesStandby = useMemo(() => {
    return new Style({
      image: hexagonStandby,
    });
  }, []);

  const shapeStylesStandbyNotes = useMemo(() => {
    return new Style({
      image: hexagonStandbyNotes,
    });
  }, []);

  // Style Functions to allow for appropriate scaling while zooming
  const styleFunctionOn = useMemo(() => {
    return (feature, resolution) => {
      hexagonOn.setScale(0.2 / resolution);
      shapeStylesOn.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? feature.get('hydrantData')?.name : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return shapeStylesOn;
    };
  }, []);

  const styleFunctionOnNotes = useMemo(() => {
    return (feature, resolution) => {
      hexagonOnNotes.setScale(0.2 / resolution);
      shapeStylesOnNotes.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? feature.get('hydrantData')?.name : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return shapeStylesOnNotes;
    };
  }, []);

  const styleFunctionOff = useMemo(() => {
    return (feature, resolution) => {
      hexagonOff.setScale(0.2 / resolution);
      shapeStylesOff.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? feature.get('hydrantData')?.name : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return shapeStylesOff;
    };
  }, []);

  const styleFunctionPriorityZero = useMemo(() => {
    return (feature, resolution) => {
      hexagonPriorityZero.setScale(0.2 / resolution);
      shapeStylesPriorityZero.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? `${feature.get('hydrantData')?.name}\n${feature.get('hydrantData')?.sequencingPriority === 2 ? language.high : feature.get('hydrantData')?.sequencingPriority === 1 ? language.med : feature.get('hydrantData')?.sequencingPriority === 0 ? language.low : ''}` : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return shapeStylesPriorityZero;
    };
  }, []);

  const styleFunctionPriorityOne = useMemo(() => {
    return (feature, resolution) => {
      hexagonPriorityOne.setScale(0.2 / resolution);
      shapeStylesPriorityOne.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? `${feature.get('hydrantData')?.name}\n${feature.get('hydrantData')?.sequencingPriority === 2 ? language.high : feature.get('hydrantData')?.sequencingPriority === 1 ? language.med : feature.get('hydrantData')?.sequencingPriority === 0 ? language.low : ''}` : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return shapeStylesPriorityOne;
    };
  }, []);

  const styleFunctionPriorityTwo = useMemo(() => {
    return (feature, resolution) => {
      hexagonPriorityTwo.setScale(0.2 / resolution);
      shapeStylesPriorityTwo.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? `${feature.get('hydrantData')?.name}\n${feature.get('hydrantData')?.sequencingPriority === 2 ? language.high : feature.get('hydrantData')?.sequencingPriority === 1 ? language.med : feature.get('hydrantData')?.sequencingPriority === 0 ? language.low : ''}` : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return shapeStylesPriorityTwo;
    };
  }, []);

  const styleFunctionOffNotes = useMemo(() => {
    return (feature, resolution) => {
      hexagonOffNotes.setScale(0.2 / resolution);
      shapeStylesOffNotes.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? feature.get('hydrantData')?.name : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return shapeStylesOffNotes;
    };
  }, []);

  const styleFunctionAlarm = useMemo(() => {
    return (feature, resolution) => {
      hexagonAlarm.setScale(0.2 / resolution);
      shapeStylesAlarm.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? feature.get('hydrantData')?.name : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return shapeStylesAlarm;
    };
  }, []);

  const styleFunctionAlarmNotes = useMemo(() => {
    return (feature, resolution) => {
      hexagonAlarmNotes.setScale(0.2 / resolution);
      shapeStylesAlarmNotes.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? feature.get('hydrantData')?.name : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return shapeStylesAlarmNotes;
    };
  }, []);

  const styleFunctionLocked = useMemo(() => {
    return (feature, resolution) => {
      hexagonLocked.setScale(0.2 / resolution);
      shapeStylesLocked.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? feature.get('hydrantData')?.name : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return shapeStylesLocked;
    };
  }, []);

  const styleFunctionLockedNotes = useMemo(() => {
    return (feature, resolution) => {
      hexagonLockedNotes.setScale(0.2 / resolution);
      shapeStylesLockedNotes.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? feature.get('hydrantData')?.name : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return shapeStylesLockedNotes;
    };
  }, []);

  const styleFunctionStandby = useMemo(() => {
    return (feature, resolution) => {
      hexagonStandby.setScale(0.2 / resolution);
      shapeStylesStandby.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? feature.get('hydrantData')?.name : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return shapeStylesStandby;
    };
  }, []);

  const styleFunctionStandbyNotes = useMemo(() => {
    return (feature, resolution) => {
      hexagonStandbyNotes.setScale(0.2 / resolution);
      shapeStylesStandbyNotes.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? feature.get('hydrantData')?.name : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return shapeStylesStandbyNotes;
    };
  }, []);
  // mobile
  const mobileOn = useMemo(() => {
    return new RegularShape({
      fill: onFill,
      stroke: onStroke,
      radius,
      radius2,
      points: 2,
    });
  });

  const mobileOnNotes = useMemo(() => {
    return new RegularShape({
      fill: onFill,
      stroke: noteStroke,
      radius,
      radius2,
      points: 2,
    });
  });

  const mobileOff = useMemo(() => {
    return new RegularShape({
      fill: offFill,
      stroke: offStroke,
      radius,
      radius2,
      points: 2,
    });
  });

  const mobileOffNotes = useMemo(() => {
    return new RegularShape({
      fill: offFill,
      stroke: noteStroke,
      radius,
      radius2,
      points: 2,
    });
  });

  const mobilePriorityZero = useMemo(() => {
    return new RegularShape({
      fill: priorityZeroFill,
      stroke: offStroke,
      radius,
      radius2,
      points: 2,
    });
  });

  const mobilePriorityOne = useMemo(() => {
    return new RegularShape({
      fill: priorityOneFill,
      stroke: offStroke,
      radius,
      radius2,
      points: 2,
    });
  });

  const mobilePriorityTwo = useMemo(() => {
    return new RegularShape({
      fill: priorityTwoFill,
      stroke: offStroke,
      radius,
      radius2,
      points: 2,
    });
  });

  const mobileAlarm = useMemo(() => {
    return new RegularShape({
      fill: alarmFill,
      stroke: alarmStroke,
      radius,
      radius2,
      points: 2,
    });
  });

  const mobileAlarmNotes = useMemo(() => {
    return new RegularShape({
      fill: alarmFill,
      stroke: noteStroke,
      radius,
      radius2,
      points: 2,
    });
  });

  const mobileLocked = useMemo(() => {
    return new RegularShape({
      fill: lockedFill,
      stroke: lockedStroke,
      radius,
      radius2,
      points: 2,
    });
  });

  const mobileLockedNotes = useMemo(() => {
    return new RegularShape({
      fill: lockedFill,
      stroke: noteStroke,
      radius,
      radius2,
      points: 2,
    });
  });

  const mobileStandby = useMemo(() => {
    return new RegularShape({
      fill: standbyFill,
      stroke: standbyStroke,
      radius,
      radius2,
      points: 2,
    });
  });

  const mobileStandbyNotes = useMemo(() => {
    return new RegularShape({
      fill: standbyFill,
      stroke: noteStroke,
      radius,
      radius2,
      points: 2,
    });
  });

  const mobileStylesOn = useMemo(() => {
    return new Style({
      image: mobileOn,
    });
  }, []);

  const mobileStylesOnNotes = useMemo(() => {
    return new Style({
      image: mobileOnNotes,
    });
  }, []);

  const mobileStylesOff = useMemo(() => {
    return new Style({
      image: mobileOff,
    });
  }, []);

  const mobileStylesOffNotes = useMemo(() => {
    return new Style({
      image: mobileOffNotes,
    });
  }, []);

  const mobileStylesAlarm = useMemo(() => {
    return new Style({
      image: mobileAlarm,
    });
  }, []);

  const mobileStylesAlarmNotes = useMemo(() => {
    return new Style({
      image: mobileAlarmNotes,
    });
  }, []);

  const mobileStylesPriorityZero = useMemo(() => {
    return new Style({
      image: mobilePriorityZero,
    });
  }, []);

  const mobileStylesPriorityOne = useMemo(() => {
    return new Style({
      image: mobilePriorityOne,
    });
  }, []);

  const mobileStylesPriorityTwo = useMemo(() => {
    return new Style({
      image: mobilePriorityTwo,
    });
  }, []);

  const mobileStylesLocked = useMemo(() => {
    return new Style({
      image: mobileLocked,
    });
  }, []);

  const mobileStylesLockedNotes = useMemo(() => {
    return new Style({
      image: mobileLockedNotes,
    });
  }, []);

  const mobileStylesStandby = useMemo(() => {
    return new Style({
      image: mobileStandby,
    });
  }, []);

  const mobileStylesStandbyNotes = useMemo(() => {
    return new Style({
      image: mobileStandbyNotes,
    });
  }, []);

  // Style Functions to allow for appropriate scaling while zooming
  const styleFunctionMobileOn = useMemo(() => {
    return (feature, resolution) => {
      mobileOn.setScale(0.2 / resolution);
      mobileStylesOn.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? `${feature.get('hydrantData')?.name}:\n${feature.get('hydrantData')?.mobileGun?.name}` : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return mobileStylesOn;
    };
  }, []);

  const styleFunctionMobileOnNotes = useMemo(() => {
    return (feature, resolution) => {
      mobileOnNotes.setScale(0.2 / resolution);
      mobileStylesOnNotes.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? `${feature.get('hydrantData')?.name}:\n${feature.get('hydrantData')?.mobileGun?.name}` : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return mobileStylesOnNotes;
    };
  }, []);

  const styleFunctionMobileOff = useMemo(() => {
    return (feature, resolution) => {
      mobileOff.setScale(0.2 / resolution);
      mobileStylesOff.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? `${feature.get('hydrantData')?.name}:\n${feature.get('hydrantData')?.mobileGun?.name}` : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return mobileStylesOff;
    };
  }, []);

  const styleFunctionMobileOffNotes = useMemo(() => {
    return (feature, resolution) => {
      mobileOffNotes.setScale(0.2 / resolution);
      mobileStylesOffNotes.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? `${feature.get('hydrantData')?.name}:\n${feature.get('hydrantData')?.mobileGun?.name}` : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return mobileStylesOffNotes;
    };
  }, []);

  const styleFunctionMobileAlarm = useMemo(() => {
    return (feature, resolution) => {
      mobileAlarm.setScale(0.2 / resolution);
      mobileStylesAlarm.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? `${feature.get('hydrantData')?.name}:\n${feature.get('hydrantData')?.mobileGun?.name}` : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return mobileStylesAlarm;
    };
  }, []);

  const styleFunctionMobileAlarmNotes = useMemo(() => {
    return (feature, resolution) => {
      mobileAlarmNotes.setScale(0.2 / resolution);
      mobileStylesAlarmNotes.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? `${feature.get('hydrantData')?.name}:\n${feature.get('hydrantData')?.mobileGun?.name}` : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return mobileStylesAlarmNotes;
    };
  }, []);

  const styleFunctionMobileLocked = useMemo(() => {
    return (feature, resolution) => {
      mobileLocked.setScale(0.2 / resolution);
      mobileStylesLocked.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? `${feature.get('hydrantData')?.name}:\n${feature.get('hydrantData')?.mobileGun?.name}` : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return mobileStylesLocked;
    };
  }, []);

  const styleFunctionMobileLockedNotes = useMemo(() => {
    return (feature, resolution) => {
      mobileLockedNotes.setScale(0.2 / resolution);
      mobileStylesLockedNotes.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? `${feature.get('hydrantData')?.name}:\n${feature.get('hydrantData')?.mobileGun?.name}` : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return mobileStylesLockedNotes;
    };
  }, []);

  const styleFunctionMobileStandby = useMemo(() => {
    return (feature, resolution) => {
      mobileStandby.setScale(0.2 / resolution);
      mobileStylesStandby.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? `${feature.get('hydrantData')?.name}:\n${feature.get('hydrantData')?.mobileGun?.name}` : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return mobileStylesStandby;
    };
  }, []);

  const styleFunctionMobileStandbyNotes = useMemo(() => {
    return (feature, resolution) => {
      mobileStandbyNotes.setScale(0.2 / resolution);
      mobileStylesStandbyNotes.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? `${feature.get('hydrantData')?.name}:\n${feature.get('hydrantData')?.mobileGun?.name}` : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return mobileStylesStandbyNotes;
    };
  }, []);

  const styleFunctionMobilePriorityZero = useMemo(() => {
    return (feature, resolution) => {
      mobilePriorityZero.setScale(0.2 / resolution);
      mobileStylesPriorityZero.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? `${feature.get('hydrantData')?.name}:\n${feature.get('hydrantData')?.mobileGun?.name}\n${feature.get('hydrantData')?.sequencingPriority === 2 ? language.high : feature.get('hydrantData')?.sequencingPriority === 1 ? language.med : feature.get('hydrantData')?.sequencingPriority === 0 ? language.low : ''}` : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return mobileStylesPriorityZero;
    };
  }, []);

  const styleFunctionMobilePriorityOne = useMemo(() => {
    return (feature, resolution) => {
      mobilePriorityOne.setScale(0.2 / resolution);
      mobileStylesPriorityOne.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? `${feature.get('hydrantData')?.name}:\n${feature.get('hydrantData')?.mobileGun?.name}\n${feature.get('hydrantData')?.sequencingPriority === 2 ? language.high : feature.get('hydrantData')?.sequencingPriority === 1 ? language.med : feature.get('hydrantData')?.sequencingPriority === 0 ? language.low : ''}` : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return mobileStylesPriorityOne;
    };
  }, []);

  const styleFunctionMobilePriorityTwo = useMemo(() => {
    return (feature, resolution) => {
      mobilePriorityTwo.setScale(0.2 / resolution);
      mobileStylesPriorityTwo.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? `${feature.get('hydrantData')?.name}:\n${feature.get('hydrantData')?.mobileGun?.name}\n${feature.get('hydrantData')?.sequencingPriority === 2 ? language.high : feature.get('hydrantData')?.sequencingPriority === 1 ? language.med : feature.get('hydrantData')?.sequencingPriority === 0 ? language.low : ''}` : '',
        fill: new Fill({ color: 'white' }),
        stroke: textStroke,
      }));
      return mobileStylesPriorityTwo;
    };
  }, []);

  // These trail/autotrail just strings so we don't need to use a useState we can pass them in as props without worrying about
  // too many rerenders being triggered. For globalNotes, hydrantsArray, and trailsArray we use the useState hook and isEqual
  // to check when changes have occured to prevent too many rerenders.
  const trail = props.trails?.selectedTrailName;
  const autoTrail = props.trails?.selectedAutoTrailName;
  // const hydrant = props.hydrants.selectedHydrantId;

  const lowerBound = props.settings?.settings?.snowDepthMin;
  const upperBound = props.settings?.settings?.snowDepthMax;
  snowDepthStyle = {
    'shape-points': 4,
    'shape-rotation': Math.PI / 4,
    'shape-radius': [
      'interpolate',
      ['exponential', 2],
      ['zoom'],
      11,
      0.4,
      21,
      0.4 * (10 ** 2),
    ],
    'shape-fill-color': [
      'interpolate',
      ['linear'],
      ['get', 'depth'],
      lowerBound,
      '#FF000090',
      lowerBound + ((upperBound - lowerBound) / 4),
      '#FFFF0090',
      upperBound - ((upperBound - lowerBound) / 4),
      '#00FF0090',
      upperBound,
      '#0000FF90',
    ],
    'shape-rotate-with-view': true,
  };

  useEffect(() => {
    const tempHydrant = props.hydrants?.selectedHydrantId ? props.hydrants?.hydrant : {};
    if (!isEqual(tempHydrant, hydrant)) {
      setHydrant(tempHydrant);
    }
  }, [props.hydrants?.selectedHydrantId, props.hydrants?.hydrant]);

  useEffect(() => {
    if (editHydrantID !== -1) {
      props.selectHydrant(editHydrantID);
    }
  }, [editHydrantID]);

  useEffect(() => {
    const tempTrend = props.hydrants?.hydrantTrendFlow ? props.hydrants?.hydrantTrendFlow : null;
    if (props.hydrants?.selectedHydrantId === null) {
      setTrendFlow({});
    } else if (!isEqual(tempTrend, trendFlow)) {
      setTrendFlow(tempTrend);
    }
  }, [props.hydrants?.selectedHydrantId, props.hydrants?.hydrantTrendFlow]);

  useEffect(() => {
    const tempTrend = props.hydrants?.hydrantTrendWetbulb ? props.hydrants?.hydrantTrendWetbulb : null;
    if (props.hydrants?.selectedHydrantId === null) {
      setTrendWetbulb({});
    } else if (!isEqual(tempTrend, trendWetbulb)) {
      setTrendWetbulb(tempTrend);
    }
  }, [props.hydrants?.selectedHydrantId, props.hydrants?.hydrantTrendWetbulb]);

  useEffect(() => {
    const tempMobile = props.mobileGuns?.selectedMobileID ? props.mobileGuns?.selectedMobileGun : {};
    if (!isEqual(tempMobile, mobile)) {
      setMobile(tempMobile);
    }
  }, [props.mobileGuns?.selectedMobileID, props.mobileGuns?.selectedMobileGun]);

  useEffect(() => {
    const tempGlobalNotes = props.hydrants?.globalNotes;
    if (!isEqual(tempGlobalNotes, globalNotes)) {
      setGlobalNotes(tempGlobalNotes);
    }
  }, [props.hydrants?.globalNotes]);

  useEffect(() => {
    const tempWeatherStationOptions = props.weatherStations?.weatherStations;
    if (!isEqual(tempWeatherStationOptions, weatherStationOptions)) {
      setWeatherStationOptions(tempWeatherStationOptions);
    }
  }, [props.weatherStations?.weatherStations]);

  useEffect(() => {
    const tempFlowZoneOptions = props.flowManagementSettings?.flowManagementSettings;
    if (!isEqual(tempFlowZoneOptions, flowManagementConfigs)) {
      setFlowManagementConfigs(tempFlowZoneOptions);
    }
  }, [props.flowManagementSettings?.flowManagementSettings]);

  useEffect(() => {
    const tempAutoTrails = props.trails?.autotrails;
    if (!isEqual(tempAutoTrails, autoTrails)) {
      setAutoTrails(tempAutoTrails);
    }
  }, [props.trails?.autotrails]);

  useEffect(() => {
    const tempHydrantsArray = Object.values(props.trails?.hydrants || {});
    // setHydrantsArray(Object.values(props.trails?.hydrants || {}));
    if (!isEqual(hydrantsArray, tempHydrantsArray)) {
      setHydrantsArray(tempHydrantsArray);
    }
  }, [props.trails?.hydrants]);

  useEffect(() => {
    const tempTrailsArray = Object.values(props.trails?.trails || {});
    if (!isEqual(trailsArray, tempTrailsArray)) {
      setTrailsArray(tempTrailsArray);
    }
  }, [props.trails?.trails]);

  useEffect(() => {
    const tempGatewaysArray = Object.values(props.gateways?.gateways || {});
    if (!isEqual(gatewaysArray, tempGatewaysArray)) {
      setGatewaysArray(tempGatewaysArray);
    }
  }, [props.gateways?.gateways]);

  // Custom Legend function to allow us to toggle all filters at once
  const legendCheckboxChange = (event, key) => {
    if (key !== 'all') {
      setHydrantFilters({ ...hydrantFilters, [key]: event.target.checked });
    } else {
      setHydrantFilters(event.target.checked ? defaultHydrantFilters : defaultHydrantFiltersFalse);
    }
  };

  useEffect(() => {
    function styleFuncSelected(feature, resolution) {
      hexagonSelected.setScale(0.2 / resolution);
      shapeStylesSelected.setText(new Text({
        overflow: true,
        font: 'normal 14px Verdana',
        text: map.getView().getZoom() > labelZoom ? feature.get('hydrantData')?.name : '',
        fill: new Fill({ color: 'white' }),
        stroke: new Stroke({
          color: '#000', width: 2,
        }),
      }));
      const hydrantData = feature.get('hydrantData');
      const sensorData = feature.get('sensorData');
      const trailData = feature.get('trailData');
      if (hydrantData) {
        return shapeStylesSelected;
      } else if (trailData && !editMode) {
        return trailStylesSelected;
      } else if (trailData && editMode === 2) {
        return trailStyles;
      } else if (sensorData && editMode === 3) {
        sensorIconSelected.setScale((1 * trackerEquipmentIconScale) / resolution);
        return shapeStylesSensorSelected;
      }
      // return editMode === 4 ? defaultStylePipes : defaultEditStyle;
      return defaultEditStyle;
    }
    // dynamic trail styles
    const styleFunc = (feature, resolution) => {
      if (!editMode && feature.get('trailData')?.name.replace('#', '') === trail && props.hydrants?.selectedHydrantId === null) {
        return trailStylesSelected;
      } else if (!editMode && props.trails?.priorityView && feature.get('trailData').sequencingPriority === 0) {
        return trailStylesPriorityZero;
      } else if (!editMode && props.trails?.priorityView && feature.get('trailData').sequencingPriority === 1) {
        return trailStylesPriorityOne;
      } else if (!editMode && props.trails?.priorityView && feature.get('trailData').sequencingPriority === 2) {
        return trailStylesPriorityTwo;
      } else if (!editMode && feature.get('trailData').state === 1) {
        return trailStylesViewed;
      } else if (!editMode && feature.get('trailData').isSequencingEnabled) {
        return trailStylesSequencing;
      } else if (editMode === 1) {
        return nonSelectedEditStyle;
      }
      return trailStyles;
    };

    if (trails) {
      trails.setStyle(styleFunc);
    }
    if (trailsAdd) {
      trailsAdd.setStyle(defaultEditStyle);
    }
    if (trailsDelete) {
      trailsDelete.setStyle(styleFunc);
    }
    if (select) {
      select.style_ = styleFuncSelected;
    }
  }, [editMode, trail, props.hydrants?.selectedHydrantId, editSensorData, trails, props.trails?.priorityView]);

  useEffect(() => {
    if (props.weatherStations?.weatherStations
      && Object.values(props.weatherStations?.weatherStations).length > 0
      && Object.values(weatherClosed).length === 0
    ) {
      setWeatherClosed(setDefaultWeatherClosed(props.weatherStations?.weatherStations));
    }
  }, [initRequestsComplete]);

  useEffect(() => {
    if (props.trackerEquipment.defenders
      && Object.values(props.trackerEquipment.defenders).length > 0
      && Object.values(defendersClosed).length === 0
    ) {
      setDefendersClosed(setDefaultDefendersClosed(props.trackerEquipment.defenders));
    }
  }, [initRequestsComplete]);

  useEffect(() => {
    if (props.equipment.valves
      && Object.values(props.equipment.valves).length > 0
      && Object.values(valvesClosed).length === 0
    ) {
      const tempValvesClosed = setDefaultValvesClosed(props.equipment.valves);
      setValvesClosed(tempValvesClosed);
    }
  }, [initRequestsComplete]);

  const updateWeatherStationVisibility = () => {
    if (props.weatherStations?.weatherStations) {
      const zoom = map.getView().getZoom();
      // Open/close weather station overlays if in edit mode or zoom level hit
      Object.values(props.weatherStations?.weatherStations).forEach((station) => {
        if (
          station.weatherlinkStation?.longitude
          || station.openWeatherMapLocation?.longitude
          || station.weatherStationTrackerEquipment?.longitude
          || station.weatherStationEquipment?.location?.longitude
        ) {
          const wOverlay = document.getElementById(`weatherOverlay${station.id}`);
          const tempFeature = weatherSource.getFeatureById(`weatherPoint_${station.id}`);
          if (wOverlay) {
            wOverlay.style.visibility = (((weatherClosed[station.id] || editMode) && zoom <= trackerEquipmentZoom) || editMode === 1) ? 'hidden' : 'visible';
          }
          if (tempFeature) {
            tempFeature.setStyle(((weatherClosed[station.id] || editMode) && zoom <= trackerEquipmentZoom && editMode !== 1 && !station.weatherStationTrackerEquipmentId) ? styleFunctionThermometer : new Style({}));
          }
        }
      });
    }
  };

  useEffect(() => {
    if (map && gen > 0) {
      if (changeResKey) {
        unByKey(changeResKey);
      }
      // We want to update the weather station visibility every time the resolution changes
      changeResKey = map.getView().on('change:resolution', updateWeatherStationVisibility);
      // We also want to make sure the current weather station visibility is correct when this use effect is triggered
      updateWeatherStationVisibility();
    }
  }, [gen, weatherClosed, editMode]);

  useEffect(() => {
    if (map && gen > 0) {
    // Iterate through our defenders and close/open their overlays depending on state
    // and or edit mode
      if (props.trackerEquipment?.defenders) {
        Object.values(props.trackerEquipment?.defenders).forEach((defender) => {
          const dOverlay = document.getElementById(`defender${defender.id}`);
          const tempFeature = defenderSource.getFeatureById(`defenderPoint_${defender.id}`);
          if (dOverlay) {
            dOverlay.style.visibility = (defendersClosed[defender.id] || editMode) ? 'hidden' : 'visible';
          }
          if (tempFeature) {
            tempFeature.setStyle(editMode !== 1 ? styleFunctionDefender : new Style({}));
          }
        });
      }
    }
  }, [gen, defendersClosed, editMode]);

  useEffect(() => {
    if (map && gen > 0) {
      if (props.equipment?.valves) {
        // Iterate through our valves and close/open their overlays depending on state
        // and or edit mode
        Object.values(props.equipment?.valves).forEach((valve) => {
          const vOverlay = document.getElementById(`valve${valve.id}`);
          const tempFeature = valveSource.getFeatureById(`drainPoint_${valve.id}`);
          // If in edit mode or valve is closed, hide overlay and set style
          // If valve is open, show overlay and hide icon with {} style
          if (vOverlay) {
            vOverlay.style.visibility = (valvesClosed[valve.id] || editMode) ? 'hidden' : 'visible';
          }
          if (tempFeature) {
            tempFeature.setStyle(editMode !== 1 ? styleFunctionValve : new Style({}));
          }
        });
      }
    }
  }, [gen, valvesClosed, editMode]);

  useEffect(() => {
    if (map && gen > 0) {
      // Remove the previous event listener for the singleclick using the stored key
      if (singleClickKey) {
        unByKey(singleClickKey);
      }
      // Now set the new singleclick event listener and update the stored key
      singleClickKey = map.on('singleclick', (e) => {
        const isHydrant = map.hasFeatureAtPixel(e.pixel, { layerFilter: (layer) => layer.get('name') === 'hydrants' });
        const isTrail = map.hasFeatureAtPixel(e.pixel, { layerFilter: (layer) => layer.get('name') === 'trails' });
        const isWeatherStation = map.hasFeatureAtPixel(e.pixel, { layerFilter: (layer) => layer.get('name') === 'weatherStations' });
        const isDefender = map.hasFeatureAtPixel(e.pixel, { layerFilter: (layer) => layer.get('name') === 'defendersLayer' });
        const isDrainValve = map.hasFeatureAtPixel(e.pixel, { layerFilter: (layer) => layer.get('name') === 'valvesLayer' });
        const isSensor = map.hasFeatureAtPixel(e.pixel, { layerFilter: (layer) => layer.get('name') === 'sensorsLayer' });
        if (editMode) {
          if (editMode === 2) {
            if (isHydrant) {
              editOverlay.setOffset([175, -15]);
              editOverlay.setPosition(e.pixel[1] < (window.innerHeight - (props?.settings?.settings?.language === 'french' ? 460 : 428)) ? e.coordinate : e.map.getCoordinateFromPixel([e.pixel[0], (window.innerHeight - (props?.settings?.settings?.language === 'french' ? 460 : 428) > 50) ? window.innerHeight - (props?.settings?.settings?.language === 'french' ? 460 : 428) : 50]));
              map.forEachFeatureAtPixel(e.pixel, (feature) => {
              // If the feature clicked is a hydrant it won't have an id_
                if (feature.id_ === undefined) {
                  setEditHydrantCoord(Proj.toLonLat(e.coordinate));
                  setEditHydrantID(feature.get('hydrantData')?.id);
                }
              });
            } else if (!isWeatherStation && !isDefender && !isDrainValve) {
              e.preventDefault();
              // NOTE: This is dependent on the edit overlay size so
              // if the overlay is changed this should also be adjusted
              editOverlay.setOffset([175, -15]);
              editOverlay.setPosition(e.pixel[1] < (window.innerHeight - 218) ? e.coordinate : e.map.getCoordinateFromPixel([e.pixel[0], (window.innerHeight - 218 > 50) ? window.innerHeight - 218 : 50]));
              setEditHydrantID(-1);
              setEditHydrantCoord(Proj.toLonLat(e.coordinate));
              if (isTrail) {
                map.forEachFeatureAtPixel(e.pixel, (feature) => {
                // If the feature clicked is a hydrant it won't have an id_
                  if (feature.id_) {
                    setEditHydrantTrail(feature.id_);
                  }
                });
              }
            }
          } else if (editMode === 1) {
            if (isTrail && !editTrailID) {
              map.forEachFeatureAtPixel(e.pixel, (feature) => {
                // If the feature clicked is a trail it will have an id_
                if (feature.id_ !== undefined) {
                  setEditTrailID(feature.id_);
                }
              });
            } else if (!isWeatherStation && !isDefender && !isDrainValve && editTrailID === null) {
              e.preventDefault();
              setEditTrailID(-1);
            }
          } else if (editMode === 3) {
            e.preventDefault();
            sensorEditOverlay.setOffset([205, 2]);
            sensorEditOverlay.setPosition(e.coordinate);
            setEditSensorCoord(Proj.toLonLat(e.coordinate));
            if (isSensor) {
              map.forEachFeatureAtPixel(e.pixel, (feature) => {
                if (feature.get('sensorData') !== undefined) {
                  setEditSensorData(feature.get('sensorData'));
                }
              });
            } else {
              setEditSensorData(null);
              // SCOTT: not sure why I added the line below, but it caused a bug where the elevation couldn't be fetched
              // setEditSensorCoord(null);
            }
          // } else if (editMode === 4) {
          //   e.preventDefault();
          //   sensorEditOverlay.setOffset([175, -15]);
          //   sensorEditOverlay.setPosition(e.coordinate);
          //   setEditSensorCoord(Proj.toLonLat(e.coordinate));
          //   if (isSensor) {
          //     map.forEachFeatureAtPixel(e.pixel, (feature) => {
          //       if (feature.get('sensorData') !== undefined) {
          //         setEditSensorData(feature.get('sensorData'));
          //       }
          //     });
          //   } else {
          //     setEditSensorData(null);
          //   }
          } else {
            e.preventDefault();
            editOverlay.setPosition(undefined);
            sensorEditOverlay.setPosition(undefined);
          }
        } else if (isHydrant) {
          map.forEachFeatureAtPixel(e.pixel, (feature) => {
            // If the feature clicked is a hydrant it won't have an id_
            if (feature.id_ === undefined) {
              const hydrantData = feature.get('hydrantData');
              // setDeleteHydrantInterval(true);
              props.selectHydrant(hydrantData?.id);
              props.selectTrail(hydrantData?.trailName);
              props.selectAutoTrail(null);
              setGlobalNotesViewVisible(false);
              setHydrantSettingsVisible(false);
              setMobileMenuVisible(false);
              setMobileViewVisible(false);
              props.selectMobile(null);
            }
          });
        } else if (isWeatherStation) {
          e.preventDefault();
          map.forEachFeatureAtPixel(e.pixel, (feature) => {
            const weatherData = feature.get('weatherData');
            if (weatherData) {
              setWeatherClosed({ ...weatherClosed, [weatherData.id]: false });
            }
          });
        } else if (isDefender) {
          e.preventDefault();
          map.forEachFeatureAtPixel(e.pixel, (feature) => {
            const defenderData = feature.get('defenderData');
            if (defenderData) {
              setDefendersClosed({ ...defendersClosed, [defenderData.id]: false });
            }
          });
        } else if (isDrainValve) {
          e.preventDefault();
          map.forEachFeatureAtPixel(e.pixel, (feature) => {
            const drainValveData = feature.get('drainValveData');
            if (drainValveData) {
              setValvesClosed({ ...valvesClosed, [drainValveData.id]: false });
            }
          });
        } else if (isTrail) {
          map.forEachFeatureAtPixel(e.pixel, (feature) => {
            // If the feature clicked is a trail
            const trailData = feature.get('trailData');
            if (trailData) {
              props.selectHydrant(null);
              props.selectTrail(trailData?.name.replace('#', ''));
              props.selectAutoTrail(null);
              setGlobalNotesViewVisible(false);
              setMobileMenuVisible(false);
              setMobileViewVisible(false);
              props.selectMobile(null);
            }
          });
        } else {
          setTrailMenuVisible(false);
          setMobileMenuVisible(false);
          setMobileViewVisible(false);
          props.selectMobile(null);
          setGlobalNotesViewVisible(false);
          setHydrantSettingsVisible(false);
          setAutoTrailSettingsVisible(false);
          props.selectHydrant(null);
          props.selectTrail(null);
          setCloseHydrantMenu(true);
          props.selectAutoTrail(null);
        }
      });
    }
  }, [gen, weatherClosed, defendersClosed, valvesClosed, editMode, editTrailID]);

  useEffect(() => {
    // Axios cancel token used to abort async requests prematurely in the event
    // that the component is unmounted. This prevents memory leaks.
    cancelTokenSourceRef.current = CancelToken.source();

    // first time we fetch trails fetch with trail geometry
    const curCancelToken = cancelTokenSourceRef.current.token;
    props.fetchAllGuns(curCancelToken);
    props.fetchFeatures(curCancelToken);
    props.fetchAllMobileGuns(curCancelToken);
    props.fetchAllMobileEquipment(curCancelToken);
    props.fetchAllGateways(curCancelToken);
    props.fetchAllFlowManagementSettings(curCancelToken);
    props.fetchAllHydrantModels(curCancelToken);
    props.fetchTemperatureZones(curCancelToken);
    props.fetchPressureZones(curCancelToken);
    props.fetchFlowZones(curCancelToken);
    props.fetchPLCs(curCancelToken);
    props.fetchAllTrackerEquipment(curCancelToken);
  }, []);

  // useEffect(() => {
  //   console.log(trailListVisible);
  //   console.log(autoTrailSettingsVisible);
  // }, [trailListVisible, autoTrailSettingsVisible]);

  useEffect(() => {
    if (props.trackerEquipment?.trackerEquipment) {
      const tempIpAddressLookup = {};
      Object.values(props.trackerEquipment?.trackerEquipment).forEach((equipment) => {
        // Use the plcId to create an object whose key/value pairs are the ipAddresses / equipment names.
        tempIpAddressLookup[props.plcs.ipAddresses?.[equipment?.plcId]?.ipAddress] = equipment?.name;
      });
      setIpAddressLookup(tempIpAddressLookup);
    }
  }, [props.trackerEquipment?.trackerEquipment]);

  let oneSecondIntervalId,
    oneMinuteIntervalId,
    selectedHydrantIntervalId,
    selectedMobileIntervalId,
    weatherTimeoutId,
    defendersTimeoutId,
    valvesTimeoutId,
    sensorsTimeoutId,
    snowDepthTimeoutId,
    loaderTimeoutId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const curCancelToken = cancelTokenSourceRef.current.token;
      const promises = [
        props.fetchAllDefenders(curCancelToken),
        props.fetchAllValves(curCancelToken),
        props.fetchAllAutoTrails(curCancelToken),
        props.fetchGlobalNotes(curCancelToken),
        props.fetchAllTrails(curCancelToken),
        props.fetchAllGateways(curCancelToken),
        props.fetchAllMobileGuns(curCancelToken),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) {
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  const oneMinuteInterval = () => {
    oneMinuteIntervalId = setTimeout(async () => {
      const curCancelToken = cancelTokenSourceRef.current.token;
      const promises = [
        props.fetchAllWeatherStations(curCancelToken),
        props.fetchAllAutoTrailsIOValues(curCancelToken),
        props.fetchAllTrackerIOValues(curCancelToken),
        props.fetchAllIOValues(curCancelToken),
        props.fetchAllZoneIOValues(curCancelToken),
      ];
      await Promise.all(promises);
      if (oneMinuteIntervalId) {
        oneMinuteIntervalId = setTimeout(oneMinuteInterval, 60000);
      }
    }, 60000);
  };

  // const selectedHydrantInterval = () => {
  //   selectedHydrantIntervalId = setTimeout(async () => {
  //     const curCancelToken = cancelTokenSourceRef.current.token;
  //     const hydrantRef = selectedHydrantIDRef.current;
  //     const trend = hydrantTrendRef.current;
  //     const trendFilter = hydrantTrendFilterRef.current;
  //     if (hydrantRef) {
  //       await props.fetchHydrant(hydrantRef, curCancelToken);
  //       if (trend) {
  //         await props.fetchHydrantTrendDataset(trendFilter, hydrantRef, 'Gpm', curCancelToken);
  //         await props.fetchHydrantTrendDataset(trendFilter, hydrantRef, 'Wetbulb', curCancelToken);
  //       }
  //     }
  //     if (selectedHydrantIntervalId) {
  //       selectedHydrantIntervalId = setTimeout(selectedHydrantInterval, 1000);
  //     }
  //   }, 1000);
  // };

  const selectedMobileInterval = () => {
    selectedMobileIntervalId = setTimeout(async () => {
      const curCancelToken = cancelTokenSourceRef.current.token;
      const mobileRef = selectedMobileIDRef.current;
      if (mobileRef) {
        await props.fetchMobileGun(mobileRef, curCancelToken);
      }
      if (selectedMobileIntervalId) {
        selectedMobileIntervalId = setTimeout(selectedMobileInterval, 1000);
      }
    }, 1000);
  };

  const fetchAllInitialData = async () => {
    const curCancelToken = cancelTokenSourceRef.current.token;
    const requests = [
      props.fetchAllWeatherStations(curCancelToken),
      props.fetchAllDefenders(curCancelToken),
      // props.fetchSnowDepths(curCancelToken),
      props.fetchAllMobileEquipment(curCancelToken),
      props.fetchAllMobileGuns(curCancelToken),
      props.fetchAllValves(curCancelToken),
      props.fetchAllZoneIOValues(curCancelToken),
      props.fetchAllFlowManagementSettings(curCancelToken),
      props.fetchAllAutoTrails(curCancelToken),
      props.fetchGlobalNotes(curCancelToken),
      props.fetchAllTrackerIOValues(curCancelToken),
      props.fetchAllIOValues(curCancelToken),
      props.fetchAllAutoTrailsIOValues(curCancelToken),
      props.fetchAllTrailsWithGeometry(curCancelToken),
      props.fetchAllPresets(curCancelToken),
    ];
    const responses = await Promise.all(requests);
    if (responses.filter((r) => (r === 'canceled')).length === 0) {
      // Only set our state if none of our requests were canceled due to the component being unmounted
      setInitRequestsComplete(true);
    }
  };

  useEffect(() => {
    setTrendFlow({});
    setTrendWetbulb({});
  }, [hydrantTrendFilter]);

  // useEffect for the selectedHydrantInterval
  // any time selectedHydrantId changes (or hydrant trend tab is selected or hydrantTrendFilter changes)
  // reinitialize selectedHydrantInterval function
  useEffect(() => {
    if (selectedHydrantIntervalId) {
      clearTimeout(selectedHydrantIntervalId);
      selectedHydrantIntervalId = null;
    }
    const selectedHydrantInterval = (hydrantID, trend, trendFilter) => {
      selectedHydrantIntervalId = setTimeout(async () => {
        const curCancelToken = cancelTokenSourceRef.current.token;
        if (hydrantID) {
          if (trend) {
            await props.fetchHydrantTrendDataset(trendFilter, hydrantID, 'Gpm', curCancelToken);
            await props.fetchHydrantTrendDataset(trendFilter, hydrantID, 'Wetbulb', curCancelToken);
          }
          await props.fetchHydrant(hydrantID, curCancelToken);
        }
        if (selectedHydrantIntervalId) {
          selectedHydrantIntervalId = setTimeout(selectedHydrantInterval(hydrantID, trend, trendFilter), 1000);
          // selectedHydrantIntervalId = selectedHydrantInterval(hydrantID, trend, trendFilter);
        }
      }, 1000);
      // return selectedHydrantIntervalId;
    };
    if (props.hydrants?.selectedHydrantId) {
      // props.fetchHydrant(props.hydrants?.selectedHydrantId, cancelTokenSourceRef.current.token);
      selectedHydrantInterval(props.hydrants?.selectedHydrantId, hydrantTrend, hydrantTrendFilter);
    }

    return () => {
      clearTimeout(selectedHydrantIntervalId);
      selectedHydrantIntervalId = null;
    };
  }, [props.hydrants?.selectedHydrantId, hydrantTrendFilter, hydrantTrend]);

  useEffect(() => {
    selectedMobileIDRef.current = props.mobileGuns?.selectedMobileID;
  }, [props.mobileGuns?.selectedMobileID]);

  useEffect(() => {
    // Function to asynchronously request all of our data but then wait for
    // all of the responses before setting 'initRequestsComplete' to true. We use
    // this to make sure we have all of the neccesary data before generating our map.
    fetchAllInitialData();

    // Start all of our intervals
    oneSecondInterval();
    oneMinuteInterval();
    // selectedHydrantInterval();
    selectedMobileInterval();

    // Cleanup function to cancel async requests that haven't returned yet and would otherwise
    // cause memory leaks. Also clears timeout to prevent memory leaks.
    //
    return () => {
      cancelTokenSourceRef.current.cancel('canceled');
      cancelTokenSourceRef.current = CancelToken.source();
      clearTimeout(oneSecondIntervalId);
      clearTimeout(oneMinuteIntervalId);
      // clearTimeout(selectedHydrantIntervalId);
      clearTimeout(selectedMobileIntervalId);
      oneSecondIntervalId = null;
      oneMinuteIntervalId = null;
      // selectedHydrantIntervalId = null;
      selectedMobileIntervalId = null;
      props.selectMobile(null);
      props.selectHydrant(null);
      props.selectTrail(null);
    };
  }, []);

  function mapUpdate(e) {
    const pixel = map.getEventPixel(e.originalEvent);
    const isHydrant = map.hasFeatureAtPixel(pixel, { layerFilter: (layer) => layer.get('name') === 'hydrants' });
    const isTrail = map.hasFeatureAtPixel(pixel, { layerFilter: (layer) => layer.get('name') === 'trails' });
    const isWeatherStation = map.hasFeatureAtPixel(pixel, { layerFilter: (layer) => layer.get('name') === 'weatherStations' });
    const isDefender = map.hasFeatureAtPixel(pixel, { layerFilter: (layer) => layer.get('name') === 'defendersLayer' });
    const isDrainValve = map.hasFeatureAtPixel(pixel, { layerFilter: (layer) => layer.get('name') === 'valvesLayer' });
    const isSnowDepth = map.hasFeatureAtPixel(pixel, { layerFilter: (layer) => layer.get('name') === 'snowDepthLayer' });
    const isSensor = map.hasFeatureAtPixel(pixel, { layerFilter: (layer) => layer.get('name') === 'sensorsLayer' });

    hoverOverlay.setPosition((isSensor || isHydrant || isTrail || isWeatherStation || isDefender || isDrainValve || isSnowDepth) ? e.coordinate : undefined);

    // If we are hovering over our hydrant, set it as hoveredHydrant
    // Need this to populate our hover square
    if (isHydrant) {
      // If we are hovering a hydrant we no longer want to display our trail hoverOverlay
      map.forEachFeatureAtPixel(pixel, (feature) => {
        const hydrantData = feature.get('hydrantData');
        if (hydrantData !== undefined) {
          setHoverOverlayData({ hydrantData });
        }
      });
    } else if (isWeatherStation) {
      map.forEachFeatureAtPixel(pixel, (feature) => {
        const weatherData = feature.get('weatherData');
        if (weatherData !== undefined) {
          setHoverOverlayData({ weatherData });
        }
      });
    } else if (isDefender) {
      map.forEachFeatureAtPixel(pixel, (feature) => {
        const defenderData = feature.get('defenderData');
        if (defenderData !== undefined) {
          setHoverOverlayData({ defenderData });
        }
      });
    } else if (isSensor) {
      map.forEachFeatureAtPixel(pixel, (feature) => {
        const sensorData = feature.get('sensorData');
        if (sensorData !== undefined) {
          if (sensorData.flowManagerEquipmentId) {
            setHoverOverlayData({ sensorData });
          }
        }
      });
    } else if (isDrainValve) {
      map.forEachFeatureAtPixel(pixel, (feature) => {
        const drainValveData = feature.get('drainValveData');
        if (drainValveData !== undefined) {
          setHoverOverlayData({
            drainValveData: {
              ...drainValveData,
              plc: props.plcs.plcs[drainValveData?.plcId],
            },
          });
        }
      });
    } else if (isSnowDepth) {
      map.forEachFeatureAtPixel(pixel, (feature) => {
        const snowDepthData = feature.get('snowDepthData');
        if (snowDepthData !== undefined) {
          setHoverOverlayData({ snowDepthData });
        }
      });
    } else if (isTrail) {
      map.forEachFeatureAtPixel(pixel, (feature) => {
        const trailData = feature.get('trailData');
        if (trailData !== undefined) {
          setHoverOverlayData({ trailData });
        }
      });
    }
  }

  useEffect(() => {
    if (changeSelectKey) {
      unByKey(changeSelectKey);
    }
    changeSelectKey = selectedFeatures.on('change:length', () => {
      if (editMode === 1) {
        setSelectedLength(selectedFeatures.getLength());
      }
    });
  }, [editMode]);

  useEffect(() => {
    if (modify) {
      modify.on('modifyend', (e) => {
        setModifyEnd(true);
      });
    }
  }, [gen]);

  useEffect(() => {
    if (map && gen > 0) {
      map.removeInteraction(translate);
      map.removeInteraction(snap);
      map.removeInteraction(snapTrail);
      map.removeInteraction(modify);
      map.removeInteraction(draw);
      // map.removeInteraction(draw2);
      select.setActive(true);
      if (editMode === 1 && editTrailID !== null) {
        map.addInteraction(modify);
        select.setActive(false);
        map.addInteraction(draw);
        map.addInteraction(snap);
        map.addInteraction(snapTrail);
      } else if (editMode === 2) {
        map.addInteraction(translate);
      } else if (editMode === 3) {
        map.addInteraction(translate);
        // select.setActive(false);
      // } else if (editMode === 4) {
        // map.addInteraction(draw2);
      }
    }
  }, [editMode, editTrailID]);

  useEffect(() => {
    if (featureRemove) {
      trailDeleteSource.clear();
      setFeatureAdd(null);
      setFeatureRemove(false);
      return () => {};
    }
    if (featureAdd) {
      trailDeleteFeatures = [];
      featureAdd.setStyle(trailStyleDelete);
      trailDeleteFeatures.push(featureAdd);
      if (trailDeleteFeatures) {
        trailDeleteSource.clear();
        trailDeleteSource.addFeatures(trailDeleteFeatures);
      }
    }
    return () => {};
  }, [featureAdd, featureRemove]);

  useEffect(() => {
    if (!trailsArray) { return () => {}; }
    const tfm = {};

    trailFeatures = trailsArray.map((t) => {
      // eslint-disable-next-line no-eval
      const arr = eval(t.geometry);

      const polyArray = [];
      for (let i = 0; i < arr?.length; i++) {
        const cPairs = [];
        const cArray = arr[i];
        for (let n = 0; n < cArray.length; n++) {
          const c = cArray[n];
          if (n === cArray.length - 1) {
            cPairs.push(cPairs[0]);
          } else {
            cPairs.push(Proj.transform([c[0], c[1]], 'EPSG:4326', 'EPSG:3857'));
          }
        }
        polyArray.push(new Polygon([cPairs]));
      }

      const f = new Feature({
        geometry: new MultiPolygon(polyArray),
      });

      f.setId(t.id);
      f.set('trailData', t);

      tfm[t.id] = f;
      return f;
    });
    if (trailFeatures && editTrailID === null) {
      trailSource.clear();
      trailSource.addFeatures(trailFeatures);
    }

    return () => {};
  }, [trailsArray, editMode, editTrailID]);

  useEffect(() => {
    if (map && gen > 0) {
      // Initialize our drain valves and add them to the map. This must be done
      // outside of the 'gen' useEffect because if there are no hydrants they wouldn't be rendered
      valveOverlays = {};
      valvePoints = [];
      if (props.equipment?.valves) {
        Object.values(props.equipment?.valves).forEach((valve) => {
          if (valve?.longitude && valve?.latitude) {
            const coord = [valve?.longitude, valve?.latitude];
            const p = Proj.fromLonLat(coord);
            const valvePoint = new Feature(new Point(p));
            valvePoint.set('id', valve.id);
            valvePoint.setId(`drainPoint_${valve.id}`);
            valvePoint.set('drainValveData', valve);
            valvePoint.setStyle(styleFunctionValve);
            valvePoints.push(valvePoint);
            valveOverlayDOM = document.getElementById(`valve${valve.id}`);
            if (valveOverlayDOM) {
              valveOverlayDOM.addEventListener('pointermove', (e) => (e.stopPropagation()));
              valveOverlays[valve.id] = new Overlay({
                element: valveOverlayDOM,
                autoPan: false,
                stopEvent: true,
              });
              valveOverlays[valve.id].setPosition(p);
            }
          }
        });
      }
      valveSource.addFeatures(valvePoints);

      valvesLayer = new VectorLayer({
        name: 'valvesLayer',
        source: valveSource,
        updateWhileInteracting: true,
        updateWhileAnimating: true,
      });

      map.addLayer(valvesLayer);
      const overlayArray = Object.values(valveOverlays);
      for (let i = 0; i < overlayArray.length; i++) {
        map.addOverlay(overlayArray[i]);
      }
      valvesTimeoutId = setTimeout(() => {
        setValvesLoading(false);
      }, 2000);
    }
    return () => {
      clearTimeout(valvesTimeoutId);
    };
  }, [gen]);

  useEffect(() => {
    if (map && gen > 0) {
      const valveFeatures = valveSource.getFeatures();
      if (props.equipment?.valves) {
        valveFeatures.forEach((valveFeature) => {
          const updatedData = props.equipment?.valves?.[valveFeature.get('id')];
          if (updatedData) {
            valveFeature.set('drainValveData', updatedData);
          }
        });
      }
    }
  }, [props.equipment?.valves]);

  // Update our sensor points as more are added/delete
  useEffect(() => {
    if (props.zones?.zoneIOValues && sensorPoints && sensorSource) {
      sensorPoints = [];
      Object.values(props.zones?.zoneIOValues).forEach((sensor) => {
        const coord = [sensor?.longitude, sensor?.latitude];
        const p = Proj.fromLonLat(coord);
        const sensorPoint = new Feature(new Point(p));
        sensorPoint.set('id', `sensorPoint_${sensor.id}`);
        sensorPoint.setId(`sensorPoint_${sensor.id}`);
        sensorPoint.set('sensorData', sensor);
        sensorPoint.setStyle(styleFunctionSensor);
        sensorPoints.push(sensorPoint);
      });

      if (props.flowManagementSettings?.flowManagementSettings && props.auth?.features?.FlowManagement) {
        Object.values(props.flowManagementSettings?.flowManagementSettings).forEach((setting) => {
          if (setting.longitude && setting.latitude) {
            const coord = [setting?.longitude, setting?.latitude];
            const p = Proj.fromLonLat(coord);
            const flowSensorPoint = new Feature(new Point(p));
            flowSensorPoint.set('id', `flowSensorPoint${setting.id}`);
            flowSensorPoint.setId(`flowSensorPoint_${setting.id}`);
            flowSensorPoint.set('sensorData', setting);
            if (Object.values(setting?.flowManagerEquipment?.ioValues || {}).some((ioValue) => ioValue?.description?.includes('alarm') && ioValue?.value === 1)) {
              flowSensorPoint.setStyle(styleFunctionFlowZoneAlarmSensor);
            } else {
              flowSensorPoint.setStyle(styleFunctionFlowZoneSensor);
            }
            sensorPoints.push(flowSensorPoint);
          }
        });
      }
    }
    if (sensorSource && editSensorData === null && sensorPoints?.length > 0) {
      sensorSource.clear();
      sensorSource.addFeatures(sensorPoints);
    }
  }, [props.zones?.zoneIOValues, props.flowManagementSettings?.flowManagementSettings]);

  // useEffect(() => {
  //   console.log(props.hydrants?.hydrants);
  //   if (map && gen > 0 && props.hydrants?.hydrants) {
  //     // console.log(props.hydrants?.hydrants);
  //     const tempKlikWeatherStations = {};
  //     Object.values(props.weatherStations.weatherStations).forEach((station) => {
  //       if (station.weatherStationTrackerEquipmentId) {
  //         let coordinates = [station.weatherStationTrackerEquipment.longitude, station.weatherStationTrackerEquipment.latitude];
  //         coordinates = (coordinates[0] && coordinates[1]) ? coordinates : 1;
  //         tempKlikWeatherStations[station.weatherStationTrackerEquipment.name] = coordinates;
  //       }
  //     });
  //     console.log(tempKlikWeatherStations);
  //     if (!tempKlikWeatherStations.isEqual(klikWeatherStations)) {
  //       console.log(tempKlikWeatherStations);
  //       setKlikWeatherStations(tempKlikWeatherStations);
  //     }
  //   }
  // }, [gen, props.hydrants?.hydrants]);

  useEffect(() => {
    if (map && gen > 0) {
      // Initialize our weather stations and add them to the map. This must be done
      // outside of the 'gen' useEffect because if there are no hydrants they wouldn't be rendered
      weatherOverlays = {};
      weatherPoints = [];
      const tempKlikWeatherStations = {};

      Object.values(props.weatherStations.weatherStations).forEach((station) => {
        // if (station.weatherStationTrackerEquipment) {
        //   let coordinates = [station.weatherStationTrackerEquipment.longitude, station.weatherStationTrackerEquipment.latitude];
        //   coordinates = (coordinates[0] && coordinates[1]) ? coordinates : 1;
        //   tempKlikWeatherStations[station.weatherStationTrackerEquipment.name] = coordinates;
        // }
        if (
          station.weatherlinkStation?.longitude
          || station.openWeatherMapLocation?.longitude
          || station.weatherStationTrackerEquipment?.longitude
          || station.weatherStationEquipment?.location?.longitude
        ) {
          let coord;
          if (station.weatherlinkStationId) {
            coord = [station.weatherlinkStation?.longitude, station.weatherlinkStation?.latitude];
          } else if (station.openWeatherMapLocationId) {
            coord = [station.openWeatherMapLocation?.longitude, station.openWeatherMapLocation?.latitude];
          } else if (station.weatherStationTrackerEquipmentId) {
            // let coordinates = [station.weatherStationTrackerEquipment.longitude, station.weatherStationTrackerEquipment.latitude];
            // coordinates = (coordinates[0] && coordinates[1]) ? coordinates : 1;
            // tempKlikWeatherStations[station.weatherStationTrackerEquipment.name] = coordinates;
            tempKlikWeatherStations[station.weatherStationTrackerEquipment.name] = 1;
            coord = [station.weatherStationTrackerEquipment.longitude, station.weatherStationTrackerEquipment.latitude];
          } else {
            coord = [station.weatherStationEquipment?.location?.longitude, station.weatherStationEquipment?.location?.latitude];
          }
          const p = Proj.fromLonLat(coord);
          const weather = new Feature(new Point(p));
          weather.set('id', station.id);
          weather.setId(`weatherPoint_${station.id}`);
          weather.set('weatherData', station);
          if (!station.weatherStationTrackerEquipmentId) {
            weather.setStyle(styleFunctionThermometer);
          } else {
            // For our Klik weather stations we want their icon to be invisible
            // but we still need to add them add them to properly place their overlays for when the weather stations are toggled
            weather.setStyle(new Style({}));
          }
          weatherPoints.push(weather);
          weatherOverlayDOM = document.getElementById(`weatherOverlay${station.id}`);
          if (weatherOverlayDOM) {
            weatherOverlays[station.id] = new Overlay({
              element: weatherOverlayDOM,
              autoPan: false,
              stopEvent: true,
            });
            weatherOverlays[station.id].setPosition(p);
          }
        }
      });
      setKlikWeatherStations(tempKlikWeatherStations);
      weatherSource.addFeatures(weatherPoints);
      weatherStations = new VectorLayer({
        name: 'weatherStations',
        source: weatherSource,
        updateWhileInteracting: true,
        updateWhileAnimating: true,
      });

      map.addLayer(weatherStations);
      const overlayArray = Object.values(weatherOverlays);
      for (let i = 0; i < overlayArray.length; i++) {
        map.addOverlay(overlayArray[i]);
      }
      weatherTimeoutId = setTimeout(() => {
        setWeatherLoading(false);
      }, 2000);
    }
    return () => {
      clearTimeout(weatherTimeoutId);
    };
  }, [gen]);

  useEffect(() => {
    if (map && gen > 0) {
      // Initialize our defenders and add them to the map. This must be done
      // outside of the 'gen' useEffect because if there are no hydrants they wouldn't be rendered
      defenderOverlays = {};
      defenderPoints = [];
      if (props.trackerEquipment?.defenders) {
        Object.values(props.trackerEquipment?.defenders).forEach((defender) => {
          const coord = [defender?.longitude, defender?.latitude];
          const p = Proj.fromLonLat(coord);
          const defenderPoint = new Feature(new Point(p));
          defenderPoint.set('id', defender.id);
          defenderPoint.setId(`defenderPoint_${defender.id}`);
          defenderPoint.set('defenderData', defender);
          defenderPoint.setStyle(styleFunctionDefender);
          defenderPoints.push(defenderPoint);
          defenderOverlayDOM = document.getElementById(`defender${defender.id}`);
          if (defenderOverlayDOM) {
            defenderOverlays[defender.id] = new Overlay({
              element: defenderOverlayDOM,
              autoPan: false,
              stopEvent: true,
            });
            defenderOverlays[defender.id].setPosition(p);
          }
        });
      }
      defenderSource.addFeatures(defenderPoints);

      defendersLayer = new VectorLayer({
        name: 'defendersLayer',
        source: defenderSource,
        updateWhileInteracting: true,
        updateWhileAnimating: true,
      });

      map.addLayer(defendersLayer);
      const overlayArray = Object.values(defenderOverlays);
      for (let i = 0; i < overlayArray.length; i++) {
        map.addOverlay(overlayArray[i]);
      }
      defendersTimeoutId = setTimeout(() => {
        setDefendersLoading(false);
      }, 2000);
    }
    return () => {
      clearTimeout(defendersTimeoutId);
    };
  }, [gen]);

  useEffect(() => {
    if (map && gen > 0) {
      // Initialize our sensors and add them to the map. This must be done
      // outside of the 'gen' useEffect because if there are no hydrants they wouldn't be rendered
      sensorPoints = [];
      if (props.zones?.zoneIOValues) {
        Object.values(props.zones?.zoneIOValues).forEach((sensor) => {
          const coord = [sensor?.longitude, sensor?.latitude];
          const p = Proj.fromLonLat(coord);
          const sensorPoint = new Feature(new Point(p));
          sensorPoint.set('id', `sensorPoint_${sensor.id}`);
          sensorPoint.setId(`sensorPoint_${sensor.id}`);
          sensorPoint.set('sensorData', sensor);
          sensorPoint.setStyle(styleFunctionSensor);
          sensorPoints.push(sensorPoint);
        });
      }
      if (props.flowManagementSettings?.flowManagementSettings && props.auth?.features?.FlowManagement) {
        Object.values(props.flowManagementSettings?.flowManagementSettings).forEach((setting) => {
          if (setting.longitude && setting.latitude) {
            const coord = [setting?.longitude, setting?.latitude];
            const p = Proj.fromLonLat(coord);
            const flowSensorPoint = new Feature(new Point(p));
            flowSensorPoint.set('id', `flowSensorPoint${setting.id}`);
            flowSensorPoint.setId(`flowSensorPoint_${setting.id}`);
            flowSensorPoint.set('sensorData', setting);
            if (Object.values(setting?.flowManagerEquipment?.ioValues || {}).some((ioValue) => ioValue?.description?.includes('alarm') && ioValue?.value === 1)) {
              flowSensorPoint.setStyle(styleFunctionFlowZoneAlarmSensor);
            } else {
              flowSensorPoint.setStyle(styleFunctionFlowZoneSensor);
            }
            sensorPoints.push(flowSensorPoint);
          }
        });
      }
      sensorSource.addFeatures(sensorPoints);

      sensorsLayer = new VectorLayer({
        name: 'sensorsLayer',
        source: sensorSource,
        updateWhileInteracting: true,
        updateWhileAnimating: true,
        visible: false,
      });

      map.addLayer(sensorsLayer);
      sensorsTimeoutId = setTimeout(() => {
        setSensorsLoading(false);
      }, 2000);
    }
    return () => {
      clearTimeout(sensorsTimeoutId);
    };
  }, [gen]);

  useEffect(() => {
    if (map && gen > 0 && editSensorData === null) {
      sensorPoints = [];
      if (props.zones?.zoneIOValues) {
        Object.values(props.zones?.zoneIOValues).forEach((sensor) => {
          const coord = [sensor?.longitude, sensor?.latitude];
          const p = Proj.fromLonLat(coord);
          const sensorPoint = new Feature(new Point(p));
          sensorPoint.set('id', `sensorPoint_${sensor.id}`);
          sensorPoint.setId(`sensorPoint_${sensor.id}`);
          sensorPoint.set('sensorData', sensor);
          sensorPoint.setStyle(styleFunctionSensor);
          sensorPoints.push(sensorPoint);
        });
      }
      if (props.flowManagementSettings?.flowManagementSettings && props.auth?.features?.FlowManagement) {
        Object.values(props.flowManagementSettings?.flowManagementSettings).forEach((setting) => {
          if (setting.longitude && setting.latitude) {
            const coord = [setting?.longitude, setting?.latitude];
            const p = Proj.fromLonLat(coord);
            const flowSensorPoint = new Feature(new Point(p));
            flowSensorPoint.set('id', `flowSensorPoint${setting.id}`);
            flowSensorPoint.setId(`flowSensorPoint_${setting.id}`);
            flowSensorPoint.set('sensorData', setting);
            if (Object.values(setting?.flowManagerEquipment?.ioValues || {}).some((ioValue) => ioValue?.description?.includes('alarm') && ioValue?.value === 1)) {
              flowSensorPoint.setStyle(styleFunctionFlowZoneAlarmSensor);
            } else {
              flowSensorPoint.setStyle(styleFunctionFlowZoneSensor);
            }
            sensorPoints.push(flowSensorPoint);
          }
        });
      }
      if (sensorPoints) {
        sensorSource.clear();
        if (editMode !== 1) {
          sensorSource.addFeatures(sensorPoints);
        }
      }
    }
  }, [editSensorData, props.zones?.zoneIOValues, props.flowManagementSettings?.flowManagementSettings]);

  useEffect(() => {
    if (map && gen > 0) {
      // Initialize our snowDepths and add them to the map. This must be done
      // outside of the 'gen' useEffect because if there are no hydrants they wouldn't be rendered
      snowDepthPoints = [];
      if (props.snowDepths?.snowDepths) {
        Object.values(props.snowDepths?.snowDepths).forEach((depth) => {
          // if (snowDepthPoints.length < 5) {
          const coord = [depth?.longitude, depth?.latitude];
          const p = Proj.fromLonLat(coord);
          const depthPoint = new Feature(new Point(p));
          depthPoint.set('id', `depthPoint_${depth?.longitude},${depth?.latitude}`);
          depthPoint.set('snowDepthData', depth);
          depthPoint.set('depth', depth.depth);
          snowDepthPoints.push(depthPoint);
          // }
        });
      }
      snowDepthSource.clear();
      snowDepthSource.addFeatures(snowDepthPoints);

      if (!snowDepthLayer) {
        snowDepthLayer = new WebGLPointsLayer({
          name: 'snowDepthLayer',
          source: snowDepthSource,
          style: snowDepthStyle,
          visible: false,
        });

        map.addLayer(snowDepthLayer);
        snowDepthTimeoutId = setTimeout(() => {
          setSnowDepthLoading(false);
        }, 2000);
      } else {
        refreshSnowDepthLayer();
      }
    }
    return () => {
      clearTimeout(snowDepthTimeoutId);
    };
  }, [gen, lowerBound, upperBound, props.snowDepths?.snowDepths]);

  const refreshSnowDepthLayer = () => {
    if (snowDepthLayer) {
      map.removeLayer(snowDepthLayer);
      snowDepthLayer.dispose();
    }
    snowDepthLayer = new WebGLPointsLayer({
      name: 'snowDepthLayer',
      source: snowDepthSource,
      style: snowDepthStyle,
    });
    map.addLayer(snowDepthLayer);
    snowDepthLayer.setVisible(snowDepthMode === true);
  };

  useEffect(() => {
    // We don't want to generate our map until we have data for it
    if (!initRequestsComplete) { return () => {}; }
    // Perform initial setup here if map doesn't exist yet, needs to be inside useEffect
    // because otherwise we don't know when props.hydrants.hydrants will be defined
    if (gen < 1) {
      weatherSource = new VectorSource();
      snowDepthSource = new VectorSource();
      defenderSource = new VectorSource();
      valveSource = new VectorSource();
      sensorSource = new VectorSource();

      if (defaultCenterPoint) {
        defaultCenterSource.addFeatures([new Feature(new Point(Proj.fromLonLat(defaultCenterPoint)))]);
      }

      // Create our vector layer using our vector source 'hydrantSource'
      // Apply our default style 'off', will be updated as changes occur
      defaultCenterLayer = new VectorLayer({
        name: 'defaultCenterLayer',
        source: defaultCenterSource,
        visible: false,
      });

      hydrants = new VectorLayer({
        name: 'hydrants',
        source: hydrantSource,
        updateWhileInteracting: true,
        updateWhileAnimating: true,
      });

      // Create a hoveroverlay to display data when over hydrant
      hoverOverlayDOM = document.getElementById('hoverOverlay');
      hoverOverlay = new Overlay({
        element: hoverOverlayDOM,
        autoPan: false,
      });

      // Create a editOverlay to display editable fields when a hydrant is clicked
      // while in map edit mode.
      editOverlayDOM = document.getElementById('editOverlay');
      editOverlayDOMHandle = document.getElementById('editOverlayHandle');
      editOverlay = new Overlay({
        element: editOverlayDOM,
        autoPan: false,
        offset: [175, -15],
        positioning: 'top-center',
      });

      sensorEditOverlayDOM = document.getElementById('sensorEditOverlay');
      sensorEditOverlayDOMHandle = document.getElementById('sensorEditOverlayHandle');
      sensorEditOverlay = new Overlay({
        element: sensorEditOverlayDOM,
        autoPan: false,
        offset: [205, 2],
        positioning: 'top-center',
      });

      trails = new VectorLayer({
        name: 'trails',
        source: trailSource,
        updateWhileInteracting: true,
        updateWhileAnimating: true,
        style: styleFunctionTrails,
      });

      // pipes = new VectorLayer({
      //   name: 'pipes',
      //   source: pipeSource,
      //   updateWhileInteracting: true,
      //   updateWhileAnimating: true,
      //   style: defaultStylePipes,
      // });

      trailsAdd = new VectorLayer({
        name: 'trailsAdd',
        source: trailAddSource,
        updateWhileInteracting: true,
        updateWhileAnimating: true,
        // style: styleFunctionTrails,
        style: defaultEditStyle,
      });

      trailsDelete = new VectorLayer({
        name: 'trailsDelete',
        source: trailDeleteSource,
        updateWhileInteracting: true,
        updateWhileAnimating: true,
        style: styleFunctionTrails,
      });

      // const url = `https://mt1.google.com/vt/lyrs=${googleLayerType}&hl=pl&&x={x}&y={y}&z={z}`;
      // const googleMapsTileSource = new XYZ({
      //   url,
      // });
      // googleMapsTileSource.setTileLoadFunction(customLoader);

      // const tiles = new TileLayer({
      //   source: googleMapsTileSource,
      //   preload: 10,
      // });
      const googleMapsTileSource = new BingMaps({
        key: 'ArKLI8AzT_0s39l0ZvHpJZOuVWnWo1KaIKD3rO5jElzx9DBgHQqquhamhv-2wacT',
        imagerySet: 'Aerial',
        // use maxZoom 19 to see stretched tiles instead of the BingMaps
        // "no photos at this zoom level" tiles
        maxZoom: 19,
        tileLoadFunction: customLoader,
      });

      const tiles = new TileLayer({
        source: googleMapsTileSource,
        preload: 10,
      });

      // new TileLayer({
      //   visible: false,
      //   preload: Infinity,
      //   source: new BingMaps({
      //     key: 'ArKLI8AzT_0s39l0ZvHpJZOuVWnWo1KaIKD3rO5jElzx9DBgHQqquhamhv-2wacT',
      //     imagerySet: Aerial,
      //     // use maxZoom 19 to see stretched tiles instead of the BingMaps
      //     // "no photos at this zoom level" tiles
      //     // maxZoom: 19
      //   }),
      // })

      // transform our extent to prevent panning too far
      const tempExtent = hydrantSource.getExtent();
      if (!isEmpty(tempExtent) && extentFactors) {
        tempExtent[0] -= (window.innerWidth * extentFactors[0]);
        tempExtent[1] -= (window.innerHeight * extentFactors[1]);
        tempExtent[2] += (window.innerWidth * extentFactors[2]);
        tempExtent[3] += (window.innerHeight * extentFactors[3]);
      }

      select = new Select({
        features: selectedFeatures,
        style: styleFunctionSelected,
        condition: (mapBrowserEvent) => {
          // check if it is a click event
          if (!singleClick(mapBrowserEvent)) return false;

          // collect all the features at the click event
          const isWeatherStation = map.hasFeatureAtPixel(mapBrowserEvent.pixel, { layerFilter: (layer) => layer.get('name') === 'weatherStations' });
          const isDefender = map.hasFeatureAtPixel(mapBrowserEvent.pixel, { layerFilter: (layer) => layer.get('name') === 'defendersLayer' });
          const isDrainValve = map.hasFeatureAtPixel(mapBrowserEvent.pixel, { layerFilter: (layer) => layer.get('name') === 'valvesLayer' });
          const isSensor = map.hasFeatureAtPixel(mapBrowserEvent.pixel, { layerFilter: (layer) => layer.get('name') === 'sensorsLayer' });
          const isSnowDepth = map.hasFeatureAtPixel(mapBrowserEvent.pixel, { layerFilter: (layer) => layer.get('name') === 'snowDepthLayer' });
          // if (isDrainValve || isWeatherStation || isDefender || isSensor || isSnowDepth) {
          if (isDrainValve || isWeatherStation || isDefender || isSnowDepth) {
            return false;
          } else {
            return true;
          }
        },
      });

      modify = new Modify({
        features: selectedFeatures,
      });
      draw = new Draw({
        source: trailAddSource,
        features: selectedFeatures,
        type: 'Polygon',
      });
      // draw2 = new Draw({
      //   source: pipeSource,
      //   features: selectedFeatures,
      //   type: 'LineString',
      // });

      snap = new Snap({
        features: selectedFeatures,
        edge: true,
        vertex: true,
        pixelTolerance: 20,
        // source: trailSource,
      });

      snapTrail = new Snap({
        edge: true,
        vertex: true,
        pixelTolerance: 20,
        source: trailSource,
      });

      translate = new Translate({
        features: selectedFeatures,
      });

      // Create map with initial setup
      map = new Map({
        renderer: 'webgl',
        target: 'map',
        layers: [
          tiles,
          trails,
          // pipes,
          trailsAdd,
          trailsDelete,
          hydrants,
          defaultCenterLayer,
        ],
        view: new View({
          center,
          zoom: initialZoom,
          minZoom: minZoomLimit,
          maxZoom: maxZoomLimit,
          ...(rotationFactor && {
            rotation: rotationFactor,
          }),
          ...((!isEmpty(tempExtent) && extentFactors) && {
            extent: tempExtent,
          }),
        }),
        overlays: [editOverlay, hoverOverlay, sensorEditOverlay],
        controls: [
          new Control.Zoom(),
        ],
        interactions: defaultInteractions().extend([select, translate, modify, snap, snapTrail, draw]),
      });

      // Attempt to set our map's view with the hydrants extent, if there are no hydrants
      // attempt to use the trails and if there are no trails use the default center configuration
      // option as a fallback
      if (rotationFactor) {
        map.getView().setRotation(rotationFactor);
      }
      if (!isEmpty(tempExtent)) {
        map.getView().fit(
          tempExtent,
          {
            size: map.getSize(),
            ...(padding && {
              padding,
            }),
          },
        );
      } else if (!isEmpty(defaultCenterSource.getExtent())) {
        map.getView().fit(
          defaultCenterSource.getExtent(),
          {
            size: map.getSize(),
            ...(padding && {
              padding,
            }),
          },
        );
      }
      map.getView().setZoom(initialZoom);

      map.removeInteraction(translate);
      map.removeInteraction(snap);
      map.removeInteraction(snapTrail);
      map.removeInteraction(modify);
      map.removeInteraction(draw);
      // map.removeInteraction(draw2);

      if (pointermoveKey) {
        unByKey(pointermoveKey);
      }
      pointermoveKey = map.on('pointermove', mapUpdate);

      // Force our zoom controls to be in the bottom right corner
      const zoomControl = document.getElementsByClassName('ol-zoom')[0];
      const zoomControlIn = document.getElementsByClassName('ol-zoom-in')[0];
      const zoomControlOut = document.getElementsByClassName('ol-zoom-out')[0];
      zoomControl.style.left = 'auto';
      zoomControl.style.top = 'auto';
      zoomControl.style.right = '7em';
      zoomControl.style.bottom = '0.5em';
      zoomControl.style.height = '51px';
      zoomControl.style.border = '3px solid rgba(255, 255, 255, 0.4)';
      zoomControl.style.borderRadius = '10px';
      zoomControlIn.style.width = '1.475em';
      zoomControlOut.style.width = '1.475em';
      zoomControlIn.style.height = '1.575em';
      zoomControlOut.style.height = '1.575em';
      zoomControlIn.style.outline = '0px';
      zoomControlOut.style.outline = '0px';
      zoomControlIn.style.margin = '0px';
      zoomControlOut.style.margin = '0px';
      zoomControlIn.style.color = theme.textColorNavbar;
      zoomControlOut.style.color = theme.textColorNavbar;
      zoomControlIn.style.backgroundColor = theme.trackerControls;
      zoomControlOut.style.backgroundColor = theme.trackerControls;
      zoomControlIn.style.borderRadius = '7px 7px 0 0';
      zoomControlOut.style.borderRadius = '0 0 7px 7px';

      // Handle our map resizing
      window.onresize = () => {
        setTimeout(() => { map.updateSize(); }, 500);
      };
      setGen(1);
    }

    return () => {};
  }, [initRequestsComplete]);

  useEffect(() => {
    if (!weatherLoading && !valvesLoading && !defendersLoading && !sensorsLoading && !snowDepthLoading) {
      loaderTimeoutId = setTimeout(() => {
        setRenderLoader(false);
      }, 2000);
    }
    return () => {
      clearTimeout(loaderTimeoutId);
    };
  }, [weatherLoading, valvesLoading, defendersLoading, sensorsLoading, snowDepthLoading]);

  useEffect(() => {
    if (map && gen > 0) {
      // console.log(klikWeatherStations);
      hydrantFeatures = hydrantsArray.map((h) => {
        const c = [h.longitude, h.latitude];
        const p = Proj.fromLonLat(c);
        const g = new Point(p);
        const f = new Feature({
          geometry: g,
        });
        let hTemp = h;
        if (klikWeatherStations[h.name]) {
          hTemp = { ...h, name: `${h.name}🌡️` };
          // hTemp = { ...h, name: `${h.name}☁️` };
          // hTemp = { ...h, name: `${h.name}💧` };
        }
        f.set('hydrantData', hTemp);

        // This is an experiment with moving to an "adaptive" style function rather than using individually declared style functions.
        // It seems that we get better performance from the individually declared functions despite it being a bit messy.
        // f.set('selected', (editMode && (h.id === editHydrantID)) || (!editMode && h.id === props.hydrants?.selectedHydrantId));
        if (h.equipment?.equipmentType?.name === 'klik_gen5') {
          f.set('controlModeStatus', h.equipment?.ioValues?.[IOValueKeys.HydrantIOValueKeys.controlStatus]?.value);
          f.set('currentPosition', h.equipment?.ioValues?.[IOValueKeys.HydrantIOValueKeys.position]?.value);
        } else if (h.equipment?.equipmentType?.name === 'klik_gen3' || h.equipment?.equipmentType?.name === 'klik_gen2') {
          f.set('autoManual', h.equipment?.ioValues?.[IOValueKeys.HydrantIOValueKeys.autoManual]?.value);
          f.set('stage0Reached', h.equipment?.ioValues?.[IOValueKeys.HydrantIOValueKeys.stage0Reached]?.value);
        } else if (h.equipment?.equipmentType?.name === 'klik_snofi') {
          f.set('index', Math.round(h.equipment?.ioValues?.[IOValueKeys.HydrantIOValueKeys.index]?.value));
          f.set('controlMode', h.controlMode);
        }

        // Set Style appropriately
        if (editMode) {
          if (editMode === 3 && editSensorData?.flowZoneId === h?.flowZoneId && props.auth?.features?.FlowManagement) {
            f.setStyle(h.sequencingPriority === 2 ? styleFunctionPriorityTwo : h.sequencingPriority === 1 ? styleFunctionPriorityOne : h.sequencingPriority === 0 ? styleFunctionPriorityZero : styleFunctionOff);
          } else if (h.id === editHydrantID) {
            f.setStyle(styleFunctionSelected);
            // f.setStyle(styleFunctionAdaptive);
          } else {
            f.setStyle(styleFunctionOff);
          }
        } else if (hydrantPriorityView && h.trailId === props.trails?.selectedTrailId && props.auth?.features?.FlowManagement) {
          if (h.mobileGunId) {
            f.setStyle(h.sequencingPriority === 2 ? styleFunctionMobilePriorityTwo : h.sequencingPriority === 1 ? styleFunctionMobilePriorityOne : h.sequencingPriority === 0 ? styleFunctionMobilePriorityZero : styleFunctionMobileOff);
          } else {
            f.setStyle(h.sequencingPriority === 2 ? styleFunctionPriorityTwo : h.sequencingPriority === 1 ? styleFunctionPriorityOne : h.sequencingPriority === 0 ? styleFunctionPriorityZero : styleFunctionOff);
          }
        } else if (hydrantPriorityView && props.auth?.features?.FlowManagement) {
          f.setStyle(h.mobileGunId ? styleFunctionMobileOff : styleFunctionOff);
        } else if (h.id === props.hydrants?.selectedHydrantId) {
          // f.setStyle(styleFunctionAdaptive);
          f.setStyle(h.mobileGunId ? styleFunctionMobileSelected : styleFunctionSelected);
        } else if (
          (hydrantFilters.notes && h.hasUnacknowledged)
        || (hydrantFilters.alarm && h.hasAlarm)
        || (hydrantFilters.mobiles && h.mobileGunId)
        || (hydrantFilters.locked && h.isLocked)
        || (hydrantFilters.on && h.isEnabled)
        // || (hydrantFilters.off && h.isEnabled === false)
        || (hydrantFilters.off && !h.isEnabled)
        || (
          hydrantFilters.standby
            && h.equipment?.equipmentType?.name === 'klik_gen5'
            && f?.values_?.controlModeStatus >= 2
            && f?.values_?.currentPosition === 0
        )
        || (
          hydrantFilters.standby
          && (h.equipment?.equipmentType?.name === 'klik_gen3' || h.equipment?.equipmentType?.name === 'klik_gen2')
          && f?.values_?.autoManual === 0
          && f?.values_?.stage0Reached > 0
        )
        || (
          hydrantFilters.standby
          && h.equipment?.equipmentType?.name === 'klik_snofi'
          && f?.values_?.index === 0
          && f?.values_?.controlMode === 0
        )
        ) {
          if (h.isLocked) {
            if (h.hasUnacknowledged) {
              f.setStyle(h.mobileGunId ? styleFunctionMobileLockedNotes : styleFunctionLockedNotes);
            } else {
              f.setStyle(h.mobileGunId ? styleFunctionMobileLocked : styleFunctionLocked);
            }
          } else if (h.hasAlarm) {
            if (h.hasUnacknowledged) {
              f.setStyle(h.mobileGunId ? styleFunctionMobileAlarmNotes : styleFunctionAlarmNotes);
            } else {
              f.setStyle(h.mobileGunId ? styleFunctionMobileAlarm : styleFunctionAlarm);
            }
          } else if (
            (h.equipment?.equipmentType?.name === 'klik_gen5' && f.values_?.controlModeStatus >= 2 && f.values_.currentPosition === 0)
          || ((h.equipment?.equipmentType?.name === 'klik_gen3' || h.equipment?.equipmentType?.name === 'klik_gen2') && f.values_?.autoManual === 0 && f.values_?.stage0Reached > 0)
          || (h.equipment?.equipmentType?.name === 'klik_snofi' && f.values_?.index === 0 && f.values_?.controlMode === 0)
          ) {
            if (h.hasUnacknowledged) {
              f.setStyle(h.mobileGunId ? styleFunctionMobileStandbyNotes : styleFunctionStandbyNotes);
            } else {
              f.setStyle(h.mobileGunId ? styleFunctionMobileStandby : styleFunctionStandby);
            }
          } else if (h.isEnabled) {
            if (h.hasUnacknowledged) {
              f.setStyle(h.mobileGunId ? styleFunctionMobileOnNotes : styleFunctionOnNotes);
              // f.setStyle(styleFunctionAdaptive);
            } else {
              f.setStyle(h.mobileGunId ? styleFunctionMobileOn : styleFunctionOn);
              // f.setStyle(styleFunctionAdaptive);
            }
          } else if (h.hasUnacknowledged) {
            f.setStyle(h.mobileGunId ? styleFunctionMobileOffNotes : styleFunctionOffNotes);
          } else {
            f.setStyle(h.mobileGunId ? styleFunctionMobileOff : styleFunctionOff);
          }
        } else {
          f.setStyle(new Style({}));
        }

        return f;
      });
    }

    if (hydrantFeatures && editHydrantID === null) {
      hydrantSource.clear();
      if (editMode !== 1) {
        hydrantSource.addFeatures(hydrantFeatures);
        // When we generate our hydrants the first time we want to set the view appropriately
        // NOTE: we only want to do this if our hydrant extent isn't empty
        if (map && hydrantsGen < 1) {
          const tempExtent = hydrantSource.getExtent();
          if (!isEmpty(tempExtent)) {
            map.getView().fit(
              tempExtent,
              {
                size: map.getSize(),
                ...(padding && {
                  padding,
                }),
              },
            );
          }
          map.getView().setZoom(initialZoom);
          setHydrantsGen(1);
        }
      }
    }
  }, [gen, hydrant, props.hydrants?.selectedHydrantId, hydrantsArray, hydrantFilters, editHydrantID, editMode, klikWeatherStations, hydrantPriorityView]);

  const escapeEditMode = (event) => {
    const evt = event || window.event;
    let isEscape = false;
    let isUndo = false;
    if ('key' in evt) {
      isEscape = (evt.key === 'Escape' || evt.key === 'Esc');
    } else {
      isEscape = (evt.keyCode === 27);
    }
    // isUndo = (evt.metaKey && evt.keyCode === 90);
    isUndo = (evt.ctrlKey && evt.keyCode === 90);
    if (isEscape) {
      if (!editTrailIDRef.current) {
        setEditMode(0);
        setEditHydrantID(null);
        setEditTrailID(null);
        setTrailListVisible(false);
        setEditSensorData(null);
        if (editOverlay) {
          editOverlay.setPosition(undefined);
        }
        if (sensorEditOverlay) {
          sensorEditOverlay.setPosition(undefined);
        }
      } else {
        draw.abortDrawing();
        // draw2.abortDrawing();
      }
    }
    if (isUndo && editTrailIDRef.current) {
      draw.removeLastPoint();
      // draw2.removeLastPoint();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escapeEditMode);
  }, []);

  useEffect(() => {
    if (editOverlayDOMHandle && editOverlay) {
      editOverlayDOMHandle.addEventListener('mousedown', (evt) => {
        setTranslationActive(true);
        function move(e) {
          editOverlay.setOffset([0, -15]);
          editOverlay.setPosition(map.getEventCoordinate(e));
          map.getViewport().style.cursor = 'grabbing';
        }
        function end(e) {
          setTranslationActive(false);
          map.getViewport().style.cursor = 'grab';
          window.removeEventListener('mousemove', move);
          window.removeEventListener('mouseup', end);
        }
        window.addEventListener('mousemove', move);
        window.addEventListener('mouseup', end);
      });
    }
    if (sensorEditOverlayDOMHandle && sensorEditOverlay) {
      sensorEditOverlayDOMHandle.addEventListener('mousedown', (evt) => {
        setTranslationActive(true);
        function move(e) {
          sensorEditOverlay.setOffset([0, -15]);
          sensorEditOverlay.setPosition(map.getEventCoordinate(e));
          map.getViewport().style.cursor = 'grabbing';
        }
        function end(e) {
          setTranslationActive(false);
          map.getViewport().style.cursor = 'grab';
          window.removeEventListener('mousemove', move);
          window.removeEventListener('mouseup', end);
        }
        window.addEventListener('mousemove', move);
        window.addEventListener('mouseup', end);
      });
    }
  }, [gen]);

  useEffect(() => {
    if (map && gen > 0) {
      if (pointermoveKeyCursor) {
        unByKey(pointermoveKeyCursor);
      }

      pointermoveKeyCursor = map.on('pointermove', (e) => {
        const pixel = map.getEventPixel(e.originalEvent);
        // const hydrant = map.hasFeatureAtPixel(pixel, { layerFilter: (layer) => layer.get('name') === 'hydrants' });
        // const trail = map.hasFeatureAtPixel(pixel, { layerFilter: (layer) => layer.get('name') === 'trails' });
        // const weatherStation = map.hasFeatureAtPixel(pixel, { layerFilter: (layer) => layer.get('name') === 'weatherStations' });
        // const defender = map.hasFeatureAtPixel(pixel, { layerFilter: (layer) => layer.get('name') === 'defendersLayer' });
        // const drainValve = map.hasFeatureAtPixel(e.pixel, { layerFilter: (layer) => layer.get('name') === 'valvesLayer' });
        // const sensor = map.hasFeatureAtPixel(pixel, { layerFilter: (layer) => layer.get('name') === 'sensorsLayer' });
        const isHydrant = map.hasFeatureAtPixel(pixel, { layerFilter: (layer) => layer.get('name') === 'hydrants' });
        const isTrail = map.hasFeatureAtPixel(pixel, { layerFilter: (layer) => layer.get('name') === 'trails' });
        const isWeatherStation = map.hasFeatureAtPixel(pixel, { layerFilter: (layer) => layer.get('name') === 'weatherStations' });
        const isDefender = map.hasFeatureAtPixel(pixel, { layerFilter: (layer) => layer.get('name') === 'defendersLayer' });
        const isDrainValve = map.hasFeatureAtPixel(pixel, { layerFilter: (layer) => layer.get('name') === 'valvesLayer' });
        const isSensor = map.hasFeatureAtPixel(pixel, { layerFilter: (layer) => layer.get('name') === 'sensorsLayer' });
        let selectedEditHydrant = false;
        let selectedEditSensor = false;
        map.forEachFeatureAtPixel(pixel, (feature) => {
          if (feature.get('hydrantData')?.id === editHydrantID) {
            selectedEditHydrant = true;
          }
          if (feature.get('sensorData') === editSensorData) {
            selectedEditSensor = true;
          }
        });

        const viewport = map.getViewport();
        if (!editMode) {
          viewport.style.cursor = (isHydrant || isTrail || isWeatherStation || isDefender || isDrainValve) ? 'pointer' : '';
        } else if (editMode === 1) {
          viewport.style.cursor = (isTrail && !editTrailID) ? 'pointer' : '';
        // } else if (editMode === 4) {
        //   viewport.style.cursor = 'pointer';
        } else if (editMode === 2) {
          if (translationActive) {
            viewport.style.cursor = 'grabbing';
          } else if (isHydrant && selectedEditHydrant) {
            viewport.style.cursor = 'grab';
          } else if (isWeatherStation || isDefender || isDrainValve) {
            viewport.style.cursor = 'not-allowed';
          } else {
            viewport.style.cursor = isHydrant ? 'pointer' : 'crosshair';
          }
        } else if (editMode === 3) {
          if (translationActive) {
            viewport.style.cursor = 'grabbing';
          } else if (isSensor && selectedEditSensor) {
            viewport.style.cursor = 'grab';
          } else {
            viewport.style.cursor = isSensor ? 'pointer' : 'crosshair';
          }
        }
      });
    }
  }, [editHydrantID, editSensorData, editMode, gen, translationActive, editTrailID]);

  useEffect(() => {
    if (translate) {
      translate.on('translatestart', (e) => {
        setTranslationActive(true);
        map.getViewport().style.cursor = 'grabbing';
      });
      translate.on('translateend', (e) => {
        setTranslationActive(false);
        map.getViewport().style.cursor = 'grab';
        e.features.forEach((feature) => {
          setEditSensorCoord(Proj.toLonLat(feature.getGeometry().getCoordinates()));
          setEditHydrantCoord(Proj.toLonLat(feature.getGeometry().getCoordinates()));
        });
      });
    }
  }, [gen]);

  useEffect(() => {
    setHydrantPriorityView(false);
    // pipeSource.clear();
  }, [props.trails?.selectedTrailId]);

  useEffect(() => {
    if (select) {
      if (!editTrailID) {
        trailAddSource.clear();
        selectedFeatures.clear();
      }
    }
    // pipeSource.clear();
  }, [editMode, editTrailID]);

  useEffect(() => {
    if (sensorsLayer) {
      if (editMode !== 3) {
        sensorsLayer.setVisible(false);
      } else {
        sensorsLayer.setVisible(true);
      }
    }
  }, [editMode]);

  useEffect(() => {
    if (snowDepthLayer) {
      if (!snowDepthMode) {
        snowDepthLayer.setVisible(false);
      } else {
        snowDepthLayer.setVisible(true);
      }
    }
  }, [snowDepthMode]);

  // useEffect(() => {
  //   if (sensorsLayer) {
  //     if (editMode !== 3) {
  //       sensorsLayer.setVisible(false);
  //     } else {
  //       sensorsLayer.setVisible(true);
  //     }
  //   }
  // }, [editMode]);

  useEffect(() => {
    guns = Object.values(props.guns.guns || {});
    gunLookup = props.guns.guns;
  }, [props.guns]);

  useEffect(() => {
    mobileGuns = Object.values(props.mobileGuns.mobileGuns || {});
  }, [props.mobileGuns]);

  useEffect(() => {
    hydrantModels = Object.values(props.hydrants.hydrantModels || {});
    hydrantModelLookup = props.hydrants.hydrantModels;
  }, [props.hydrants?.hydrantModels]);

  return (
    <div css={styles.background}>
      {renderLoader && (
        <div
          css={[
            styles.loadingContainer,
            props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
            (weatherLoading && valvesLoading && defendersLoading && sensorsLoading && snowDepthLoading) ? css`opacity: 1;` : css`opacity: 0;`,
          ]}
        >
          <div css={css`position: relative; width: 100%; height: 100%;`}>
            <LoadingIndicator visible={weatherLoading && valvesLoading && defendersLoading && sensorsLoading && snowDepthLoading} zIndex={4} />
          </div>
        </div>
      )}
      <div id="map" css={[styles.map, (editMode === 2) ? styles.mapEdit : {}]} />
      {renderDefenders(props.trackerEquipment.defenders, defendersClosed, setDefendersClosed)}
      {renderWeatherStations(props.weatherStations?.weatherStations, weatherClosed, setWeatherClosed, props.settings?.settings)}
      {renderDrainValves(props.equipment.valves, valvesClosed, setValvesClosed, props.setIOValue)}
      {(editMode !== 0)
      && (
        <div>
          <Banner
            text={(() => {
              if (editMode === 1) {
                return language.mapTrailEditBanner;
              } else if (editMode === 2) {
                return language.mapHydrantEditBanner;
              } else if (editMode === 3) {
                return language.sensorEditBanner;
              // } else if (editMode === 4) {
              //   return 'Pipes Mode';
              }
              return '';
            })()}
            color="white"
            backgroundColor="#1cd05d"
            onXClick={() => {
              setEditMode(0);
              setTrailListVisible(false);
              setEditHydrantID(null);
              setEditSensorData(null);
              setEditTrailID(null);
              if (editOverlay) {
                editOverlay.setPosition(undefined);
              }
              if (sensorEditOverlay) {
                sensorEditOverlay.setPosition(undefined);
              }
            }}
            navbarClosed={props.navbarClosed}
            xTooltip={language.mapEditClose}
          />
          {(editMode === 1)
          && (
            <TrailEditor
              doSelect={(featureID) => {
                setTrailListSelection(true);
                selectedFeatures.clear();
                selectedFeatures.push(trailSource.getFeatureById(featureID));
              }}
              trails={props.trails?.trails || {}}
              trailListVisible={trailListVisible}
              setTrailListVisible={setTrailListVisible}
              editTrailID={editTrailID}
              setEditTrailID={setEditTrailID}
              trailNameKey={null} // This is an optional prop, pass in a key to use trails as dictionary lookup
              navbarClosed={props.navbarClosed}
            />
          )}

        </div>
      )}
      {!editMode && Object.values(hydrantFilters).indexOf(false) >= 0
      && (
        <Banner
          text={language.hydrantFilterBanner}
          color="white"
          backgroundColor="rgb(153, 201, 255)"
          onBannerClick={() => {
            setShowLegend(true);
          }}
          onXClick={() => {
            setHydrantFilters(defaultHydrantFilters);
          }}
          navbarClosed={props.navbarClosed}
          xTooltip={language.hydrantFilterClose}
        />
      )}
      <button
        aria-label="menu"
        type="button"
        css={styles.menu}
        onClick={() => { setShowMenu(!showMenu); }}
      >
        {!showMenu && <FaAngleDoubleUp css={css`height: 100%;`} />}
        {showMenu && <FaAngleDoubleDown css={css`height: 100%;`} />}
      </button>
      <button
        aria-label="origin"
        type="button"
        id="origin"
        css={styles.origin}
        onClick={() => {
          if (rotationFactor) {
            map.getView().setRotation(rotationFactor);
          }
          const tempExtent = hydrantSource.getExtent();
          if (!isEmpty(tempExtent)) {
            map.getView().fit(
              tempExtent,
              {
                size: map.getSize(),
                ...(padding && {
                  padding,
                }),
              },
            );
          } else if (!isEmpty(defaultCenterSource.getExtent())) {
            map.getView().fit(
              defaultCenterSource.getExtent(),
              {
                size: map.getSize(),
                ...(padding && {
                  padding,
                }),
              },
            );
          }
          map.getView().setZoom(initialZoom);
        }}
      >
        {/* {!showMenu && <FaAngleDoubleUp css={css`height: 100%;`} />} */}
        <FaCrosshairs css={css`height: 100%; width: 66%;`} />
      </button>
      {editMode === 1 && (<TrailEditInstructions />)}
      <div css={showMenu ? styles.iconContainer : styles.hidden}>
        <img
          css={styles.legendIcon}
          alt="trail download"
          data-tooltip-float
          data-tooltip-content={language.downloadTrailKML}
          src={legendIcons[`trailDownload${theme.iconKey}`]}
          data-tooltip-id="legendTooltip"
          onClick={() => {
            downloadKML('trails');
          }}
        />
        <img
          css={styles.legendIcon}
          alt="hydrant download"
          data-tooltip-float
          data-tooltip-content={language.downloadHydrantKML}
          src={legendIcons[`hydrantDownload${theme.iconKey}`]}
          data-tooltip-id="legendTooltip"
          onClick={() => {
            downloadKML('hydrants');
          }}
        />
        {props.auth?.user?.roles && props.auth?.user?.roles.includes(mapEditRole)
        && (
          <>
            <img
              css={styles.legendIcon}
              src={legendIcons[`sensorImage${theme.iconKey}`]}
              alt="sensor edit"
              data-tooltip-float
              data-tooltip-content={language.editSensorTooltip}
              data-tooltip-id="legendTooltip"
              onClick={() => {
                if (editMode !== 3) {
                  setEditMode(3);
                  setEditHydrantID(null);
                  if (editOverlay) {
                    editOverlay.setPosition(undefined);
                  }
                  // Close all controls  when entering edit mode
                  setTrailMenuVisible(false);
                  setMobileMenuVisible(false);
                  setMobileViewVisible(false);
                  props.selectMobile(null);
                  setGlobalNotesViewVisible(false);
                  setHydrantSettingsVisible(false);
                  setAutoTrailSettingsVisible(false);
                  props.selectHydrant(null);
                  props.selectTrail(null);
                  props.selectAutoTrail(null);
                } else {
                  setEditTrailID(null);
                  setTrailListVisible(false);
                  setEditMode(0);
                  setEditSensorData(null);
                  if (sensorEditOverlay) {
                    sensorEditOverlay.setPosition(undefined);
                  }
                }
              }}
            />
            <img
              css={styles.legendIcon}
              src={legendIcons[`trailIcon${theme.iconKey}`]}
              alt=" edit trails"
              data-tooltip-float
              data-tooltip-content={language.editTrailMapTooltip}
              data-tooltip-id="legendTooltip"
              onClick={() => {
                if (editMode !== 1) {
                  setEditMode(1);
                  setEditHydrantID(null);
                  setEditSensorData(null);
                  if (editOverlay) {
                    editOverlay.setPosition(undefined);
                  }
                  if (sensorEditOverlay) {
                    sensorEditOverlay.setPosition(undefined);
                  }
                  // Close all controls  when entering edit mode
                  setTrailMenuVisible(false);
                  setMobileMenuVisible(false);
                  setMobileViewVisible(false);
                  props.selectMobile(null);
                  setGlobalNotesViewVisible(false);
                  setHydrantSettingsVisible(false);
                  setAutoTrailSettingsVisible(false);
                  props.selectHydrant(null);
                  props.selectTrail(null);
                  props.selectAutoTrail(null);
                } else {
                  setEditTrailID(null);
                  setTrailListVisible(false);
                  setEditMode(0);
                }
              }}
            />
            <img
              css={styles.legendIcon}
              src={legendIcons[`snowGun${theme.iconKey}`]}
              alt="edit hydrants"
              data-tooltip-float
              data-tooltip-content={language.editHydrantMapTooltip}
              data-tooltip-id="legendTooltip"
              onClick={() => {
                if (editMode !== 2) {
                  setEditMode(2);
                  setEditTrailID(null);
                  setEditSensorData(null);
                  if (sensorEditOverlay) {
                    sensorEditOverlay.setPosition(undefined);
                  }
                  // Close all controls  when entering edit mode
                  setTrailMenuVisible(false);
                  setMobileMenuVisible(false);
                  setMobileViewVisible(false);
                  props.selectMobile(null);
                  setTrailListVisible(false);
                  setGlobalNotesViewVisible(false);
                  setHydrantSettingsVisible(false);
                  setAutoTrailSettingsVisible(false);
                  props.selectHydrant(null);
                  props.selectTrail(null);
                  props.selectAutoTrail(null);
                } else {
                  setEditMode(0);
                  setEditHydrantID(null);
                  if (editOverlay) {
                    editOverlay.setPosition(undefined);
                  }
                }
              }}
            />
            {/* <img
              css={styles.legendIcon}
              src={legendIcons[`hydrantIcon${theme.iconKey}`]}
              alt="edit pipes"
              data-tooltip-content={language.editHydrantMapTooltip}
              data-tooltip-id="legendTooltip"
              onClick={() => {
                if (editMode !== 4) {
                  setEditMode(4);
                  setEditTrailID(null);
                  // Close all controls  when entering edit mode
                  setTrailMenuVisible(false);
                  setTrailListVisible(false);
                  setGlobalNotesViewVisible(false);
                  setHydrantSettingsVisible(false);
                  setAutoTrailSettingsVisible(false);
                  props.selectHydrant(null);
                  props.selectTrail(null);
                  props.selectAutoTrail(null);
                } else {
                  setEditMode(0);
                  setEditHydrantID(null);
                  if (editOverlay) {
                    editOverlay.setPosition(undefined);
                  }
                }
              }}
            /> */}
          </>
        )}
        {props.auth?.user?.roles && props.auth?.user?.roles.includes(developerRole) && (
          <img
            css={styles.legendIcon}
            src={legendIcons[`configIcon${theme.iconKey}`]}
            alt="edit hydrants"
            data-tooltip-float
            data-tooltip-content={language.logConfigurationDetails}
            data-tooltip-id="legendTooltip"
            onClick={() => {
              if (map) {
                console.log('Values used by developers for initial setup. (See Configuration.js)');
                console.log(`Rotation Factor: ${map.getView().getRotation()}`);
                console.log(`Zoom Factor: ${map.getView().getZoom()}`);
              }
            }}
          />
        )}
        <img
          css={styles.legendIcon}
          src={legendIcons[`thermo${theme.iconKey}`]}
          alt="thermo"
          data-tooltip-float
          data-tooltip-content={language.toggleWeatherStationTooltip}
          data-tooltip-id="legendTooltip"
          onClick={() => {
            const tempClosed = {};
            Object.values(props.weatherStations?.weatherStations).forEach((station) => {
              if (
                station.weatherlinkStation?.longitude
                || station.openWeatherMapLocation?.longitude
                || station.weatherStationTrackerEquipment?.longitude
                || station.weatherStationEquipment?.location?.longitude
              ) {
                tempClosed[station.id] = !globalWeatherToggle;
              }
            });
            setGlobalWeatherToggle(!globalWeatherToggle);
            setWeatherClosed(tempClosed);
          }}
        />
        <img
          css={styles.legendIcon}
          src={legendIcons[`keyIcon${theme.iconKey}`]}
          alt="key"
          data-tooltip-float
          data-tooltip-content={language.toggleLegendTooltip}
          data-tooltip-id="legendTooltip"
          onClick={() => { setShowLegend(!showLegend); }}
        />
        {props.auth?.features?.SnowDepth && (
        <img
          css={styles.legendIcon}
          alt="snow depth"
          data-tooltip-float
          data-tooltip-content={language.snowDepth}
          src={legendIcons[`snowDepthImage${theme.iconKey}`]}
          data-tooltip-id="legendTooltip"
          onClick={() => {
            setSnowDepthMode(!snowDepthMode);
          }}
        />
        )}
      </div>
      <ReactTooltip id="legendTooltip" place="left" offset={20} style={{ borderRadius: '8px' }} />
      <form css={showLegend && !editMode ? styles.legend : styles.hidden}>
        <div css={css`position: relative;width: 100%; display: flex; justify-content: center;`}>
          <FaTimes
            css={styles.iconTimes}
            onClick={() => { setShowLegend(false); }}
            size={21}
            alt="close"
          />
          <h2>{language.legend}</h2>
        </div>
        <div css={css`width: 100%; display: flex; align-items: flex-start;`}>
          <div css={css`margin-left: 5px;`}>{language.show}:</div>
        </div>
        <div css={styles.hexPair}>
          <input
            data-lpignore
            type="checkbox"
            id="showAllHydrants"
            css={styles.legendCheckbox}
            checked={hydrantFilters.all}
            onChange={(e) => { legendCheckboxChange(e, 'all'); }}
          />
          <svg height="27" width="24" css={styles.legendHex}>
            <polygon
              points="12,2 22,7.75 22,19.25 12,25 2,19.25 2,7.75"
              stroke="black"
              fill="white"
              strokeWidth="2"
            />
          </svg>
          <p css={css`margin-left: 5px;`}>{language.all}</p>
        </div>
        <div css={styles.hexPair}>
          <input
            data-lpignore
            type="checkbox"
            id="showOffHydrants"
            css={styles.legendCheckbox}
            checked={hydrantFilters.off}
            onChange={(e) => { legendCheckboxChange(e, 'off'); }}
          />
          <svg height="27" width="24" css={styles.legendHex}>
            <polygon
              points="12,2 22,7.75 22,19.25 12,25 2,19.25 2,7.75"
              stroke={theme.hydrantOffStroke}
              fill={theme.offRed}
              strokeWidth="2"
            />
          </svg>
          <p css={css`margin-left: 5px;`}>{language.off}</p>
        </div>
        <div css={styles.hexPair}>
          <input
            data-lpignore
            type="checkbox"
            id="showOnHydrants"
            css={styles.legendCheckbox}
            checked={hydrantFilters.on}
            onChange={(e) => { legendCheckboxChange(e, 'on'); }}
          />
          <svg height="27" width="24" css={styles.legendHex}>
            <polygon
              points="12,2 22,7.75 22,19.25 12,25 2,19.25 2,7.75"
              stroke="rgba(1,120,0,1)"
              fill={theme.onGreen}
              strokeWidth="2"
            />
          </svg>
          <p css={css`margin-left: 5px;`}>{language.on}</p>
        </div>
        <div css={styles.hexPair}>
          <input
            data-lpignore
            type="checkbox"
            id="showAlarmHydrants"
            css={styles.legendCheckbox}
            checked={hydrantFilters.alarm}
            onChange={(e) => { legendCheckboxChange(e, 'alarm'); }}
          />
          <svg height="27" width="24" css={styles.legendHex}>
            <polygon
              points="12,2 22,7.75 22,19.25 12,25 2,19.25 2,7.75"
              stroke="rgba(120,0,0,1)"
              fill={theme.alarm}
              strokeWidth="2"
            />
          </svg>
          <p css={css`margin-left: 5px;`}>{language.alarm}</p>
        </div>
        <div css={styles.hexPair}>
          <input
            data-lpignore
            type="checkbox"
            id="showLockedHydrants"
            css={styles.legendCheckbox}
            checked={hydrantFilters.locked}
            onChange={(e) => { legendCheckboxChange(e, 'locked'); }}
          />
          <svg height="27" width="24" css={styles.legendHex}>
            <polygon
              points="12,2 22,7.75 22,19.25 12,25 2,19.25 2,7.75"
              stroke="black"
              fill={theme.connectivity}
              strokeWidth="2"
            />
          </svg>
          <p css={css`margin-left: 5px;`}>{language.locked}</p>
        </div>
        <div css={styles.hexPair}>
          <input
            data-lpignore
            type="checkbox"
            id="showStandbyHydrants"
            css={styles.legendCheckbox}
            checked={hydrantFilters.standby}
            onChange={(e) => { legendCheckboxChange(e, 'standby'); }}
          />
          <svg height="27" width="24" css={styles.legendHex}>
            <polygon
              points="12,2 22,7.75 22,19.25 12,25 2,19.25 2,7.75"
              stroke="rgba(0,94,204,1)"
              fill="rgba(153, 201, 255, 1)"
              strokeWidth="2"
            />
          </svg>
          <p css={css`margin-left: 5px;`}>{language.standby}</p>
        </div>
        <div css={styles.hexPair}>
          <input
            data-lpignore
            type="checkbox"
            id="showNotesHydrants"
            css={styles.legendCheckbox}
            checked={hydrantFilters.notes}
            onChange={(e) => { legendCheckboxChange(e, 'notes'); }}
          />
          <svg height="27" width="24" css={styles.legendHex}>
            <polygon
              points="12,2 22,7.75 22,19.25 12,25 2,19.25 2,7.75"
              stroke="rgb(204, 0, 255)"
              fill="white"
              strokeWidth="2"
            />
          </svg>
          <p css={css`margin-left: 5px;`}>{language.notes}</p>
        </div>
        <div css={styles.hexPair}>
          <input
            data-lpignore
            type="checkbox"
            id="showMobileHydrants"
            css={styles.legendCheckbox}
            checked={hydrantFilters.mobiles}
            onChange={(e) => { legendCheckboxChange(e, 'mobiles'); }}
          />
          <svg height="27" width="24" css={styles.legendHex}>
            <polygon
              points="12,3.25 22,13.5 12,23.75 2,13.5"
              stroke="black"
              fill="white"
              strokeWidth="2"
            />
          </svg>
          <p css={css`margin-left: 5px;`}>{language.mobiles}</p>
        </div>
        <div css={styles.hexPair}>
          <svg height="27" width="24" css={[styles.legendHex, styles.legendNoCheckbox]}>
            <polygon
              points="12,2 22,7.75 22,19.25 12,25 2,19.25 2,7.75"
              stroke="rgba(255,128,0,1)"
              fill="rgba(255,128,0,0.7)"
              strokeWidth="2"
            />
          </svg>
          <p css={css`margin-left: 5px;`}>{language.selected}</p>
        </div>
        <div css={styles.hexPair}>
          <img alt="thermometer" src={Thermometer} css={css`height: 23px; margin: 2px 2px 2px 28px;`} />
          <p css={css`margin-left: 5px;`}>{language.weatherStation}</p>
        </div>
        <div css={styles.hexPair}>
          <img alt="defender" src={DefenderImage} css={css`height: 23px; margin: 2px 2px 2px 28px;`} />
          <p css={css`margin-left: 5px;`}>{language.defender}</p>
        </div>
        <div css={styles.hexPair}>
          <img alt="drainValve" src={ValveImage} css={css`height: 23px; margin: 2px 2px 2px 28px;`} />
          <p css={css`margin-left: 5px;`}>{language.drainValve}</p>
        </div>
      </form>
      <EditOverlay
        editHydrantID={editHydrantID}
        hydrantData={(() => {
          if (editHydrantID === null) {
            return null;
          } else if (editHydrantID === -1) {
            // If we are adding a new hydrant set its name to an empty string
            // and then set its trailId to default to whichever trail was clicked
            return {
              name: '',
              ...(
                editHydrantTrail !== null
                && { trailId: editHydrantTrail }
              ),
            };
          // } else if (props.hydrants?.selectedHydrantId === editHydrantID) {
          } else if (props.hydrants?.hydrant?.id === editHydrantID) {
            return props.hydrants?.hydrant;
          // } else if (props.trails?.hydrants?.[editHydrantID]) {
          //   return props.trails?.hydrants?.[editHydrantID];
          } else {
            return null;
          }
        })()}
        trails={trailsArray}
        gateways={gatewaysArray}
        editMode={editMode}
        closeEditOverlay={() => {
          setEditHydrantID(null);
          if (editOverlay) {
            editOverlay.setPosition(undefined);
          }
        }}
        grabbing={translationActive}
        zones={props.zones}
        addHydrant={props.addHydrant}
        deleteHydrant={props.deleteHydrant}
        updateHydrant={props.updateHydrant}
        coordinates={editHydrantCoord}
        fetchElevation={props.fetchElevation}
        map={props.map}
        addAutoHydrant={props.addAutoHydrant}
        addSnofiHydrant={props.addSnofiHydrant}
        deleteAutoHydrant={props.deleteAutoHydrant}
        addGateway={props.addGateway}
        // deleteGateway={props.deleteGateway}
        editPLC={props.editPLC}
        ipAddressLookup={ipAddressLookup}
        autoHydrantError={props.trackerEquipment?.autoHydrantError}
        addHydrantSensor={props.addHydrantSensor}
        deleteHydrantSensor={props.deleteHydrantSensor}
        autoTrailsIOValues={props.trails.autotrailsiovalues || {}}
        fetchAllAutoTrailsIOValues={props.fetchAllAutoTrailsIOValues}
      />
      <SensorEditOverlay
        sensorData={editSensorData}
        weatherStations={weatherStationOptions}
        trackerIOValues={props.trails?.trackerIOValues || {}}
        plantIOValues={props.trends?.IOValues || {}}
        plcs={props.plcs.plcs || {}}
        editMode={editMode}
        closeEditOverlay={() => {
          setEditSensorData(null);
          setEditSensorCoord(null);
          if (sensorEditOverlay) {
            sensorEditOverlay.setPosition(undefined);
          }
        }}
        grabbing={translationActive}
        coordinates={editSensorCoord}
        fetchElevation={props.fetchElevation}
        map={props.map}
        addZoneIOValue={props.addZoneIOValue}
        deleteZoneIOValue={props.deleteZoneIOValue}
        editZoneIOValue={props.editZoneIOValue}
        fetchAllZoneIOValues={props.fetchAllZoneIOValues}
        flowManagementConfigs={flowManagementConfigs}
        editFlowZoneConfig={props.editFMConfig}
        fetchAllFlowZoneConfigs={props.fetchAllFlowManagementSettings}
        flowManagementFeature={props.auth?.features?.FlowManagement}
      />
      <TrailEditOverlay
        fetchAllTrailsWithGeometry={props.fetchAllTrailsWithGeometry}
        featureAdd={featureAdd}
        setFeatureAdd={setFeatureAdd}
        setFeatureRemove={setFeatureRemove}
        trailData={(() => {
          if (editTrailID === null) {
            return undefined;
          } else if (editTrailID === -1) {
            return { name: '' };
          } else if (props.trails?.trails?.[editTrailID]) {
            return props.trails?.trails?.[editTrailID];
          } else {
            return undefined;
          }
        })()}
        editMode={editMode}
        navbarClosed={props.navbarClosed}
        closeEditOverlay={() => {
          setEditTrailID(null);
        }}
        modifyEnd={modifyEnd}
        setModifyEnd={setModifyEnd}
        selectedLength={selectedLength}
        selectedFeatures={selectedFeatures}
        drawnFeaturesSource={trailAddSource}
        existingFeaturesSource={trailSource}
        addTrail={props.addTrail}
        deleteTrail={props.deleteTrail}
        updateTrail={props.updateTrail}
        map={props.map}
        trailListSelection={trailListSelection}
        setTrailListSelection={setTrailListSelection}
      />
      <HoverOverlay
        hydrantData={hoverOverlayData?.hydrantData}
        trailData={hoverOverlayData?.trailData}
        sensorData={hoverOverlayData?.sensorData}
        weatherData={hoverOverlayData.weatherData}
        defenderData={hoverOverlayData.defenderData}
        drainValveData={hoverOverlayData.drainValveData}
        snowDepthData={hoverOverlayData.snowDepthData}
        editMode={editMode}
        gunModelLookup={gunModelLookup}
        celsius={props.settings?.settings?.useCelsius}
      />
      {!editMode
      && (
      <Controls
        trails={trailsArray}
        features={props.auth?.features}
        presets={props.presets?.presets ? Object.values(props.presets?.presets) : []}
        fetchAllTrails={props.fetchAllTrails}
        autotrails={autoTrails}
        hydrantTrend={hydrantTrend}
        setHydrantTrend={setHydrantTrend}
        setHydrantTrendFilter={setHydrantTrendFilter}
        hydrants={hydrantsArray}
        hydrant={hydrant}
        hydrantTrendFeature={props.auth?.features?.HydrantTrends}
        hydrantTrendFlow={trendFlow}
        hydrantTrendWetbulb={trendWetbulb}
        selectedHydrantId={props.hydrants?.selectedHydrantId}
        trail={trail}
        autoTrail={autoTrail}
        guns={guns}
        weatherStations={weatherStationOptions}
        deleteMobileGun={props.deleteMobileGun}
        addMobileGun={props.addMobileGun}
        editMobileGun={props.editMobileGun}
        mobileGuns={mobileGuns}
        selectedMobile={mobile}
        selectedMobileID={props.mobileGuns?.selectedMobileID}
        hydrantModels={hydrantModels}
        hydrantModelLookup={hydrantModelLookup}
        onMobileSelect={(m) => {
          props.selectMobile(m?.id !== undefined ? m.id : null);
        }}
        onHydrantSelect={(h) => {
          props.selectHydrant(h?.id !== undefined ? h.id : null);
        }}
        onTrailSelect={(t) => {
          props.selectTrail(t);
        }}
        onAutoTrailSelect={(h) => {
          props.selectAutoTrail(h);
        }}
        updateTrail={props.updateTrail}
        updateHydrant={props.updateHydrant}
        bulkUpdateHydrants={props.bulkUpdateHydrants}
        bulkSetHydrantIOValues={props.bulkSetHydrantIOValues}
        setHydrantIOValue={props.setHydrantIOValue}
        setTrailState={props.setTrailState}
        flowManagementFeature={props.auth?.features?.FlowManagement}
        setPriorityView={props.setPriorityView}
        priorityView={props.trails?.priorityView}
        setHydrantPriorityView={setHydrantPriorityView}
        hydrantPriorityView={hydrantPriorityView}
        setAutoTrailIOValue={props.setAutoTrailIOValue}
        setAutoTrailValue={props.setAutoTrailValue}
        addNote={props.addNote}
        user={props.auth?.user}
        // probably don't want to pass roles like this
        mapEditRole={mapEditRole}
        updateNote={props.updateNote}
        closeHydrantMenu={closeHydrantMenu}
        setCloseHydrantMenu={setCloseHydrantMenu}
        trailMenuVisible={trailMenuVisible}
        setTrailMenuVisible={setTrailMenuVisible}
        setMobileMenuVisible={setMobileMenuVisible}
        mobileMenuVisible={mobileMenuVisible}
        setMobileViewVisible={setMobileViewVisible}
        mobileViewVisible={mobileViewVisible}
        mobileEquipment={props.trackerEquipment.mobileEquipment}
        globalNotes={globalNotes}
        globalNotesViewVisible={globalNotesViewVisible}
        setGlobalNotesViewVisible={setGlobalNotesViewVisible}
        hydrantSettingsVisible={hydrantSettingsVisible}
        setHydrantSettingsVisible={setHydrantSettingsVisible}
        autoTrailSettingsVisible={autoTrailSettingsVisible}
        setAutoTrailSettingsVisible={setAutoTrailSettingsVisible}
        trailNameKey={null} // This is an optional prop, pass in a key to use trails as dictionary lookup
        IOValueKeys={IOValueKeys}
        gunModelLookup={gunModelLookup}
        setGunModelLookup={setGunModelLookup}
        gunLookup={gunLookup}
        navbarClosed={props.navbarClosed}
        defenders={props.trackerEquipment.defenders}
        // celsius={props.settings?.settings?.useCelsius}
        snowDepthMode={snowDepthMode}
        setSnowDepthMode={setSnowDepthMode}
        defaultHydrantFiltersObject={{
          true: defaultHydrantFilters,
          false: defaultHydrantFiltersFalse,
        }}
        hydrantFilters={hydrantFilters}
        setHydrantFilters={setHydrantFilters}
        settings={props.settings?.settings}
        updateSettings={props.updateSettings}
        fetchSnowDepthsByDate={props.fetchSnowDepthsByDate}
      />
      )}
    </div>
  );
}

const stylesFromTheme = (theme) => {
  return {
    background: css`
    flex: 1;
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
    @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
      height: 100%;
      height: 100dvh;
    }
  `,
    map: css`
      height: 100vh;
      width: 100%;
      position: relative;
    `,
    mapEdit: css`
      cursor: crosshair;
    `,
    hidden: css`
       display: none;
    `,
    hexPair: css`
       display: flex;
       align-items: center;
       justify-content: flex-start;
       width: 200px;
       padding-left: 20px;
       margin-bottom: 5px;
    `,
    legend: css`
       position: absolute;
       right: 0px;
       top: 0px;
       margin: 0.5em;
       margin-top: calc(25px + 0.5em);
       width: 200px;
      //  background-color: rgba(100,255,255,0.9);
       background-color: ${theme.trackerControls};
       opacity: 0.9;
       border: 3px solid ${theme.textColor};
       color: ${theme.textColorNavbar};
       border-radius: 10px;
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: flex-start;
    `,
    legendIcon: css`
      margin: 10px;
      margin-bottom: 0;
      height: 38px;
      cursor: pointer;
      :last-of-type {
        margin-bottom: 10px;
      }
    `,
    iconContainer: css`
      position: absolute;
      bottom: 60px;
      right: 0px;
      margin: 0.5em;
      background-color: ${theme.trackerControls};
      // opacity: 0.8;
      color: ${theme.textColor};
      border-radius: 10px;
      display: flex;
      flex-direction: column; 
    `,
    origin: css`
       position: absolute;
       right: 47px;
       bottom: 0px;
       height: 52px;
       width: 40px;
       border-radius: 10px;
       margin: 0.5em;
       background-color: ${theme.trackerControls};
       background-clip: padding-box;
       color: ${theme.textColorNavbar};
       border: 3px solid rgba(255, 255, 255, 0.4);
       cursor: pointer;
       display: flex;
       align-items: center;
       justify-content: center;
       :hover {
        border-color: rgba(255, 255, 255, 0.6);
       }

    `,
    menu: css`
       position: absolute;
       right: 0px;
       bottom: 0px;
       height: 52px;
       width: 40px;
       border-radius: 10px;
       margin: 0.5em;
       background-color: ${theme.trackerControls};
       color: ${theme.textColorNavbar};
       border: 3px solid rgba(255, 255, 255, 0.4);
       background-clip: padding-box;
       cursor: pointer;
       display: flex;
       align-items: center;
       justify-content: center;
       :hover {
        border-color: rgba(255, 255, 255, 0.6);
       }

    `,
    customEditCursor: css`
       width: 3rem;
       height: 3rem;
       border: 2px solid black;
       border-radius: 50%;
       position: absolute;
       transform: translate(-50%, -50%);
       pointer-events: none;
    `,
    legendCheckbox: css`
       cursor: pointer;
       margin-right: 15px;
    `,
    legendHex: css`
       min-width: 24px;
    `,
    legendNoCheckbox: css`
       margin-left: 28px;
    `,
    iconTimes: css`
      color: ${theme.close};
      margin: 4px 4px 0px 0px;
      position: absolute;
      top: 0px;
      right: 0px;
      cursor: pointer;
      :hover {
          color: ${theme.closeHover};
      }
    `,
    list: css`
    font-weight: bold;
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 4px;
    padding: 4px 8px;

    :hover {
      background: rgba(0,0,0,0.08);
    }
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

TrackerScreen.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      roles: PropTypes.string,
      username: PropTypes.string,
    }),
    features: PropTypes.shape({
      SnowDepth: PropTypes.bool,
      FlowManagement: PropTypes.bool,
      HydrantTrends: PropTypes.bool,
    }),
    authenticated: PropTypes.bool,
    need2fa: PropTypes.bool,
    error: PropTypes.number,
  }).isRequired,

  trails: PropTypes.shape({
    trails: PropTypes.shape({}),
    autotrails: PropTypes.shape({}),
    autotrailsiovalues: PropTypes.shape({}),
    trail: PropTypes.shape({}),
    hydrants: PropTypes.shape({}),
    selectedTrailName: PropTypes.string,
    selectedTrailId: PropTypes.number,
    priorityView: PropTypes.bool,
    selectedAutoTrailName: PropTypes.shape({}),
    globalNotes: PropTypes.shape({}),
    trackerIOValues: PropTypes.shape({}),
  }).isRequired,

  hydrants: PropTypes.shape({
    hydrants: PropTypes.shape({}),
    hydrantModels: PropTypes.shape({}),
    hydrantTrendFlow: PropTypes.shape({}),
    hydrantTrendWetbulb: PropTypes.shape({}),
    hydrant: PropTypes.shape({ id: PropTypes.number }),
    selectedHydrantId: PropTypes.number,
    globalNotes: PropTypes.shape({}),
  }).isRequired,

  weatherStations: PropTypes.shape({
    weatherStations: PropTypes.shape({}),
  }).isRequired,

  flowManagementSettings: PropTypes.shape({
    flowManagementSettings: PropTypes.shape({}),
  }).isRequired,

  guns: PropTypes.shape({
    guns: PropTypes.shape({}),
  }).isRequired,

  mobileGuns: PropTypes.shape({
    mobileGuns: PropTypes.shape({}),
    selectedMobileGun: PropTypes.shape({}),
    selectedMobileID: PropTypes.number,
  }).isRequired,

  gateways: PropTypes.shape({
    gateways: PropTypes.shape({}),
  }).isRequired,

  zones: PropTypes.shape({
    flowZones: PropTypes.shape({}),
    temperatureZones: PropTypes.shape({}),
    pressureZones: PropTypes.shape({}),
    zoneIOValues: PropTypes.shape({}),
  }).isRequired,

  map: PropTypes.shape({
    elevation: PropTypes.number,
  }).isRequired,

  settings: PropTypes.shape({
    settings: PropTypes.shape({
      useCelsius: PropTypes.bool,
      language: PropTypes.string,
      snowDepthMin: PropTypes.number,
      snowDepthMax: PropTypes.number,
      showSuggestedStage: PropTypes.bool,
    }),
  }).isRequired,

  trackerEquipment: PropTypes.shape({
    defenders: PropTypes.shape({}),
    trackerEquipment: PropTypes.shape({}),
    mobileEquipment: PropTypes.shape({}),
    autoHydrantError: PropTypes.number,
  }).isRequired,

  equipment: PropTypes.shape({
    valves: PropTypes.shape({}),
  }).isRequired,

  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
    ipAddresses: PropTypes.shape({
      plcId: PropTypes.string,
      ipAddress: PropTypes.string,
    }),
  }).isRequired,

  snowDepths: PropTypes.shape({
    snowDepths: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,

  presets: PropTypes.shape({
    presets: PropTypes.shape({}),
  }).isRequired,

  fetchElevation: PropTypes.func.isRequired,

  navbarClosed: PropTypes.bool.isRequired,

  fetchFeatures: PropTypes.func.isRequired,
  addHydrant: PropTypes.func.isRequired,
  deleteHydrant: PropTypes.func.isRequired,
  updateHydrant: PropTypes.func.isRequired,
  bulkUpdateHydrants: PropTypes.func.isRequired,
  bulkSetHydrantIOValues: PropTypes.func.isRequired,
  addTrail: PropTypes.func.isRequired,
  deleteTrail: PropTypes.func.isRequired,
  updateTrail: PropTypes.func.isRequired,
  fetchAllTrails: PropTypes.func.isRequired,
  fetchAllTrailsWithGeometry: PropTypes.func.isRequired,
  fetchAllAutoTrails: PropTypes.func.isRequired,
  fetchGlobalNotes: PropTypes.func.isRequired,
  fetchAllGateways: PropTypes.func.isRequired,
  addGateway: PropTypes.func.isRequired,
  fetchAllWeatherStations: PropTypes.func.isRequired,
  fetchAllGuns: PropTypes.func.isRequired,
  fetchAllMobileGuns: PropTypes.func.isRequired,
  fetchMobileGun: PropTypes.func.isRequired,
  deleteMobileGun: PropTypes.func.isRequired,
  addMobileGun: PropTypes.func.isRequired,
  editMobileGun: PropTypes.func.isRequired,
  fetchAllMobileEquipment: PropTypes.func.isRequired,
  fetchAllHydrantModels: PropTypes.func.isRequired,
  selectHydrant: PropTypes.func.isRequired,
  selectMobile: PropTypes.func.isRequired,
  selectTrail: PropTypes.func.isRequired,
  selectAutoTrail: PropTypes.func.isRequired,
  setHydrantIOValue: PropTypes.func.isRequired,
  setTrailState: PropTypes.func.isRequired,
  setPriorityView: PropTypes.func.isRequired,
  setAutoTrailIOValue: PropTypes.func.isRequired,
  setAutoTrailValue: PropTypes.func.isRequired,
  addNote: PropTypes.func.isRequired,
  updateNote: PropTypes.func.isRequired,
  fetchPressureZones: PropTypes.func.isRequired,
  fetchTemperatureZones: PropTypes.func.isRequired,
  fetchFlowZones: PropTypes.func.isRequired,
  fetchAllDefenders: PropTypes.func.isRequired,
  // fetchSnowDepths: PropTypes.func.isRequired,
  fetchSnowDepthsByDate: PropTypes.func.isRequired,
  fetchAllValves: PropTypes.func.isRequired,
  addAutoHydrant: PropTypes.func.isRequired,
  addSnofiHydrant: PropTypes.func.isRequired,
  deleteAutoHydrant: PropTypes.func.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  editPLC: PropTypes.func.isRequired,
  fetchAllTrackerEquipment: PropTypes.func.isRequired,
  fetchAllPresets: PropTypes.func.isRequired,
  addHydrantSensor: PropTypes.func.isRequired,
  deleteHydrantSensor: PropTypes.func.isRequired,
  fetchAllAutoTrailsIOValues: PropTypes.func.isRequired,
  fetchAllTrackerIOValues: PropTypes.func.isRequired,
  fetchAllIOValues: PropTypes.func.isRequired,
  fetchAllZoneIOValues: PropTypes.func.isRequired,
  addZoneIOValue: PropTypes.func.isRequired,
  deleteZoneIOValue: PropTypes.func.isRequired,
  editZoneIOValue: PropTypes.func.isRequired,
  trends: PropTypes.shape({
    IOValues: PropTypes.shape({}),
  }).isRequired,
  setIOValue: PropTypes.func.isRequired,
  fetchHydrant: PropTypes.func.isRequired,
  fetchHydrantTrendDataset: PropTypes.func.isRequired,
  fetchAllFlowManagementSettings: PropTypes.func.isRequired,
  editFMConfig: PropTypes.func.isRequired,
  updateSettings: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  hydrants: state.hydrants,
  trails: state.trails,
  presets: state.presets,
  trends: state.trends,
  guns: state.guns,
  mobileGuns: state.mobileGuns,
  gateways: state.gateways,
  weatherStations: state.weatherStations,
  flowManagementSettings: state.flowManagementSettings,
  navbarClosed: state.nav.navbarClosed,
  zones: state.zones,
  auth: state.auth,
  map: state.map,
  settings: state.settings,
  trackerEquipment: state.trackerEquipment,
  equipment: state.equipment,
  plcs: state.plcs,
  snowDepths: state.snowDepths,
});

export default connect(mapStateToProps, {
  fetchTemperatureZones,
  fetchPressureZones,
  fetchFlowZones,
  addHydrant,
  deleteHydrant,
  updateHydrant,
  bulkUpdateHydrants,
  bulkSetHydrantIOValues,
  addTrail,
  deleteTrail,
  updateTrail,
  fetchAllTrails,
  fetchAllTrailsWithGeometry,
  fetchAllAutoTrails,
  fetchAllGateways,
  addGateway,
  fetchFeatures,
  fetchGlobalNotes,
  fetchAllWeatherStations,
  selectTrail,
  selectHydrant,
  selectMobile,
  selectAutoTrail,
  setHydrantIOValue,
  setTrailState,
  setPriorityView,
  setAutoTrailIOValue,
  setAutoTrailValue,
  addNote,
  updateNote,
  fetchAllGuns,
  fetchAllMobileGuns,
  fetchMobileGun,
  deleteMobileGun,
  addMobileGun,
  editMobileGun,
  fetchAllMobileEquipment,
  fetchAllHydrantModels,
  fetchElevation,
  fetchAllDefenders,
  // fetchSnowDepths,
  fetchSnowDepthsByDate,
  fetchAllValves,
  addAutoHydrant,
  deleteAutoHydrant,
  addSnofiHydrant,
  fetchPLCs,
  editPLC,
  fetchAllTrackerEquipment,
  fetchAllPresets,
  addHydrantSensor,
  deleteHydrantSensor,
  fetchAllAutoTrailsIOValues,
  fetchAllTrackerIOValues,
  fetchAllIOValues,
  fetchAllZoneIOValues,
  addZoneIOValue,
  deleteZoneIOValue,
  editZoneIOValue,
  setIOValue,
  fetchHydrant,
  fetchHydrantTrendDataset,
  updateSettings,
  fetchAllFlowManagementSettings,
  editFMConfig,
})(TrackerScreen);
