// Settings Screen v0.0.2
// Created By Trevor Colby
// IMPORTANT: This file should be identical for every mountain
// any configuration should occur in './Configuration.js'
import React, {
  useContext, useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CancelToken } from 'axios';
import { css, useTheme } from '@emotion/react';
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from 'react-tabs';

import { Button } from 'isno/lib/components/controls';
import { TextAreaInputSmall } from 'isno/lib/components/tracker';
import Select from 'react-select';
import 'react-tabs/style/react-tabs.css';
import { LanguageContext, Languages } from 'isno/lib/languages/LanguageContext';
import {
  GunModels, AutoTrails, Zones, Gateways, Users, QualityPresets, MobileEquipment, FlowManagement,
} from 'isno/lib/components/settings';
import { LoadingIndicator, Version } from 'isno/lib/components/displays';
import { isEqual, startCase } from 'lodash';
import {
  reset2FA,
  setIsDisabled,
  setEmail,
  fetchAllUsers,
  addUser,
  editUser,
  updateSettings,
  getApiVersion,
  fetchAllGuns,
  fetchAllAutoTrails,
  fetchAllAutoTrailsIOValues,
  fetchAllTrails,
  fetchPLCs,
  fetchAllTrackerEquipment,
  fetchAllGateways,
  addGateway,
  deleteGateway,
  fetchNodes,
  addNode,
  deleteNode,
  fetchAllPresets,
  addPreset,
  deletePreset,
  updatePreset,
  updateGunIsAvailable,
  updateGun,
  updateGunModel,
  addGun,
  addGunModel,
  deleteGunModel,
  setAutoTrailValue,
  setAutoTrailIOValue,
  fetchTemperatureZones,
  fetchFlowZones,
  fetchPressureZones,
  addZone,
  deleteZone,
  editZone,
  editZoneIOValue,
  fetchAllZoneIOValues,
  fetchAllTrackerIOValues,
  fetchAllIOValues,
  fetchAllWeatherStations,
  addMobileEquipment,
  deleteMobileEquipment,
  fetchLocations,
  fetchAllMobileEquipment,
  fetchAllFlowManagementSettings,
  addFMConfig,
  deleteFMConfig,
  editFMConfig,
} from '../actions';
import isnoLogo from '../static/isnoControlsGrey.png';

import configuration from './Configuration';

let languageOptions;

// Configuration values pulled in from the './Configuration.js' file
const {
  mapEditRole,
  userManagerRole,
} = configuration;

function SettingsScreen(props) {
  const { language, setLanguage } = useContext(LanguageContext);
  const theme = useTheme();
  const styles = stylesFromTheme(theme);
  const [currentTab, setCurrentTab] = useState(0);
  const [initRequestsComplete, setInitRequestsComplete] = useState(false);
  const [ipAddressLookup, setIpAddressLookup] = useState({});
  const [renderLoader, setRenderLoader] = useState(true);
  const [languageLoading, setLanguageLoading] = useState(false);
  const [celsiusLoading, setCelsiusLoading] = useState(false);
  const [suggestedStageLoading, setSuggestedStageLoading] = useState(false);
  const [themeLoading, setThemeLoading] = useState(false);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [resetName, setResetName] = useState('');
  const [nodesGateID, setNodesGateID] = useState(null);
  const [resetID, setResetID] = useState(null);
  const [resetType, setResetType] = useState(null);
  const [tabStyle, setTabStyle] = useState(false);
  // add flag
  const flagOptions = {
    english: <link
      className="fi fi-us"
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.0.0/css/flag-icons.min.css"
    />,
    french: <link
      className="fi fi-fr"
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.0.0/css/flag-icons.min.css"
    />,
  };
  const [currentLanguage, setCurrentLanguage] = useState({
    value: props.settings?.settings?.language,
    label: props.settings?.settings?.language && (
    <div>
      {flagOptions[props.settings?.settings?.language]}
      {' '}
      {Languages[props.settings?.settings?.language].fileName.charAt(0).toUpperCase() + Languages[props.settings?.settings?.language].fileName.slice(1)}
    </div>
    ),
  });
  const [useCelsius, setUseCelsius] = useState({
    value: props.settings?.settings?.useCelsius,
    label: props.settings?.settings?.useCelsius ? language.celsius : language.fahrenheit,
  });
  const [showSuggestedStage, setshowSuggestedStage] = useState({
    value: props.settings?.settings?.showSuggestedStage,
    label: props.settings?.settings?.showSuggestedStage ? language.show : language.hide,
  });
  const [prevLanguage, setPrevLanguage] = useState(null);
  const currentTabRef = useRef(currentTab);
  const nodesGateIDRef = useRef(nodesGateID);
  const cancelTokenSourceRef = useRef(null);
  // const [userRoles, setUserRoles] = useState(0);

  let timedIntervalId, loaderTimeoutId;
  const timedInterval = () => {
    timedIntervalId = setTimeout(() => {
      // Pass the reference to our current cancelToken to allow the requests to be aborted
      // in the event that the component is unmounted before they have returned
      const curCancelToken = cancelTokenSourceRef.current.token;
      if (currentTabRef.current === 1) {
        props.fetchAllGuns(curCancelToken);
      } else if (currentTabRef.current === 2) {
        props.fetchAllMobileEquipment(curCancelToken);
      } else if (currentTabRef.current === 3) {
        props.fetchAllFlowManagementSettings(curCancelToken);
        props.fetchFlowZones(curCancelToken);
        props.fetchLocations(curCancelToken);
      } else if (currentTabRef.current === 4) {
        props.fetchAllPresets(curCancelToken);
      } else if (currentTabRef.current === 5) {
        props.fetchAllTrails(curCancelToken);
        props.fetchAllAutoTrails(curCancelToken);
        props.fetchAllAutoTrailsIOValues(curCancelToken);
      } else if (currentTabRef.current === 6) {
        props.fetchTemperatureZones(curCancelToken);
        props.fetchPressureZones(curCancelToken);
        props.fetchFlowZones(curCancelToken);
        props.fetchAllZoneIOValues(curCancelToken);
        props.fetchAllIOValues(curCancelToken);
        props.fetchAllTrackerIOValues(curCancelToken);
        props.fetchAllWeatherStations(curCancelToken);
      } else if (currentTabRef.current === 7) {
        props.fetchAllGateways(curCancelToken);
        props.fetchAllTrackerEquipment(curCancelToken);
        props.fetchPLCs(curCancelToken);
        if (nodesGateIDRef.current) {
          props.fetchNodes(nodesGateIDRef.current, curCancelToken);
        }
      } else if (currentTabRef.current === 8) {
        props.fetchAllUsers(curCancelToken);
      }
      timedIntervalId = setTimeout(timedInterval, 4000);
    }, 4000);
  };

  // To start we fetch all of the data for all of the tabs.
  // After that we only fetch data for the current tab
  const fetchAllInitialData = async () => {
    // Pass the reference to our current cancelToken to allow the requests to be aborted
    // in the event that the component is unmounted before they have returned
    const curCancelToken = cancelTokenSourceRef.current.token;
    const requests = [
      props.fetchAllGuns(curCancelToken),
      props.getApiVersion(curCancelToken),
      props.fetchAllMobileEquipment(curCancelToken),
      props.fetchAllFlowManagementSettings(curCancelToken),
      props.fetchFlowZones(curCancelToken),
      props.fetchLocations(curCancelToken),
      props.fetchAllPresets(curCancelToken),
      props.fetchAllGateways(curCancelToken),
      props.fetchAllTrails(curCancelToken),
      props.fetchAllAutoTrails(curCancelToken),
      props.fetchAllAutoTrailsIOValues(curCancelToken),
      props.fetchTemperatureZones(curCancelToken),
      props.fetchAllWeatherStations(curCancelToken),
      props.fetchPressureZones(curCancelToken),
      props.fetchFlowZones(curCancelToken),
      props.fetchAllZoneIOValues(curCancelToken),
      props.fetchAllIOValues(curCancelToken),
      props.fetchAllTrackerIOValues(curCancelToken),
      props.fetchAllUsers(curCancelToken),
      props.fetchAllTrackerEquipment(curCancelToken),
      props.fetchPLCs(curCancelToken),
      // props.fetchQualityPresets(curCancelToken),
    ];
    const responses = await Promise.all(requests);
    if (responses.filter((r) => (r === 'canceled')).length === 0) {
      // Only set our state if none of our requests were canceled due to the component being unmounted
      setInitRequestsComplete(true);
    }
  };

  const tabChangeAction = () => {
    const tabs = document.getElementsByClassName('react-tabs__tab');
    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i]) {
        tabs[i].style.color = theme.textColor;
        tabs[i].style.backgroundColor = '';
        tabs[i].style.borderRadius = '';
      }
    }
    const selectedTabs = document.getElementsByClassName('react-tabs__tab--selected');
    for (let i = 0; i < tabs.length; i++) {
      if (selectedTabs[i]) {
        selectedTabs[i].style.color = theme.textColor;
        selectedTabs[i].style.backgroundColor = theme.gridButtonBackgroundColor;
        selectedTabs[i].style.borderRadius = '10px 10px 0px 0px';
      }
    }
  };

  useEffect(() => {
    tabChangeAction();
  }, []);

  useEffect(() => {
    if (currentTab !== 4) {
      setNodesGateID(null);
    }
  }, [currentTab, currentTabRef]);

  useEffect(() => {
    if (!themeLoading) {
      setTabStyle(!tabStyle);
    }
  }, [themeLoading]);

  useEffect(() => {
    tabChangeAction();
  }, [currentTab, renderLoader, tabStyle]);

  // add flag
  useEffect(() => {
    const tempLang = {
      value: props.settings?.settings?.language,
      label: props.settings?.settings?.language && (
        <div>
          {flagOptions[props.settings?.settings?.language]}
          {' '}
          {Languages[props.settings?.settings?.language].fileName.charAt(0).toUpperCase() + Languages[props.settings?.settings?.language].fileName.slice(1)}
        </div>
      ),
    };
    if (!isEqual(tempLang, currentLanguage)) {
      setCurrentLanguage(tempLang);
    }
  }, [props.settings?.settings?.language]);

  useEffect(() => {
    const tempUseCelsius = {
      value: props.settings?.settings?.useCelsius,
      label: props.settings?.settings?.useCelsius ? language.celsius : language.fahrenheit,
    };
    if (!isEqual(tempUseCelsius, useCelsius)) {
      setUseCelsius(tempUseCelsius);
    }
  }, [props.settings?.settings?.useCelsius]);

  useEffect(() => {
    const tempShowSuggestedStage = {
      value: props.settings?.settings?.showSuggestedStage,
      label: props.settings?.settings?.showSuggestedStage ? language.show : language.hide,
    };
    if (!isEqual(tempShowSuggestedStage, showSuggestedStage)) {
      setshowSuggestedStage(tempShowSuggestedStage);
    }
  }, [props.settings?.settings?.showSuggestedStage]);

  useEffect(() => {
    // Axios cancel token used to abort async requests prematurely in the event
    // that the component is unmounted. This prevents memory leaks.
    cancelTokenSourceRef.current = CancelToken.source();

    if (props.auth?.user?.roles && props.auth?.user?.roles.includes(mapEditRole)) {
      // Async function to asynchronously request all of our data but then wait for
      // all of the responses before setting 'initRequestsComplete' to true. We use
      // this to make sure we have all of the neccesary data before completing our page load.
      // fetchAllInitialData(source.token);
      // timedInterval(source.token);
      fetchAllInitialData();
      timedInterval();
    } else {
      // If the current user doesn't have access to advanced roles
      // then we don't need to make further requests
      setInitRequestsComplete(true);
    }

    // Cleanup function to cancel async requests that haven't returned yet and would otherwise
    // cause memory leaks. Also clears timeout to prevent memory leaks.
    return () => {
      cancelTokenSourceRef.current.cancel('canceled');
      cancelTokenSourceRef.current = CancelToken.source();
      clearTimeout(timedIntervalId);
    };
  }, []);

  useEffect(() => {
    if (props.trackerEquipment?.trackerEquipment) {
      const tempIpAddressLookup = {};
      Object.values(props.trackerEquipment?.trackerEquipment).forEach((equipment) => {
        // Use the plcId to create an object whose key/value pairs are the ipAddresses / equipment names.
        tempIpAddressLookup[props.plcs.ipAddresses?.[equipment?.plcId]?.ipAddress] = equipment?.name;
      });
      setIpAddressLookup(tempIpAddressLookup);
    }
  }, [props.trackerEquipment?.trackerEquipment]);

  useEffect(() => {
    if (props.settings?.settings?.language) {
      if (prevLanguage !== props.settings?.settings?.language) {
        setLanguage(Languages[props.settings?.settings?.language]);
        setPrevLanguage(props.settings?.settings?.language);
      }
    }
  }, [props.settings?.settings?.language]);

  useEffect(() => {
    currentTabRef.current = currentTab;
  }, [currentTab]);

  useEffect(() => {
    nodesGateIDRef.current = nodesGateID;
  }, [nodesGateID]);

  const temperatureOptions = [
    { value: false, label: language.fahrenheit },
    { value: true, label: language.celsius },
  ];

  const suggestedStageOptions = [
    { value: false, label: language.hide },
    { value: true, label: language.show },
  ];

  // const waterOptions = [
  //   { value: 'gallons', label: language.gallons },
  //   { value: 'liters', label: language.liters },
  // ];

  const themeOptions = [
    { value: 'LightTheme', label: language.light },
    { value: 'DarkTheme', label: language.dark },
    // { value: 'OriginalTheme', label: language.original },
  ];

  // const autoChooseOptions = [
  //   { value: 'no', label: language.no },
  //   { value: 'yes', label: language.yes },
  // ];

  // const defaultAutoChoose = { value: 'no', label: language.no };

  const generateLanguageOptions = () => {
    const temp = [];
    Object.keys(Languages).forEach((item) => {
      // temp.push({ value: item, label: `${startCase(item)}` });
      temp.push({
        value: item,
        label:
  <div>
    {flagOptions[item]}
    {' '}
    {Languages[item].fileName.charAt(0).toUpperCase() + Languages[item].fileName.slice(1)}
  </div>,
      });
    });
    return temp;
  };

  if (!languageOptions) {
    languageOptions = generateLanguageOptions();
  }

  useEffect(() => {
    if (initRequestsComplete) {
      loaderTimeoutId = setTimeout(() => {
        if (cancelTokenSourceRef.current.token.message !== 'canceled') {
          setRenderLoader(false);
        }
      }, 2000);
    }
    // Cleanup function to prevent memory leaks if component is unmounted before setTimeout finishes
    return () => {
      clearTimeout(loaderTimeoutId);
    };
  }, [initRequestsComplete]);

  // useEffect(() => {
  //   const handleWindowResize = () => {
  //     console.log(document.getElementById('tabList')?.offsetHeight);
  //   };

  //   window.addEventListener('resize', handleWindowResize);

  //   return () => {
  //     window.removeEventListener('resize', handleWindowResize);
  //   };
  // });

  // useEffect(() => {
  //   console.log(document.getElementById('tabList')?.offsetHeight);
  // }, [props.navbarClosed]);

  return (
    <div css={[css`width: 100%; max-height: 100vh; overflow: hidden; position: relative;`, styles.mobileSettings]}>
      {renderLoader
      && (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
          (!initRequestsComplete) ? css`opacity: 1;` : css`opacity: 0;`,
        ]}
      >
        <LoadingIndicator visible={!initRequestsComplete} zIndex={4} />
      </div>
      )}
      {props.settings?.settings
      && (
      <div css={styles.background}>
        <Tabs
          defaultIndex={0}
          css={css`width: 100%;`}
          onSelect={(index) => {
            let tempIndex = index;
            if (index === 0 || (props.auth?.user?.roles && props.auth?.user?.roles.includes(mapEditRole))) {
              // conditionally bump index value if no mobileTracking or flowManagement
              if ((index > 1) && !props.auth?.features?.MobileTracking) {
                tempIndex += 1;
              }
              if ((tempIndex > 2) && !props.auth?.features?.FlowManagement) {
                tempIndex += 1;
              }
              setCurrentTab(tempIndex);
            }
          }}
          focusTabOnClick={false}
        >
          <TabList id="tabList">
            {/* add styling to tab-list: "overflow-x: scroll; height: 42px; display: flex; scrollbar-width: none;" */}
            {/* add styling to tab: "display: flex; width: fit-content; height: 39px; white-space: nowrap;" */}
            <Tab><h2>{language.general}</h2></Tab>
            {props.auth?.user?.roles && props.auth?.user?.roles.includes(mapEditRole)
              && <Tab><h2>{language.gunModels}</h2></Tab>}
            {props.auth?.user?.roles && props.auth?.user?.roles.includes(mapEditRole) && props.auth?.features?.MobileTracking
              && <Tab><h2>{language.mobileEquipment}</h2></Tab>}
            {props.auth?.user?.roles && props.auth?.user?.roles.includes(mapEditRole) && props.auth?.features?.FlowManagement // SCOTT change feature flag to flowManagement
              && <Tab><h2>{language.flowManagement}</h2></Tab>}
            {props.auth?.user?.roles && props.auth?.user?.roles.includes(mapEditRole)
              && <Tab><h2>{language.quality}</h2></Tab>}
            {props.auth?.user?.roles && props.auth?.user?.roles.includes(mapEditRole)
              && <Tab><h2>{language.autoTrailConfig}</h2></Tab>}
            {props.auth?.user?.roles && props.auth?.user?.roles.includes(mapEditRole)
            && <Tab><h2>{language.zones}</h2></Tab>}
            {props.auth?.user?.roles && props.auth?.user?.roles.includes(mapEditRole)
            && <Tab><h2>{language.gateway}s</h2></Tab>}
            {props.auth?.user?.roles && props.auth?.user?.roles.includes(userManagerRole)
            && <Tab><h2>{language.users}</h2></Tab>}
            {/* <Tab><h2>KLiK</h2></Tab>
            <Tab><h2>Tracker</h2></Tab> */}
          </TabList>
          <TabPanel>
            <img alt="iSno" src={isnoLogo} css={[styles.logoMountain]} />
            <div css={styles.versionWrapper}>
              <div>
                <Version settings color={theme.textColorNavbar} />
              </div>
              <div>
                iSno-Api {props.settings.apiVersion}
              </div>
            </div>
            <div css={styles.selectContainer}>
              <LoadingIndicator visible={languageLoading} height="28px" />
              <div css={styles.selectLabel}>{language.language}: </div>
              <Select
                value={currentLanguage}
                  // {
                  //   value: props.settings?.settings?.language,
                  //   label: `${props.settings?.settings?.language.charAt(0).toUpperCase() + props.settings?.settings?.language.slice(1)}`,
                  // }
                onChange={async (selection) => {
                  setLanguageLoading(true);
                  await props.updateSettings({
                    userId: props.settings?.settings?.userId,
                    useCelsius: props.settings?.settings?.useCelsius,
                    language: selection.value,
                    showSuggestedStage: props.settings?.settings?.showSuggestedStage,
                  });
                  setLanguageLoading(false);
                }}
                options={languageOptions}
                styles={theme.settingsSelectStyles}
                menuPortalTarget={document.getElementById('portalPlaceholder')}
                menuPosition="absolute"
                menuPlacement="bottom"
                theme={theme.selectTheme}
              />
            </div>
            <div css={styles.selectContainer}>
              <LoadingIndicator visible={celsiusLoading} height="28px" />
              <div css={styles.selectLabel}>{language.temperature}: </div>
              <Select
                value={useCelsius}
                onChange={async (selection) => {
                  setCelsiusLoading(true);
                  await props.updateSettings({
                    userId: props.settings?.settings?.userId,
                    useCelsius: selection.value,
                    showSuggestedStage: props.settings?.settings?.showSuggestedStage,
                  });
                  setCelsiusLoading(false);
                }}
                options={temperatureOptions}
                styles={theme.settingsSelectStyles}
                menuPortalTarget={document.getElementById('portalPlaceholder')}
                menuPosition="absolute"
                menuPlacement="bottom"
                theme={theme.selectTheme}
              />
            </div>
            <div css={styles.selectContainer}>
              <LoadingIndicator visible={suggestedStageLoading} height="28px" />
              <div css={styles.selectLabel}>{language.suggestedStage}: </div>
              <Select
                value={{
                  value: props.settings?.settings?.showSuggestedStage,
                  label: suggestedStageOptions.find((option) => (option.value === props.settings?.settings?.showSuggestedStage))?.label,
                }}
                onChange={async (selection) => {
                  setSuggestedStageLoading(true);
                  await props.updateSettings({
                    userId: props.settings?.settings?.userId,
                    useCelsius: props.settings?.settings?.useCelsius,
                    showSuggestedStage: selection.value,
                  });
                  setSuggestedStageLoading(false);
                }}
                options={suggestedStageOptions}
                styles={theme.settingsSelectStyles}
                menuPortalTarget={document.getElementById('portalPlaceholder')}
                menuPosition="absolute"
                menuPlacement="bottom"
                theme={theme.selectTheme}
              />
            </div>
            {/* <div css={styles.selectContainer}>
              <div css={styles.selectLabel}>{language.water}: </div>
              <Select
                value={{ value: 'gallons', label: language.gallons }}
                options={waterOptions}
                styles={settingsSelectStyles}
              />
            </div> */}
            <div css={styles.selectContainer}>
              <LoadingIndicator visible={themeLoading} height="28px" />
              <div css={styles.selectLabel}>{language.theme}: </div>
              <Select
                value={
                  {
                    value: props.settings?.settings?.theme,
                    label: themeOptions.find((option) => (option.value === props.settings?.settings?.theme))?.label,
                  }
                }
                onChange={async (selection) => {
                  setThemeLoading(true);
                  await props.updateSettings({
                    userId: props.settings?.settings?.userId,
                    useCelsius: props.settings?.settings?.useCelsius,
                    showSuggestedStage: props.settings?.settings?.showSuggestedStage,
                    theme: selection.value,
                  });
                  setThemeLoading(false);
                }}
                options={themeOptions}
                styles={theme.settingsSelectStyles}
                menuPortalTarget={document.getElementById('portalPlaceholder')}
                menuPosition="absolute"
                menuPlacement="bottom"
                theme={theme.selectTheme}
              />
            </div>
            <div css={styles.selectContainer}>
              <div css={styles.selectLabel}>Email: </div>
              <TextAreaInputSmall
                updateText={(text) => {
                  props.setEmail(props.auth?.user?.id, text);
                }}
                text={props?.auth?.user?.email || ' '}
                textColor={theme.textColor}
                styles="margin: 0px;
                margin-bottom: -3px;
                width: 177px;
                height: 28px;
                border: 1px solid #9e9e9e;
                border-radius: 4px;
                padding: 4px 0px 0px 8px;
                white-space: nowrap;
                ::-webkit-scrollbar{
                  display: none;
                }"
                backgroundColor={theme.emailBackgroundColor}
              />
            </div>
            {/* <div css={css`display: flex; margin-left: 202px;`}>
              <Button
                dot={false}
                outline
                textColor={theme.textColor}
                borderColor="#9e9e9e"
                backgroundColor={theme.backgroundColor}
                // onClick={async () => {
                //   await props.reset2FA(props.settings?.settings?.userId);
                // }}
                onClick={() => {
                  setResetID(props.settings?.settings?.userId);
                  setResetName('Your');
                }}
              >
                <div css={css`display: flex;`}>
                  <p>Reset 2FA</p>
                </div>
              </Button>
            </div> */}
          </TabPanel>
          {props.auth?.user?.roles && props.auth?.user?.roles.includes(mapEditRole) && (
          <TabPanel>
            <div css={[styles.tabPanel, css`height: calc(100vh - ${document.getElementById('tabList')?.offsetHeight}px - 30px);`]}>
              <GunModels
                // gunModels={props.settings?.gunModels ? Object.values(props.settings?.gunModels) : undefined}
                guns={props.guns?.guns ? Object.values(props.guns?.guns) : []}
                updateGunIsAvailable={props.updateGunIsAvailable}
                updateGun={props.updateGun}
                updateGunModel={props.updateGunModel}
                addGun={props.addGun}
                addGunModel={props.addGunModel}
                deleteGunModel={props.deleteGunModel}
              />
            </div>
          </TabPanel>
          )}
          {props.auth?.user?.roles && props.auth?.user?.roles.includes(mapEditRole) && props.auth?.features?.MobileTracking && (
          <TabPanel>
            <div css={[styles.tabPanel, css`height: calc(100vh - ${document.getElementById('tabList')?.offsetHeight}px - 30px);`]}>
              <MobileEquipment
                equipment={props?.trackerEquipment?.mobileEquipment}
                ipAddressLookup={ipAddressLookup}
                setDeleteID={setResetID}
                setDeleteName={setResetName}
                setResetType={setResetType}
                addMobileEquipment={props.addMobileEquipment}
              />
            </div>
          </TabPanel>
          )}
          {props.auth?.user?.roles && props.auth?.user?.roles.includes(mapEditRole) && props.auth?.features?.FlowManagement && ( // SCOTT change feature flow to flow management
          <TabPanel>
            <div css={[styles.zonesTPanel, css`height: calc(100vh - ${document.getElementById('tabList')?.offsetHeight}px - 60px);`]}>
              <FlowManagement
                // celsius={useCelsius.value}
                flowZones={props.zones?.flowZones}
                locations={props.locations}
                configs={props.flowManagementSettings?.flowManagementSettings ? Object.values(props.flowManagementSettings?.flowManagementSettings) : []}
                addConfig={props.addFMConfig}
                updateConfig={props.editFMConfig}
                setDeleteID={setResetID}
                setDeleteName={setResetName}
                setResetType={setResetType}
              />
            </div>
          </TabPanel>
          )}
          {props.auth?.user?.roles && props.auth?.user?.roles.includes(mapEditRole) && (
          <TabPanel>
            <div css={[styles.zonesTPanel, css`height: calc(100vh - ${document.getElementById('tabList')?.offsetHeight}px - 60px);`]}>
              <QualityPresets
                celsius={useCelsius.value}
                presets={props.presets?.presets ? Object.values(props.presets?.presets) : []}
                addPreset={props.addPreset}
                deletePreset={props.deletePreset}
                updatePreset={props.updatePreset}
              />
            </div>
          </TabPanel>
          )}
          {props.auth?.user?.roles && props.auth?.user?.roles.includes(mapEditRole) && (
          <TabPanel>
            <div css={[styles.tabPanel, css`height: calc(100vh - ${document.getElementById('tabList')?.offsetHeight}px - 30px);`]}>
              <AutoTrails
                autotrails={props.trails?.autotrails}
                weatherStations={props.weatherStations?.weatherStations}
                trails={props.trails?.trails ? Object.values(props.trails?.trails) : []}
                autotrailsIOValues={props.trails?.autotrailsiovalues ? Object.values(props.trails?.autotrailsiovalues) : []}
                setAutoTrailValue={props.setAutoTrailValue}
                setAutoTrailIOValue={props.setAutoTrailIOValue}
                defenders={props.trackerEquipment.defenders}
                useCelsius={useCelsius.value}
              />
            </div>
          </TabPanel>
          )}
          {props.auth?.user?.roles && props.auth?.user?.roles.includes(mapEditRole) && (
          <TabPanel>
            <div css={[styles.zonesTPanel, css`height: calc(100vh - ${document.getElementById('tabList')?.offsetHeight}px - 60px);`]}>
              <Zones
                zones={props.zones}
                tabChangeAction={tabChangeAction}
                trackerIOValues={props.trails?.trackerIOValues || {}}
                weatherStations={props.weatherStations?.weatherStations || {}}
                plantIOValues={props.trends?.IOValues || {}}
                addZone={props.addZone}
                celsius={props.settings?.settings?.useCelsius}
                deleteZone={props.deleteZone}
                editZone={props.editZone}
                editZoneIOValue={props.editZoneIOValue}
              />
            </div>
          </TabPanel>
          )}
          {props.auth?.user?.roles && props.auth?.user?.roles.includes(mapEditRole) && (
          <TabPanel>
            <div css={[styles.zonesTPanel, css`height: calc(100vh - ${document.getElementById('tabList')?.offsetHeight}px - 60px);`]}>
              <Gateways
                gateways={props.gateways.gateways}
                ipAddressLookup={ipAddressLookup}
                nodes={props.gateways.nodes}
                nodesGateID={nodesGateID}
                fetchNodes={async (id) => {
                  setNodesGateID(id);
                  await props.fetchNodes(id);
                }}
                addGateway={props.addGateway}
                addNode={props.addNode}
                setDeleteID={setResetID}
                setDeleteName={setResetName}
                setResetType={setResetType}
              />
            </div>
          </TabPanel>
          )}
          {props.auth?.user?.roles && props.auth?.user?.roles.includes(userManagerRole) && (
          <TabPanel>
            <div css={[styles.zonesTPanel, css`height: calc(100vh - ${document.getElementById('tabList')?.offsetHeight}px - 60px);`]}>
              <Users
                users={props.auth.users}
                // tabChangeAction={tabChangeAction}
                reset2FA={props.reset2FA}
                setIsDisabled={props.setIsDisabled}
                setResetID={setResetID}
                setResetName={setResetName}
                setResetType={setResetType}
                addUser={props.addUser}
                // deleteZone={props.deleteZone}
                editUser={props.editUser}
                // editZoneIOValue={props.editZoneIOValue}
              />
            </div>
          </TabPanel>
          )}
        </Tabs>
      </div>
      )}
      {/* this should be a separate component so it can be reused */}
      {resetID && (
        <div css={styles.confirmWrapper}>
          <div css={[styles.confirmContainer]}>
            <p css={css`margin-top: 10px;`}>{language.confirmAction}</p>
            <div css={css`padding: 10px; font-size: 1.3em;`}>
              {(resetType === 1 || resetType === 2 || resetType === 3 || resetType === 5) && (
              <p>{language.delete} {resetName}</p>
              )}
              {resetType === 4 && (
              <p>{language.reset} {language.twoFA} {resetName}</p>
              )}
            </div>
            <div css={css`display: flex; flex-direction: row;`}>
              <Button
                dot={false}
                outline
                width="58px"
                textColor={theme.textColor}
                borderColor="#9e9e9e"
                backgroundColor={theme.buttonBackgroundColor}
                // onClick={async () => {
                //   await props.reset2FA(props.settings?.settings?.userId);
                // }}
                onClick={() => {
                  setResetID(null);
                  setResetName('');
                  setResetType(null);
                }}
              >
                <div css={css`display: flex;`}>
                  <p>{language.cancel}</p>
                </div>
              </Button>
              <Button
                dot={false}
                outline
                width="58px"
                textColor={theme.textColor}
                borderColor="#9e9e9e"
                backgroundColor={theme.buttonBackgroundColor}
                onClick={async () => {
                  if (resetType === 1) {
                    await props.deleteMobileEquipment(resetID);
                  } else if (resetType === 2) {
                    await props.deleteGateway(resetID);
                  } else if (resetType === 3) {
                    await props.deleteNode(resetID, resetName);
                  } else if (resetType === 4) {
                    await props.reset2FA(resetID);
                  } else if (resetType === 5) {
                    await props.deleteFMConfig(resetID);
                  }
                  setResetID(null);
                  setResetName('');
                  setResetType(null);
                }}
              >
                <div css={css`display: flex;`}>
                  <p>{language.yes}</p>
                </div>
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const stylesFromTheme = (theme) => {
  return {
    background: css`
      padding: 10px;
      display: flex;
      width: 100%;
      height: 100%;
      color: ${theme.textColor};
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        margin-left: 16px;
      }
    `,
    tabContainer: css`
    :global .ReactTabs__Tab.ReactTabs__Tab--selected {
      background: ;
    }
    `,

    mobileSettings: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        height: 100vh;
        height: 100dvh;
      }
    `,
    input: css`
      border: 0;
    `,
    qualitySettingsBox: css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-style: solid;
      border-color: black;
      border-width: thin;
      border-radius: 3px;
      padding: 2px;
      margin-right: 5px;
      margin-bottom: 5px;
    `,
    upperInputContainer: css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 4px;
    min-height: 36px;
  `,
    confirmContainer: css`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    height: 135px;
    margin: 4px;
    cursor: auto;
    background-color: ${theme.containerBackgroundColor};
    border: 1px solid ${theme.borderColor};
    border-radius: 4px;
  `,
    stageInput: css`
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 3px;
      margin-right: 3px;
      padding: 5px;
      border-style: solid;
      border-color: grey;
      border-width: thin;
      border-radius: 3px;
    `,
    overrideInput: css`
      display: flex;
      flex-direction: column;
      // justify-content: space-around;
      margin-left: 3px;
      margin-right: 3px;
      padding: 7px;
      width: 90px;
      border-style: solid;
      border-color: grey;
      border-width: thin;
      border-radius: 3px;
    `,
    confirmWrapper: css`
    backdrop-filter: blur(1px);
    width: 100%; 
    height: 100%;
    margin-left: 8px;
    padding: 250px 0px 0px calc(50% - 175px);
    position: absolute;
    top: 0px;
    z-index: 999;
    cursor: wait;
    @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
      padding: 250px 0px 0px calc(50% - 90px);
    }
    `,
    inputField: css`
      width: 50px;
      margin-bottom: 4px;
      margin-right: 2px;
    `,
    qualityTableContainer: css`
      display: flex;
      flex-direction: row;
    `,
    buttonActive: css`
      display: flex;
      padding: 8px;
      background-color: #457DC1;
      cursor: pointer;
      border-radius: 3px;
      color: white;
      margin-bottom: 5px;
      margin-top: 5px;
      margin-left: 6px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    `,
    logoMountain: css`
      height: auto;
      width: 320px;
    `,
    versionWrapper: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 8px 0px 0px 4px;
      width: 316px;
      font-weight: 700;
    `,
    settingsVersion: css`
      flex:1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-left: 10px;
  `,
    submitButton: css`
      background-color:#7B7B7B;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      color: white;
      margin-top: 4px;
      padding: 3px 5px 3px 5px;
      border-color: black;
      border-style: solid;
      border-width: 1px;
      margin-left: 6px;
      margin-top: 3px;
      font-size: 14px;
      font-family: Open Sans;
      cursor: pointer;
      border-radius: 4px;
      // width: 90px;
      height: 35px;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    `,
    resetButton: css`
      display: flex;
      padding: 8px;
      background-color: #A5A5A5;
      cursor: pointer;
      border-radius: 3px;
      color: white;
      margin-bottom: 5px;
      margin-top: 5px;
      margin-left: 6px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    `,
    buttonGroup: css`
      display: flex;
      flex-direction: row;
      margin-left: 4px;
      margin-right: 4px;
      align-items: center;
    `,
    selectLabel: css`
      margin-left: 4px;
      margin-right: 4px;
      width: 135px;
    `,
    selectContainer: css`
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: fit-content;
      margin-top: 10px;
      margin-bottom: 5px;
      border-radius: 4px;
      overflow: hidden;
      position: relative;
    `,
    qualityHeader: css`
      margin-bottom: 3px;
      font-style: bold;
    `,
    tabPanel: css`
      display: flex;
      margin-right: 5px;
      margin-top: 5px;
      flex-wrap: wrap;
      width: 100%;
      height: calc(100vh - 68px);
      overflow: scroll;
      scrollbar-width: none;
    `,
    zonesTabPanel: css`
      display: flex;
      margin-right: 5px;
      margin-top: 5px;
      flex-wrap: wrap;
      max-width: 100%;
      height: calc(100vh - 131px);
    `,
    btnGroupHeader: css`
      display: flex;
      align-self: center;
      justify-content: center;
      flex-direction: column;
    `,
    btnGroupTitle: css`
      display: flex;
      align-self: center;
    `,
    trendsStat: css`
      display: flex;
      align-items: left;
      flex: 1;
      flex-wrap: wrap;
      margin-left: 3px;
      margin-right: 3px;
      margin-top: 3px;
      margin-bottom: 3px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        margin-left: 20px;
      }
    `,
    loaderContainer: css`
      position: absolute;
      top: 60;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 4;
    `,
    textContainer: css`
      position: absolute;
      top: 60;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 4;
    `,
    spinningLoader: css`
      width: 50px;
      height: 50px;
      border: 5px solid rgba(10, 188, 201, 0.4);
      border-left-color: rgb(10, 188, 201);
      border-radius: 50%;
      background: transparent;
      animation-name: rotate-s-loader;
      animation-iteration-count: infinite;
      animation-duration: 1s;
      animation-timing-function: linear;
      position: relative;
      @keyframes rotate-s-loader {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(360deg);
        }
      }
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        // padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s;
    `,
  };
};

SettingsScreen.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      roles: PropTypes.string,
      email: PropTypes.string,
      id: PropTypes.number,
    }),
    users: PropTypes.shape({}),
    features: PropTypes.shape({
      MobileTracking: PropTypes.bool,
      FlowManagement: PropTypes.bool,
    }),
    authenticated: PropTypes.bool,
    need2fa: PropTypes.bool,
    error: PropTypes.number,
  }).isRequired,

  settings: PropTypes.shape({
    settings: PropTypes.shape({
      userId: PropTypes.number,
      theme: PropTypes.string,
      language: PropTypes.string,
      seasonStart: PropTypes.string,
      useCelsius: PropTypes.bool,
      showSuggestedStage: PropTypes.bool,
    }),
    apiVersion: PropTypes.string,
    // gunModels: PropTypes.shape({}),
  }).isRequired,
  guns: PropTypes.shape({
    guns: PropTypes.shape({}),
  }).isRequired,

  trackerEquipment: PropTypes.shape({
    defenders: PropTypes.shape({}),
    trackerEquipment: PropTypes.shape({}),
    mobileEquipment: PropTypes.shape({}),
  }).isRequired,

  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
    ipAddresses: PropTypes.shape({
      plcId: PropTypes.string,
      ipAddress: PropTypes.string,
    }),
  }).isRequired,

  trails: PropTypes.shape({
    trails: PropTypes.shape({}),
    autotrails: PropTypes.shape({}),
    autotrailsiovalues: PropTypes.shape({}),
    trail: PropTypes.shape({}),
    hydrants: PropTypes.shape({}),
    selectedTrailName: PropTypes.string,
    selectedAutoTrailName: PropTypes.shape({}),
    trackerIOValues: PropTypes.shape({}),
  }).isRequired,

  zones: PropTypes.shape({
    flowZones: PropTypes.shape({}),
    temperatureZones: PropTypes.shape({}),
    pressureZones: PropTypes.shape({}),
  }).isRequired,

  flowManagementSettings: PropTypes.shape({
    flowManagementSettings: PropTypes.shape({}),
  }).isRequired,

  locations: PropTypes.shape({}).isRequired,

  weatherStations: PropTypes.shape({
    weatherStations: PropTypes.shape({}),
  }).isRequired,

  gateways: PropTypes.shape({
    gateways: PropTypes.shape({}),
    nodes: PropTypes.shape({}),
  }).isRequired,

  presets: PropTypes.shape({
    presets: PropTypes.shape({}),
  }).isRequired,

  trends: PropTypes.shape({
    IOValues: PropTypes.shape({}),
  }).isRequired,

  reset2FA: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setIsDisabled: PropTypes.func.isRequired,
  fetchAllUsers: PropTypes.func.isRequired,
  addUser: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  updateSettings: PropTypes.func.isRequired,
  getApiVersion: PropTypes.func.isRequired,
  updateGunIsAvailable: PropTypes.func.isRequired,
  updateGun: PropTypes.func.isRequired,
  updateGunModel: PropTypes.func.isRequired,
  addGun: PropTypes.func.isRequired,
  addGunModel: PropTypes.func.isRequired,
  deleteGunModel: PropTypes.func.isRequired,
  fetchAllGuns: PropTypes.func.isRequired,
  fetchAllTrackerEquipment: PropTypes.func.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchAllTrails: PropTypes.func.isRequired,
  fetchAllAutoTrails: PropTypes.func.isRequired,
  fetchAllAutoTrailsIOValues: PropTypes.func.isRequired,
  setAutoTrailValue: PropTypes.func.isRequired,
  setAutoTrailIOValue: PropTypes.func.isRequired,
  fetchAllGateways: PropTypes.func.isRequired,
  fetchNodes: PropTypes.func.isRequired,
  addGateway: PropTypes.func.isRequired,
  deleteGateway: PropTypes.func.isRequired,
  addNode: PropTypes.func.isRequired,
  deleteNode: PropTypes.func.isRequired,
  fetchAllPresets: PropTypes.func.isRequired,
  addPreset: PropTypes.func.isRequired,
  deletePreset: PropTypes.func.isRequired,
  updatePreset: PropTypes.func.isRequired,
  fetchFlowZones: PropTypes.func.isRequired,
  fetchPressureZones: PropTypes.func.isRequired,
  fetchTemperatureZones: PropTypes.func.isRequired,
  addZone: PropTypes.func.isRequired,
  deleteZone: PropTypes.func.isRequired,
  editZone: PropTypes.func.isRequired,
  editZoneIOValue: PropTypes.func.isRequired,
  fetchAllZoneIOValues: PropTypes.func.isRequired,
  fetchAllTrackerIOValues: PropTypes.func.isRequired,
  fetchAllIOValues: PropTypes.func.isRequired,
  fetchAllWeatherStations: PropTypes.func.isRequired,
  addMobileEquipment: PropTypes.func.isRequired,
  deleteMobileEquipment: PropTypes.func.isRequired,
  fetchLocations: PropTypes.func.isRequired,
  fetchAllMobileEquipment: PropTypes.func.isRequired,
  fetchAllFlowManagementSettings: PropTypes.func.isRequired,
  addFMConfig: PropTypes.func.isRequired,
  deleteFMConfig: PropTypes.func.isRequired,
  editFMConfig: PropTypes.func.isRequired,
  navbarClosed: PropTypes.bool.isRequired,
};

SettingsScreen.defaultProps = {
  // zones: {
  //   flowZones: {},
  //   temperatureZones: {},
  //   pressureZones: {},
  // },
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  guns: state.guns,
  gateways: state.gateways,
  presets: state.presets,
  trails: state.trails,
  trackerEquipment: state.trackerEquipment,
  weatherStations: state.weatherStations,
  zones: state.zones,
  locations: state.locations.locations,
  flowManagementSettings: state.flowManagementSettings,
  trends: state.trends,
  auth: state.auth,
  plcs: state.plcs,
  navbarClosed: state.nav.navbarClosed,
});

export default connect(mapStateToProps, {
  reset2FA,
  setEmail,
  setIsDisabled,
  fetchAllUsers,
  addUser,
  editUser,
  updateSettings,
  getApiVersion,
  fetchAllGuns,
  fetchAllTrails,
  fetchAllAutoTrails,
  fetchAllAutoTrailsIOValues,
  fetchAllGateways,
  addGateway,
  deleteGateway,
  fetchNodes,
  addNode,
  deleteNode,
  updateGunIsAvailable,
  fetchAllPresets,
  addPreset,
  deletePreset,
  updatePreset,
  updateGun,
  updateGunModel,
  addGun,
  addGunModel,
  deleteGunModel,
  fetchPLCs,
  // fetchQualityPresets,
  fetchAllTrackerEquipment,
  setAutoTrailValue,
  setAutoTrailIOValue,
  fetchFlowZones,
  fetchPressureZones,
  fetchTemperatureZones,
  fetchAllWeatherStations,
  addZone,
  deleteZone,
  editZone,
  editZoneIOValue,
  fetchAllIOValues,
  fetchAllTrackerIOValues,
  fetchAllZoneIOValues,
  addMobileEquipment,
  deleteMobileEquipment,
  fetchAllMobileEquipment,
  fetchLocations,
  fetchAllFlowManagementSettings,
  addFMConfig,
  deleteFMConfig,
  editFMConfig,
})(SettingsScreen);
