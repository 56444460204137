/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Switch, Route, Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from '@emotion/react';

import OriginalLogo from 'isno/lib/static/images/Navbar/isnoLogoLight.svg';
import LightLogo from 'isno/lib/static/images/Navbar/isnoLogoWhite.svg';
import DarkLogo from 'isno/lib/static/images/Navbar/isnoLogoDark.svg';

import NavBarSide from './NavBarSide';

import LoginScreen from '../screens/LoginScreen';
import ReportScreen from '../screens/ReportScreen';
import TrendScreen from '../screens/TrendScreen';
import WeatherForecastScreen from '../screens/WeatherForecastScreen';
import TrackerScreen from '../screens/TrackerScreen';
import OverviewScreen from '../screens/OverviewScreen';
import SettingsScreen from '../screens/SettingsScreen';
import EquipmentScreen from '../screens/QuebecFactory';
import ValveHousesScreen from '../screens/ValveHousesScreen';

const isnoLogos = {
  OriginalLogo,
  DarkLogo,
  LightLogo,
};

function WithNavBars(props) {
  const theme = useTheme();
  return (
    <div css={{
      display: 'flex', flexDirection: 'row', userSelect: 'none', backgroundColor: theme.base, color: theme.textColor, backgroundImage: `url(${isnoLogos[theme.logoKey]})`, backgroundSize: 'cover',
    }}
    >
      <NavBarSide float={props.float} />
      {props.children}
    </div>
  );
}

WithNavBars.propTypes = {
  children: PropTypes.node.isRequired,
  float: PropTypes.bool,
};

WithNavBars.defaultProps = {
  float: false,
};

function FallBack() {
  return <div>URL Not Found</div>;
}

function ProtectedRoute({ auth, children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => (auth?.authenticated ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: { from: location },
          }}
        />
      ))}
    />
  );
}

ProtectedRoute.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({}),
    authenticated: PropTypes.bool,
    need2fa: PropTypes.bool,
    error: PropTypes.number,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

function Routes(props) {
  const { auth } = props;
  return (
    <Switch>
      <Route
        exact
        path="/"
      >
        <LoginScreen />
      </Route>

      <ProtectedRoute
        auth={auth}
        exact
        path="/overview"
      >
        <WithNavBars float>
          <OverviewScreen />
        </WithNavBars>
      </ProtectedRoute>

      <ProtectedRoute
        auth={auth}
        exact
        path="/equipment"
      >
        <WithNavBars>
          <EquipmentScreen />
        </WithNavBars>
      </ProtectedRoute>

      {/* <ProtectedRoute
        auth={auth}
        exact
        path="/smartrek"
      >
        <WithNavBars>
          <ValveHousesScreen />
        </WithNavBars>
      </ProtectedRoute> */}

      <ProtectedRoute
        auth={auth}
        exact
        path="/reports"
      >
        <WithNavBars>
          <ReportScreen />
        </WithNavBars>
      </ProtectedRoute>

      <ProtectedRoute
        auth={auth}
        exact
        path="/trends"
      >
        <WithNavBars>
          <TrendScreen />
        </WithNavBars>
      </ProtectedRoute>

      <ProtectedRoute
        auth={auth}
        exact
        path="/forecast"
      >
        <WithNavBars>
          <WeatherForecastScreen />
        </WithNavBars>
      </ProtectedRoute>

      <ProtectedRoute
        auth={auth}
        exact
        path="/tracker"
      >
        <WithNavBars float>
          <TrackerScreen />
        </WithNavBars>
      </ProtectedRoute>

      {/* <ProtectedRoute exact
        path="/predictive"
      >
        <WithNavBars>
          <PredictiveScreen />
        </WithNavBars>
      </ProtectedRoute> */}

      <ProtectedRoute
        auth={auth}
        exact
        path="/settings"
      >
        <WithNavBars>
          <SettingsScreen />
        </WithNavBars>
      </ProtectedRoute>

      <ProtectedRoute
        auth={auth}
        exact
      >
        <WithNavBars>
          <FallBack />
        </WithNavBars>
      </ProtectedRoute>

    </Switch>
  );
}

Routes.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({}),
    authenticated: PropTypes.bool,
    need2fa: PropTypes.bool,
    error: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Routes);
