import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme, css } from '@emotion/react';

import {
  BasicRow,
  InfoControlRow,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Info,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import PLC from 'isno/lib/static/images/PLC.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

function ValveHousesScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(1),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) { // intervalId is null if component has unmounted
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(1);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null; // needed to prevent race condition with promise
    };
  }, []);

  const locationEquipment1 = props.locations?.locations?.[1]?.equipment;
  if (!props.locations?.locations?.[1]) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  const useCelsius = props.settings?.settings?.useCelsius;

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles={`min-width: fit-content; min-height: fit-content; @media only screen and (max-width: ${theme.mobileBreakpoint}px) {flex-direction: column;}`}>
          <LabeledContainer height="fit-content" flex="3" border label="PSI 4-20mA">
            <InfoControlRow>
              <Info
                title="Water Pressure"
                statistic={locationEquipment1?.['PSI 4-20']?.ioValues?.pressure?.value}
                label={locationEquipment1?.['PSI 4-20']?.ioValues?.pressure?.ioValueType?.units}
              />
              <Info
                title="Battery"
                statistic={locationEquipment1?.['PSI 4-20']?.ioValues?.battery?.value}
                label={locationEquipment1?.['PSI 4-20']?.ioValues?.battery?.ioValueType?.units}
              />
              <Info
                title="RSSI"
                statistic={locationEquipment1?.['PSI 4-20']?.ioValues?.rssi?.value}
                label={locationEquipment1?.['PSI 4-20']?.ioValues?.rssi?.ioValueType?.units}
              />
              <Info
                img={PLC}
                title="Device Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[166], language)}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[166], theme)}
              />
            </InfoControlRow>
          </LabeledContainer>
          <LabeledContainer height="fit-content" flex="3" border label="PSI 0-300psi">
            <InfoControlRow>
              <Info
                title="Water Pressure"
                statistic={locationEquipment1?.['PSI 300']?.ioValues?.pressure?.value}
                label={locationEquipment1?.['PSI 300']?.ioValues?.pressure?.ioValueType?.units}
              />
              <Info
                title="Battery"
                statistic={locationEquipment1?.['PSI 300']?.ioValues?.battery?.value}
                label={locationEquipment1?.['PSI 300']?.ioValues?.battery?.ioValueType?.units}
              />
              <Info
                title="RSSI"
                statistic={locationEquipment1?.['PSI 300']?.ioValues?.rssi?.value}
                label={locationEquipment1?.['PSI 300']?.ioValues?.rssi?.ioValueType?.units}
              />
              <Info
                img={PLC}
                title="Device Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[166], language)}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[166], theme)}
              />
            </InfoControlRow>
          </LabeledContainer>
          <LabeledContainer height="fit-content" flex="3" border label="Weather Station">
            <InfoControlRow>
              <Info
                title="Drybulb"
                statistic={useCelsius ? locationEquipment1?.WS?.ioValues?.drybulb_alternate?.value : locationEquipment1?.WS?.ioValues?.drybulb?.value}
                label={useCelsius ? locationEquipment1?.WS?.ioValues?.drybulb_alternate?.ioValueType?.units : locationEquipment1?.WS?.ioValues?.drybulb?.ioValueType?.units}
                precision={1}
              />
              <Info
                title="Relative Humidity"
                statistic={locationEquipment1?.WS?.ioValues?.relative_humidity?.value}
                label={locationEquipment1?.WS?.ioValues?.relative_humidity?.ioValueType?.units}
              />
              <Info
                title="Wetbulb"
                statistic={useCelsius ? locationEquipment1?.WS?.ioValues?.wetbulb_alternate?.value : locationEquipment1?.WS?.ioValues?.wetbulb?.value}
                label={useCelsius ? locationEquipment1?.WS?.ioValues?.wetbulb_alternate?.ioValueType?.units : locationEquipment1?.WS?.ioValues?.wetbulb?.ioValueType?.units}
                precision={1}
              />
              <Info
                title="Battery"
                statistic={locationEquipment1?.WS?.ioValues?.battery?.value}
                label={locationEquipment1?.WS?.ioValues?.battery?.ioValueType?.units}
              />
              <Info
                title="RSSI"
                statistic={locationEquipment1?.WS?.ioValues?.rssi?.value}
                label={locationEquipment1?.WS?.ioValues?.rssi?.ioValueType?.units}
              />
              <Info
                img={PLC}
                title="Device Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[166], language)}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[166], theme)}
              />
            </InfoControlRow>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        min-height: 100dvh;
        max-height: 100dvh;
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
      flex-direction: row;
      height: 100%;
      min-width: 175px;
    `,
    flexCol: css`
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;
      position: relative;
    `,
    pumpWrapper: css`
      display: flex;
      flex: 3;
      flex-direction: row;
      height: 100%;
      position: relative;
    `,
    redBox: css`
      border: 2px dashed red;
      z-index: 3;
      background-color: rgba(0,0,0,0.5);
      position: absolute;
      top: 0;
      left: 0;
      width: 133%;
      height: 83%;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
    drainLable: css`
    width: 41px;
    position: absolute;
    top: 43%;
    left: 5px;
    background: #666666;
    border-radius: 2px;
    padding: 2px;
    `,
  };
};

ValveHousesScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
  settings: PropTypes.shape({
    settings: PropTypes.shape({
      useCelsius: PropTypes.bool,
    }),
  }).isRequired,
};

ValveHousesScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
  settings: state.settings,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(ValveHousesScreen);
