import React, {
  useEffect, useContext, useState,
} from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { NavLink, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Languages, LanguageContext } from 'isno/lib/languages/LanguageContext';
import navbarSelectIconDarkTheme from 'isno/lib/static/images/TrackerIcons/darkTheme/navbarSelectIconDarkTheme.svg';
import {
  FaCog,
  FaBell,
  FaDownload,
  FaSignOutAlt,
  FaChevronLeft, FaChevronRight,
} from 'react-icons/fa';

import { StatMonitor, Version } from 'isno/lib/components/displays';
import Logo from '../static/hkd_logo.png';
import LogoDark from '../static/hkd_logo_dark.png';
import LogoMiami from '../static/MMH.png';
import TextMiami from '../static/MMH_Logo.png';
import UserGuideEN from '../static/iSnoManualEN.pdf';
import UserGuideFR from '../static/iSnoManualFR.pdf';

import {
  fetchNavBarStats,
  fetchAlarms,
  fetchSettings,
  signoutUser,
  signoutUserAuto,
  setNavState,
  jwtTokenIsExpired,
  fetchFeatures,
} from '../actions';

const squareIcon = () => (
  <img
    css={css`height: 17px; margin-top: 1px;`}
    alt="-"
    src={navbarSelectIconDarkTheme}
  />
);
// const squareIcon = () => (<FaSquare css={css`transform: rotate(45deg); margin-top: 2px;`} />);

function NavBarSide(props) {
  const theme = useTheme();
  const styles = stylesFromTheme(theme, props);
  const { language, setLanguage } = useContext(LanguageContext);

  const [languageSetupDone, setLanguageSetupDone] = useState(false);

  const logos = {
    OriginalLogo: Logo,
    LightLogo: LogoDark,
    DarkLogo: Logo,
    MiamiLogo: LogoMiami,
    MiamiText: TextMiami,
  };

  // If we succesfully authenticated: fetch user settings
  useEffect(() => {
    if (!props.settings?.settings) {
      props.fetchSettings();
      props.fetchFeatures();
    }
  }, []);

  // If we succesfully fetched user settings: set current language
  useEffect(() => {
    if (props.settings?.settings?.language && !languageSetupDone) {
      setLanguage(Languages[props.settings?.settings?.language]);
      // We only want to set our language here the first time (i.e on setup).
      setLanguageSetupDone(true);
    }
  }, [props.settings?.settings]);

  let fiveSecondIntervalId, oneSecondIntervalId;
  const fiveSecondInterval = () => {
    fiveSecondIntervalId = setTimeout(async () => {
      await props.fetchNavBarStats();
      if (jwtTokenIsExpired()) {
        props.signoutUserAuto();
      } else if (fiveSecondIntervalId) {
        fiveSecondIntervalId = setTimeout(fiveSecondInterval, 5000);
      }
    }, 5000);
  };

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      await props.fetchAlarms();
      if (oneSecondIntervalId) {
        oneSecondIntervalId = setTimeout(oneSecondInterval, 5000);
      }
    }, 1000);
  };

  useEffect(() => {
    props.fetchNavBarStats();
    props.fetchAlarms();
    oneSecondInterval();
    fiveSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      clearTimeout(fiveSecondIntervalId);
      oneSecondIntervalId = null;
      fiveSecondIntervalId = null;
    };
  }, []);

  return (
    <div id="sidebar" css={[styles.container, props.navbarClosed ? styles.closed : styles.open]}>
      <button
        type="button"
        css={[styles.hamburger, props.navbarClosed ? styles.hamburgerClosed : styles.hamburgerOpen]}
        onClick={() => {
          props.setNavState(!props.navbarClosed);
          window.dispatchEvent(new Event('resize'));
        }}
      >
        {props.navbarClosed
          ? <FaChevronRight />
          : <FaChevronLeft />}
      </button>
      <div css={[styles.content, props.navbarClosed ? styles.closedContent : styles.openContent]}>

        <div css={[styles.navItemsTop, styles.hideScrollbar]}>
          <div css={[styles.mountainInfo, css`justify-content: center;`]}>
            <img alt="iSno" src={props?.auth?.user?.username === 'Mark' ? logos.MiamiLogo : logos[theme.logoKey]} css={[styles.logoMountain, css`margin-left: auto; margin-right: auto;`]} />
            {props?.auth?.user?.username === 'Mark' && <img alt="iSno" src={logos.MiamiText} css={[styles.logoMountainName, css`margin: -10px auto 10px auto;`]} />}
            <p css={styles.mountainText}>Quebec Factory</p>
          </div>
          <div data-tooltip-id="statTip">
            <StatMonitor navbarStatistics={props.navbarStatistics} />
          </div>
          <ReactTooltip
            id="statTip"
            positionStrategy="fixed"
            // float
            place="right"
            border={`1px solid ${theme.navbarStatTipBorder}`}
            offset={0}
            style={{
              backgroundColor: `${theme.navbarStatTipBackground}`,
              color: `${theme.navbarStatTipText}`,
              borderRadius: '10px',
            }}
            // border
            // textColor={theme.navbarStatTipText}
            // backgroundColor={theme.navbarStatTipBackground}
            // borderColor={theme.navbarStatTipBorder}
          >
            <div>
              <p><b>{language.gunsWaitingHeader}:</b> {language.gunsWaitingTip}</p>
              <p><b>{language.gunsOnHeader}:</b> {language.gunsOnTip}</p>
              <p><b>{language.gpmTotalHeader}:</b> {language.gpmTotalTip}</p>
              <p><b>{language.cfmTotalHeader}:</b> {language.cfmTotalTip}</p>
            </div>
          </ReactTooltip>
          <div css={styles.navItems}>
            <div css={[styles.navItemsTopGroup, styles.hideScrollbar]}>
              <Link
                exact
                to="/overview"
                color={theme.textColorNavbar}
                hovercolor={theme.linkColorActive}
                activeStyle={{
                  color: theme.linkColorActive,
                  fontWeight: 700,
                }}
              >
                <NavItem>
                  <NavItemTextWrapper>
                    <Route
                      exact
                      path="/overview"
                      component={squareIcon}
                    />
                    <NavItemText>{language.overview}</NavItemText>
                  </NavItemTextWrapper>
                  {/* <FaBell css={styles.alarmBell} /> */}
                </NavItem>
              </Link>
              <Link
                exact
                to="/tracker"
                color={theme.textColorNavbar}
                hovercolor={theme.linkColorActive}
                activeStyle={{
                  color: theme.linkColorActive,
                  fontWeight: 700,
                }}
              >
                <NavItem>
                  <NavItemTextWrapper>
                    <Route
                      exact
                      path="/tracker"
                      component={squareIcon}
                    />
                    {/* add FRENCH MC = TM */}
                    <NavItemText>Tracker<sup style={{ fontSize: '7pt' }}>{language.tradeMark}</sup></NavItemText>
                  </NavItemTextWrapper>
                  {/* <FaBell css={styles.alarmBell} /> */}
                </NavItem>
              </Link>
              <Link
                exact
                to="/equipment"
                color={theme.textColorNavbar}
                hovercolor={theme.linkColorActive}
                activeStyle={{
                  color: theme.linkColorActive,
                  fontWeight: 700,
                }}
              >
                <NavItem>
                  <NavItemTextWrapper>
                    <Route
                      exact
                      path="/equipment"
                      component={squareIcon}
                    />
                    <NavItemText>{language.pumphouse}</NavItemText>
                  </NavItemTextWrapper>
                  {props?.locations?.alarms.find((item) => item.id === 1)?.hasAlarm && (
                    <FaBell css={styles.alarmBell} />
                  )}
                </NavItem>
              </Link>
              {/* <Link
                exact
                to="/smartrek"
                color={theme.textColorNavbar}
                hovercolor={theme.linkColorActive}
                activeStyle={{
                  color: theme.linkColorActive,
                  fontWeight: 700,
                }}
              >
                <NavItem>
                  <NavItemTextWrapper>
                    <Route
                      exact
                      path="/smartrek"
                      component={squareIcon}
                    />
                    <NavItemText>Smartrek</NavItemText>
                  </NavItemTextWrapper>
                  {props?.locations?.alarms.find((item) => item.id === 1)?.hasAlarm && (
                    <FaBell css={styles.alarmBell} />
                  )}
                </NavItem>
              </Link> */}
              <Link
                exact
                to="/trends"
                color={theme.textColorNavbar}
                hovercolor={theme.linkColorActive}
                activeStyle={{
                  color: theme.linkColorActive,
                  fontWeight: 700,
                }}
              >
                <NavItem>
                  <NavItemTextWrapper>
                    <Route
                      exact
                      path="/trends"
                      component={squareIcon}
                    />
                    <NavItemText>{language.trends}</NavItemText>
                  </NavItemTextWrapper>
                </NavItem>
              </Link>
              {props.auth.features?.WeatherForecast && (
              <Link
                exact
                to="/forecast"
                color={theme.textColorNavbar}
                hovercolor={theme.linkColorActive}
                activeStyle={{
                  color: theme.linkColorActive,
                  fontWeight: 700,
                }}
              >
                <NavItem>
                  <NavItemTextWrapper>
                    <Route
                      exact
                      path="/forecast"
                      component={squareIcon}
                    />
                    <NavItemText>{language.weatherForecast}</NavItemText>
                  </NavItemTextWrapper>
                </NavItem>
              </Link>
              )}
              <Link
                exact
                to="/reports"
                color={theme.textColorNavbar}
                hovercolor={theme.linkColorActive}
                activeStyle={{
                  color: theme.linkColorActive,
                  fontWeight: 700,
                }}
              >
                <NavItem>
                  <NavItemTextWrapper>
                    <Route
                      exact
                      path="/reports"
                      component={squareIcon}
                    />
                    <NavItemText>{language.reports}</NavItemText>
                  </NavItemTextWrapper>
                </NavItem>
              </Link>
            </div>
          </div>
        </div>
        <div css={styles.navItemsBottomGroup}>
          <div css={[styles.horzDivider, css`background-color: ${theme.linkColor};`]} />
          <Link
            exact
            to="/settings"
            color={theme.textColorNavbar}
            hovercolor={theme.altLinkColorActive}
            activeStyle={{
              color: theme.altLinkColorActive,
              fontWeight: 700,
            }}
          >
            <NavItem2>
              <FaCog />
              <NavItemText>{language.settings}</NavItemText>
            </NavItem2>
          </Link>
          {/* need FRENCH userGuide */}
          <ExternalLink
            href={language.fileName === 'english' ? UserGuideEN : UserGuideFR}
            download={language.fileName === 'english' ? 'iSnoManual.pdf' : 'iSnoManuel.pdf'}
            target="_blank"
            rel="noreferrer"
            color={theme.textColorNavbar}
            hovercolor={theme.altLinkColorActive}
          >
            <NavItem2>
              <FaDownload />
              <NavItemText>{language.userGuide}</NavItemText>
            </NavItem2>
          </ExternalLink>
          <Link
            exact
            to="/"
            onClick={() => { props.signoutUser(); }}
            color={theme.textColorNavbar}
            hovercolor={theme.altLinkColorActive}
          >
            <NavItem2>
              <FaSignOutAlt />
              <NavItemText css={css`margin-top: -14px;`}>{language.logout}:</NavItemText>
            </NavItem2>
            <div css={css`display: flex; text-decoration-line: underline; margin-bottom: -6px; margin-top: -7px; margin-left: 32px;`}>
              {props?.auth?.user?.username}
            </div>
          </Link>
          <div css={styles.navItemsVersion}>
            <Version color={theme.textColorNavbar} />
          </div>
        </div>
      </div>
    </div>

  );
}

const stylesFromTheme = (theme, props) => {
  return {
    container: css`
      z-index: 5;
      overflow: hidden;
      background-color: ${theme.navbarBackgroundColor};
      height: 100vh;
      flex: 0 0 160px;
      display: flex;
      flex-direction: column;
      position: ${props?.float ? 'fixed' : 'sticky'};
      top:0;
      left:0;

      transition: flex-basis ease 0.4s, width ease 0.4s, padding ease 0.4s, background-color ease 1s; 
      > *:not(:first-of-type) {
        transition: opacity 0.4s ease; 
      }

      // using flex-basis not width
      width: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        position: fixed;
        height: 100%;
        height: 100dvh;
      }
    `,
    closed: css`
      flex-basis: 16px; 
      > *:not(:first-of-type) {
        opacity: 0;
        pointer-events: none; 
      }
      width: ${props?.float ? '16px' : ''};
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        width: 16px;
      }
      padding-right: 0px;
    `,
    open: css`
      width: ${props?.float ? '160px' : ''};
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        width: 160px;
        // padding-right: 0px;
      }
      padding-right: 160px;
    `,
    hamburger: css`
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${theme.borderColor};

      cursor: pointer;

      box-shadow: 0 0 4px black;

      background: white;
      width: 24px;
      height: 24px;
      border-radius: 12px;

      position: fixed;
      top: 32px;
      transition: left ease 0.4s; 
      :hover{
        box-shadow: 0 0 6px black;
      }
    `,
    hamburgerOpen: css`
      left: 144px;
    `,
    hamburgerClosed: css`
      left: 0px;
    `,
    content: css`
      flex:1;
      // overflow-y: auto;  /*adds scroll to this container*/
      // overflow: hidden;
      display: flex;
      flex-direction:column;
      width:160px;
      transition: opacity ease 0.4s; 
    `,
    closedContent: css`
      opacity: 0.0;
      pointer-events:none;
    `,
    openContent: css`
      opacity: 1.0;
    `,
    mountainInfo: css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `,
    logoMountain: css`
      height: auto;
      width: 100%;
      padding: 10px;
    `,
    logoMountainName: css`
      height: auto;
      width: 100%;
    `,
    alarmBell: css`
      color: red;
      animation: blink 1.5s 6;
      @keyframes blink {
        50% { opacity: 0; }
      }
    `,
    mountainText: css`
      color: ${theme.textColorNavbar};
      font-size: 110%;
      text-align: center;
      white-space: nowrap;
      margin-bottom: 6px;
    `,
    navItems: css`
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `,
    navItemsTop: css`
      flex: 1 1 0;
      display: flex;
      flex-direction: column;
      // max-height: calc(100vh + 123px - ${props.settings?.settings?.language === 'french' ? '320px' : '302px'}); // heights of our other navbar items
      overflow-y: scroll;
      overfloy-x: hidden;
  `,
    navItemsTopGroup: css`
      flex:1;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      overfloy-x: hidden;
  `,
    navItemsVersion: css`
      flex:1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-left: 10px;
  `,
    navItemsBottomGroup: css`
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
  `,
    hidden: css`
      display: none;
  `,
    hideScrollbar: css`
      overflow-y: scroll;
      // -ms-overflow-style: none;  /* Internet Explorer 10+ */
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
      }
      scrollbar-width: none; /* Firefox */
  `,
    horzDivider: css`
      height: 1px;
      width: 100%;
      background: ${theme.borderColor};
      margin: 1px 0px;
      padding: 0px 10px;
  `,
  };
};

const Link = styled(NavLink)`
  color: ${(props) => props.color || 'white'};
  padding: 12px 0px;
  :not(:last-child) {
    border-bottom: 1px solid #00000030;
  }

  :hover {
    color: ${(props) => props.hovercolor}
  }
`;

const ExternalLink = styled.a`
  color: ${(props) => props.color || 'white'}; 
  padding: 12px 0px;
  :not(:last-child) {
    border-bottom: 1px solid #00000030;
  }

  :hover {
    color: ${(props) => props.hovercolor}
  }
`;

const NavItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
`;

const NavItem2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
`;

const NavItemTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const NavItemText = styled.p`
  margin-left: 8px;
`;

NavBarSide.propTypes = {
  signoutUser: PropTypes.func.isRequired,
  signoutUserAuto: PropTypes.func.isRequired,
  setNavState: PropTypes.func.isRequired,
  navbarClosed: PropTypes.bool.isRequired,

  navbarStatistics: PropTypes.shape({
    gunsOn: PropTypes.number,
    gunsWaiting: PropTypes.number,
    gpm: PropTypes.number,
    cfm: PropTypes.number,
  }).isRequired,

  fetchNavBarStats: PropTypes.func.isRequired,
  fetchAlarms: PropTypes.func.isRequired,

  locations: PropTypes.shape({
    alarms: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,

  auth: PropTypes.shape({
    user: PropTypes.shape({
      username: PropTypes.string,
    }),
    features: PropTypes.shape({
      WeatherForecast: PropTypes.bool,
    }),
    authenticated: PropTypes.bool,
    need2fa: PropTypes.bool,
    error: PropTypes.number,
  }).isRequired,

  settings: PropTypes.shape({
    settings: PropTypes.shape({
      userId: PropTypes.number,
      theme: PropTypes.string,
      language: PropTypes.string,
      seasonStart: PropTypes.string,
      useCelsius: PropTypes.bool,
    }),
  }).isRequired,

  fetchSettings: PropTypes.func.isRequired,
  fetchFeatures: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  navbarStatistics: state.nav.navbarStatistics,
  settings: state.settings,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  signoutUser, fetchFeatures, signoutUserAuto, setNavState, fetchAlarms, fetchNavBarStats, fetchSettings,
})(NavBarSide);
